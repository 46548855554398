const aller = {
  aller: {
    present: [
      "Je %%vais%% à la salle de gym tous les matins.",
      "Tu %%vas%% à la bibliothèque chaque week-end.",
      "Il %%va%% souvent au cinéma le vendredi soir.",
      "Elle %%va%% rarement à la piscine en hiver.",
      "On %%va%% généralement en vacances en été.",
      "Nous %%allons%% souvent voir des concerts de jazz.",
      "Vous %%allez%% bientôt rencontrer mon nouvel ami.",
      "Ils %%vont%% habituellement à l'église le dimanche.",
      "Elles %%vont%% au marché aux puces pour chercher des antiquités.",
      "Mon frère %%va%% au dojo pour sa leçon de karaté.",
      "Mes parents %%vont%% au café pour prendre leur petit déjeuner.",
      "Mon meilleur ami et moi %%allons%% au lac pour pêcher.",
      "Les étudiants %%vont%% à la conférence sur l'art contemporain.",
      "L'oiseau %%va%% vers le sud pour l'hiver.",
      "Ma grand-mère et toi %%allez%% à l'opéra une fois par mois.",
      "Le soleil %%va%% se coucher bientôt.",
      "Ma tante %%va%% souvent au jardin pour arroser les plantes.",
      "Mes collègues et moi %%allons%% à la cantine pour déjeuner.",
      "Mon père et moi %%allons%% au stade pour regarder le match de football.",
      "Les abeilles %%vont%% de fleur en fleur pour collecter le nectar.",
      "La tortue %%va%% lentement vers la rivière.",
      "La lune %%va%% apparaître dans le ciel nocturne.",
      "Mon cousin et toi %%allez%% à l'école en vélo.",
      "Les invités %%vont%% arriver dans une heure.",
      "Le bateau %%va%% naviguer le long de la rivière.",
      "L'équipe de football %%va%% au camp d'entraînement chaque été.",
      "Mon oncle et ma tante %%vont%% au théâtre chaque samedi.",
      "Les enfants %%vont%% à l'école à pied.",
      "La vie %%va%% parfois de manière imprévisible.",
      "La pluie %%va%% arrêter de tomber bientôt.",
      // First person singular
      "Je %%vais%% à la plage chaque été.",
      "Avec mon chien, je %%vais%% au parc tous les matins.",
      "Je %%vais%% déménager en Espagne.",
      "Pour l'anniversaire de mon père, je %%vais%% préparer un gâteau au chocolat fait maison.",

      // Second person singular
      "Tu %%vas%% vite comprendre le problème.",
      "Avec ton chapeau, tu %%vas%% attirer l'attention.",
      "Tu %%vas%% à la bibliothèque tous les dimanches.",
      "Tu %%vas%% rencontrer ma sœur demain.",

      // Third person singular
      "Mon voisin %%va%% à la pêche tous les week-ends.",
      "Ce chien %%va%% souvent se promener dans le parc.",
      "Le facteur %%va%% livrer le colis demain.",
      "L'avion %%va%% décoller dans cinq minutes.",

      // First person plural
      "Nous %%allons%% à la montagne chaque hiver.",
      "Avec mes amis, nous %%allons%% souvent voir des films.",
      "Nous %%allons%% courir un marathon ce weekend.",
      "Nous %%allons%% préparer un repas pour la fête de demain.",

      // Second person plural
      "Vous %%allez%% adorer ce nouveau restaurant.",
      "Avec vos enfants, vous %%allez%% au zoo chaque année.",
      "Vous %%allez%% arriver en retard si vous ne partez pas maintenant.",
      "Vous %%allez%% voir, cette série est fantastique.",

      // Third person plural
      "Mes collègues %%vont%% courir tous les jours à midi.",
      "Ces oiseaux %%vont%% migrer vers le sud en automne.",
      "Les étudiants %%vont%% passer un examen demain.",
      "Les enfants %%vont%% jouer dans le parc après l'école.",
    ],
    futurSimple: [
      "J'%%irai%% au cinéma demain.",
      "Tu %%iras%% à l'école.",
      "Il %%ira%% au marché.",
      "Elle %%ira%% à la bibliothèque.",
      "On %%ira%% au concert ce soir.",
      "Nous %%irons%% en vacances la semaine prochaine.",
      "Vous %%irez%% à la fête ce weekend.",
      "Ils %%iront%% à la plage.",
      "Elles %%iront%% au zoo.",
      "Mon ami %%ira%% à la gare.",
      "Mes parents %%iront%% au restaurant.",
      "Mon ami et moi %%irons%% à la pêche.",
      "Les enfants %%iront%% au parc.",
      "Le chien %%ira%% chez le vétérinaire.",
      "Ma soeur et toi %%irez%% au musée.",
      "Le professeur %%ira%% à l'université.",
      "Ma mère %%ira%% à la boulangerie.",
      "Mes collègues %%iront%% à la conférence.",
      "Mon père et moi %%irons%% à la chasse.",
      "La police %%ira%% à la banque.",
      "La voiture %%ira%% au garage.",
      "Le courrier %%ira%% à l'administration.",
      "Mon frère %%ira%% à l'hôpital.",
      "Ma tante et ma cousine %%iront%% au spa.",
      "Le café %%ira%% aux clients.",
      "L'école %%ira%% en excursion.",
      "Mon ami et toi %%irez%% à la salle de sport.",
      "Les fleurs %%iront%% à ma grand-mère.",
      "Le film %%ira%% à Cannes.",
      "La porte %%ira%% à la réparation.",
      // First person singular
      "J'%%irai%% à la plage demain.",
      "Avec mon chien, j'%%irai%% au parc après le déjeuner.",
      "J'%%irai%% vivre en Espagne l'année prochaine.",
      "Pour le prochain Noël, j'%%irai%% chez mes grands-parents.",

      // Second person singular
      "Tu %%iras%% au musée après l'école.",
      "Avec ton nouvel appareil photo, tu %%iras%% faire un shooting demain.",
      "Tu %%iras%% te coucher tôt ce soir.",
      "Tu %%iras%% à la fête de Sophie samedi.",

      // Third person singular
      "Mon frère %%ira%% à la pêche ce week-end.",
      "Ce chat %%ira%% se promener dans le jardin plus tard.",
      "Le facteur %%ira%% livrer le colis après sa pause.",
      "L'avion %%ira%% décoller dans une heure.",

      // First person plural
      "Nous %%irons%% à la montagne pour le long week-end.",
      "Avec mes amis, nous %%irons%% voir un film demain soir.",
      "Nous %%irons%% courir un marathon le mois prochain.",
      "Nous %%irons%% préparer un grand dîner pour la fête de fin d'année.",

      // Second person plural
      "Vous %%irez%% adorer ce nouveau parc d'attractions.",
      "Avec vos enfants, vous %%irez%% à Disneyland le mois prochain.",
      "Vous %%irez%% arriver en retard si vous ne partez pas tout de suite.",
      "Vous %%irez%% voir, ce film est fantastique.",

      // Third person plural
      "Mes collègues %%iront%% courir après le travail.",
      "Ces oiseaux %%iront%% migrer vers le sud le mois prochain.",
      "Les étudiants %%iront%% passer un examen la semaine prochaine.",
      "Les enfants %%iront%% jouer au parc ce week-end.",
    ],
    imparfait: [
      "Quand j'étais enfant, j'%%allais%% au parc tous les jours.",
      "Tu %%allais%% à la bibliothèque chaque semaine pour lire de nouveaux livres.",
      "Il %%allait%% toujours au cinéma le samedi soir.",
      "Elle %%allait%% souvent à la plage durant l'été.",
      "Nous %%allions%% à la boulangerie chaque matin pour du pain frais.",
      "Vous %%alliez%% à l'école à vélo quand vous étiez jeune.",
      "Ils %%allaient%% à la pêche tous les dimanches.",
      "Elles %%allaient%% à l'église chaque Noël.",
      "Mon père %%allait%% souvent en voyage d'affaires à l'étranger.",
      "Mes grands-parents %%allaient%% en promenade tous les après-midi.",
      "Mon ami et moi %%allions%% à la piscine chaque vendredi.",
      "Les oiseaux %%allaient%% vers le sud chaque hiver.",
      "Le soleil %%allait%% se coucher à l'horizon.",
      "Ma mère et toi %%alliez%% au marché chaque samedi.",
      "Le chien %%allait%% souvent se promener dans le jardin.",
      "Ma tante %%allait%% toujours voir un spectacle de danse le mois dernier.",
      "Mes collègues et moi %%allions%% prendre le café tous les après-midis.",
      "Mon oncle et ma tante %%allaient%% souvent au théâtre.",
      "Les abeilles %%allaient%% de fleur en fleur pour collecter le nectar.",
      "La tortue %%allait%% lentement vers la rivière.",
      "La lune %%allait%% apparaître dans le ciel nocturne.",
      "Mon cousin et toi %%alliez%% à l'école en vélo.",
      "Les invités %%allaient%% arriver dans une heure.",
      "Le bateau %%allait%% naviguer le long de la rivière.",
      "L'équipe de football %%allait%% au camp d'entraînement chaque été.",
      "Les enfants %%allaient%% à l'école à pied.",
      "La vie %%allait%% parfois de manière imprévisible.",
      "La pluie %%allait%% arrêter de tomber bientôt.",
      "La voiture %%allait%% lentement sur la route de montagne.",
      "Le vent %%allait%% souffler toute la nuit.",
      // First person singular
      "J'%%allais%% à la plage chaque été.",
      "Avec mon chien, j'%%allais%% au parc tous les matins.",
      "J'%%allais%% souvent visiter mes grands-parents.",
      "Le dimanche, j'%%allais%% souvent pêcher au lac.",

      // Second person singular
      "Tu %%allais%% à l'école à pied.",
      "Avec ton vélo, tu %%allais%% à la boulangerie chaque matin.",
      "Tu %%allais%% souvent voir des films le week-end.",
      "Tu %%allais%% à la piscine tous les mercredis après-midi.",

      // Third person singular
      "Mon père %%allait%% à la pêche tous les week-ends.",
      "Ce chat %%allait%% souvent se promener dans le jardin.",
      "Ma sœur %%allait%% chercher le pain tous les matins.",
      "L'avion %%allait%% décoller quand la tempête a commencé.",

      // First person plural
      "Nous %%allions%% à la montagne chaque hiver.",
      "Avec mes amis, nous %%allions%% souvent au cinéma le samedi.",
      "Nous %%allions%% courir dans le parc chaque matin.",
      "Nous %%allions%% rendre visite à nos cousins chaque mois.",

      // Second person plural
      "Vous %%alliez%% adorer ce nouveau restaurant.",
      "Avec vos enfants, vous %%alliez%% au zoo chaque année.",
      "Vous %%alliez%% arriver en retard si vous ne partiez pas maintenant.",
      "Vous %%alliez%% voir, cette série était fantastique.",

      // Third person plural
      "Mes collègues %%allaient%% courir tous les jours à midi.",
      "Ces oiseaux %%allaient%% migrer vers le sud en automne.",
      "Les étudiants %%allaient%% à la bibliothèque pour étudier.",
      "Les enfants %%allaient%% jouer dans le parc après l'école.",
    ],
  },
};

export default aller;
