// AccentKeyboard.tsx
import React from "react";

interface AccentKeyboardProps {
  index: number;
  onCharClick: (char: string) => void;
}

const AccentKeyboard: React.FC<AccentKeyboardProps> = ({ onCharClick }) => {
  const chars = ["é", "è", "ê", "à", "ô", "ç"];

  return (
    <div
      className="position-absolute bg-white"
      style={{ zIndex: "999", display: "inline" }}
    >
      <div className="btn-group" role="group">
        {chars.map((char) => (
          <button
            type="button"
            className="btn btn-outline-primary"
            key={char}
            onClick={(_e) => onCharClick(char)}
          >
            {char}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AccentKeyboard;
