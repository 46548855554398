const mettre: any = {
  mettre: {
    present: [
      "Je %%mets%% mes chaussures.",
      "Tu %%mets%% le couvert.",
      "Il %%met%% son manteau.",
      "Mes amis %%mettent%% la musique.",
      "Elles %%mettent%% leurs bijoux.",
      "Vous %%mettez%% vos lunettes.",
      "Mon père %%met%% sa cravate.",
      "Nous %%mettons%% nos masques.",
      "Les étudiants %%mettent%% leurs sacs dans le casier.",
      "La serveuse %%met%% les plats sur la table.",
      "Je %%mets%% du sucre dans mon café.",
      "Tu %%mets%% de l’engrais dans les plantes.",
      "L'arbitre %%met%% un carton rouge.",
      "Les enfants %%mettent%% leurs casquettes.",
      "On %%met%% les cadeaux sous l'arbre.",
      "Elle %%met%% du rouge à lèvres.",
      "Vous %%mettez%% la climatisation?",
      "Ma sœur et moi %%mettons%% la table.",
      "Ils %%mettent%% les poubelles dehors.",
      "La policière %%met%% des menottes.",
      "Je %%mets%% mon sac dans le coffre.",
      "Tu %%mets%% l’alarme avant de partir?",
      "Le chauffeur %%met%% le clignotant.",
      "Nous %%mettons%% nos écharpes.",
      "Vous %%mettez%% du lait dans le thé.",
      "Les comédiens %%mettent%% leurs costumes.",
      "Le professeur %%met%% les notes en ligne.",
      "Ils %%mettent%% de l'ordre dans la chambre.",
      "La vendeuse %%met%% les articles en solde.",
      "Je %%mets%% du baume à lèvres.",
    ],
    imparfait: [
      "Je %%mettais%% mes bottes.",
      "Tu %%mettais%% les clés sur la table.",
      "Il %%mettait%% son écharpe.",
      "Nous %%mettions%% nos cahiers dans les casiers.",
      "Vous %%mettiez%% le dîner sur la table.",
      "Ils %%mettaient%% du sel dans la soupe.",
      "Mon ami %%mettait%% sa montre.",
      "Elle %%mettait%% de la crème solaire.",
      "Mes parents %%mettaient%% les volets.",
      "Le fermier %%mettait%% les animaux à l’abri.",
      "Je %%mettais%% la ceinture.",
      "Tu %%mettais%% tes écouteurs?",
      "Le bibliothécaire %%mettait%% les livres en place.",
      "Nous %%mettions%% les courses dans le coffre.",
      "Vous %%mettiez%% des photos dans l’album.",
      "Les élèves %%mettaient%% leurs téléphones en mode silencieux.",
      "Le jardinier %%mettait%% des graines.",
      "Elle %%mettait%% son casque de vélo.",
      "On %%mettait%% les manteaux dans le placard.",
      "Ils %%mettaient%% la tente.",
      "Je %%mettais%% du vernis à ongles.",
      "Tu %%mettais%% de l’essence dans la voiture.",
      "Le peintre %%mettait%% une couche de vernis.",
      "Nous %%mettions%% les verres sur l’étagère.",
      "Vous %%mettiez%% la table pour le dîner.",
      "Les touristes %%mettaient%% leurs sacs à dos.",
      "Le maître %%mettait%% un bon point.",
      "Ils %%mettaient%% les voiles.",
      "La factrice %%mettait%% le courrier dans la boîte aux lettres.",
      "Je %%mettais%% du parfum.",
    ],
    futurSimple: [
      "Je %%mettrai%% mon meilleur costume.",
      "Tu %%mettras%% la voiture dans le garage.",
      "Il %%mettra%% ses nouvelles chaussures.",
      "Nous %%mettrons%% les bagages dans le taxi.",
      "Vous %%mettrez%% le pain dans le four.",
      "Ils %%mettront%% les décorations de Noël.",
      "Ma mère %%mettra%% le café.",
      "Elle %%mettra%% ses lentilles de contact.",
      "Mes frères %%mettront%% le barbecue.",
      "L’artiste %%mettra%% sa signature sur le tableau.",
      "Je %%mettrai%% du lait dans le frigo.",
      "Tu %%mettras%% les documents dans le dossier?",
      "Le pilote %%mettra%% le moteur en marche.",
      "Nous %%mettrons%% les chaises en cercle.",
      "Vous %%mettrez%% la musique à fond.",
      "Les acteurs %%mettront%% en scène la pièce.",
      "Le DJ %%mettra%% des tubes.",
      "Elle %%mettra%% le riz dans la casserole.",
      "On %%mettra%% les voiles au matin.",
      "Ils %%mettront%% fin à la réunion.",
      "Je %%mettrai%% un rappel pour l'anniversaire.",
      "Tu %%mettras%% du citron dans le thé.",
      "Le professeur %%mettra%% un examen surprise.",
      "Nous %%mettrons%% le chauffage en arrivant.",
      "Vous %%mettrez%% l'argent dans le tiroir.",
      "Les joueurs %%mettront%% les maillots.",
      "Le coiffeur %%mettra%% un peu de gel.",
      "Ils %%mettront%% le projecteur en marche.",
      "La secrétaire %%mettra%% les appels en attente.",
      "Je %%mettrai%% le journal sur la table.",
    ],
  },
};

export default mettre;
