const conjugationData = {
  present: [
    "j'%%aime%%",
    "tu %%aimes%%",
    "il/elle %%aime%%",
    "nous %%aimons%%",
    "vous %%aimez%%",
    "ils/elles %%aiment%%",
  ],
  passeCompose: [
    "j'%%ai aimé%%",
    "tu %%as aimé%%",
    "il/elle %%a aimé%%",
    "nous %%avons aimé%%",
    "vous %%avez aimé%%",
    "ils/elles %%ont aimé%%",
  ],
  imparfait: [
    "j'%%aimais%%",
    "tu %%aimais%%",
    "il/elle %%aimait%%",
    "nous %%aimions%%",
    "vous %%aimiez%%",
    "ils/elles %%aimaient%%",
  ],
  plusQueParfait: [
    "j'%%avais aimé%%",
    "tu %%avais aimé%%",
    "il/elle %%avait aimé%%",
    "nous %%avions aimé%%",
    "vous %%aviez aimé%%",
    "ils/elles %%avaient aimé%%",
  ],
  passeSimple: [
    "j'%%aimai%%",
    "tu %%aimas%%",
    "il/elle %%aima%%",
    "nous %%aimâmes%%",
    "vous %%aimâtes%%",
    "ils/elles %%aimèrent%%",
  ],
  passeAnterieur: [
    "j'%%eus aimé%%",
    "tu %%eus aimé%%",
    "il/elle %%eut aimé%%",
    "nous %%eûmes aimé%%",
    "vous %%eûtes aimé%%",
    "ils/elles %%eurent aimé%%",
  ],
  futurSimple: [
    "j'%%aimerai%%",
    "tu %%aimeras%%",
    "il/elle %%aimera%%",
    "nous %%aimerons%%",
    "vous %%aimerez%%",
    "ils/elles %%aimeront%%",
  ],
  futurAnterieur: [
    "j'%%aurai aimé%%",
    "tu %%auras aimé%%",
    "il/elle %%aura aimé%%",
    "nous %%aurons aimé%%",
    "vous %%aurez aimé%%",
    "ils/elles %%auront aimé%%",
  ],
  subjonctif_present: [
    "que j'%%aime%%",
    "que tu %%aimes%%",
    "qu'il/elle %%aime%%",
    "que nous %%aimions%%",
    "que vous %%aimiez%%",
    "qu'ils/elles %%aiment%%",
  ],
  subjonctif_passe: [
    "que j'%%aie aimé%%",
    "que tu %%aies aimé%%",
    "qu'il/elle %%ait aimé%%",
    "que nous %%ayons aimé%%",
    "que vous %%ayez aimé%%",
    "qu'ils/elles %%aient aimé%%",
  ],
  subjonctif_imparfait: [
    "que j'%%aimasse%%",
    "que tu %%aimasses%%",
    "qu'il/elle %%aimât%%",
    "que nous %%aimassions%%",
    "que vous %%aimassiez%%",
    "qu'ils/elles %%aimassent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse aimé%%",
    "que tu %%eusses aimé%%",
    "qu'il/elle %%eût aimé%%",
    "que nous %%eussions aimé%%",
    "que vous %%eussiez aimé%%",
    "qu'ils/elles %%eussent aimé%%",
  ],
  conditionnel_present: [
    "j'%%aimerais%%",
    "tu %%aimerais%%",
    "il/elle %%aimerait%%",
    "nous %%aimerions%%",
    "vous %%aimeriez%%",
    "ils/elles %%aimeraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais aimé%%",
    "tu %%aurais aimé%%",
    "il/elle %%aurait aimé%%",
    "nous %%aurions aimé%%",
    "vous %%auriez aimé%%",
    "ils/elles %%auraient aimé%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais aimé%%",
    "tu %%aurais aimé%%",
    "il/elle %%aurait aimé%%",
    "nous %%aurions aimé%%",
    "vous %%auriez aimé%%",
    "ils/elles %%auraient aimé%%",
  ],
};

export default conjugationData;
