const rendre: any = {
  rendre: {
    present: [
      "Je %%rends%% visite à mes grands-parents.",
      "Tu %%rends%% les livres à la bibliothèque?",
      "Il %%rend%% le travail plus difficile.",
      "Nous %%rendons%% les clés à la réception.",
      "Les enfants %%rendent%% le jardin plus joyeux.",
      "Vous %%rendez%% l'argent que vous devez?",
      "Elles %%rendent%% hommage à leur professeur.",
      "Le musicien %%rend%% la mélodie enchanteresse.",
      "Les voisins %%rendent%% le quartier vivant.",
      "On %%rend%% les formulaires d'ici demain.",
      "Le boulanger %%rend%% le pain croustillant.",
      "Tu %%rends%% service à qui en ce moment?",
      "Le chien %%rend%% la balle après l'avoir attrapée.",
      "Vous %%rendez%% les choses plus simples.",
      "Les élèves %%rendent%% les devoirs en temps et en heure.",
      "La pluie %%rend%% les routes dangereuses.",
      "Nous %%rendons%% les choses possibles.",
      "Il %%rend%% compte de la situation.",
      "Le cinéaste %%rend%% la scène réaliste.",
      "Vous %%rendez%% la monnaie sur ce billet?",
      "Les acteurs %%rendent%% les personnages vivants.",
      "Je %%rends%% l'atmosphère détendue.",
      "Les ingénieurs %%rendent%% les machines plus efficaces.",
      "Vous %%rendez%% la salle propre?",
      "Le médicament %%rend%% le patient somnolent.",
      "Elle %%rend%% la robe à sa sœur.",
      "Nous %%rendons%% hommage aux anciens.",
      "Les artistes %%rendent%% la vie plus belle.",
      "On %%rend%% le verdict aujourd'hui.",
      "Vous %%rendez%% le passeport après l'inspection.",
    ],
    imparfait: [
      "Je %%rendais%% souvent visite à mon oncle.",
      "Tu %%rendais%% les choses plus complexes.",
      "Il %%rendait%% les animaux heureux.",
      "Nous %%rendions%% les livres chaque semaine.",
      "Les gens %%rendaient%% les objets trouvés au commissariat.",
      "Vous %%rendiez%% compte de vos erreurs?",
      "Elles %%rendaient%% la musique plus agréable.",
      "La mer %%rendait%% l'air salin.",
      "Les lumières %%rendaient%% l'ambiance chaleureuse.",
      "On %%rendait%% les clés au gardien.",
      "La neige %%rendait%% les routes glissantes.",
      "Tu %%rendais%% tout plus difficile.",
      "Le professeur %%rendait%% les sujets compréhensibles.",
      "Vous %%rendiez%% la maison propre chaque samedi.",
      "Les arbres %%rendaient%% le paysage magnifique.",
      "Il %%rendait%% le travail en retard.",
      "Nous %%rendions%% les vêtements trop petits.",
      "Les couleurs %%rendaient%% la pièce lumineuse.",
      "Vous %%rendiez%% la vie plus joyeuse?",
      "Les bénévoles %%rendaient%% le parc propre.",
      "Je %%rendais%% le vélo après utilisation.",
      "La brume %%rendait%% le matin mystérieux.",
      "Vous %%rendiez%% service à vos voisins?",
      "Le vent %%rendait%% la voile efficace.",
      "Elles %%rendaient%% tout plus simple.",
      "On %%rendait%% hommage aux héros.",
      "Le jardinier %%rendait%% les plantes vigoureuses.",
      "Tu %%rendais%% les films intéressants.",
      "Le bois %%rendait%% le feu chaud.",
      "Vous %%rendiez%% les plats savoureux.",
    ],
    futurSimple: [
      "Je %%rendrai%% le document signé demain.",
      "Tu %%rendras%% la vie meilleure.",
      "Il %%rendra%% la voiture au garage.",
      "Nous %%rendrons%% les clés en partant.",
      "Les employés %%rendront%% leur badge à la fin de la journée.",
      "Vous %%rendrez%% les papiers à l'administration?",
      "Elles %%rendront%% le monde plus durable.",
      "La réunion %%rendra%% la situation claire.",
      "Les étoiles %%rendront%% le ciel magnifique.",
      "On %%rendra%% les votes publics.",
      "L'expérience %%rendra%% les résultats plus fiables.",
      "Tu %%rendras%% le travail plus facile.",
      "Le maire %%rendra%% la décision finale.",
      "Vous %%rendrez%% hommage à la légende.",
      "Les ingénieurs %%rendront%% la technologie plus accessible.",
      "Elle %%rendra%% l'argent emprunté.",
      "Nous %%rendrons%% le verdict bientôt.",
      "Le comité %%rendra%% les résultats officiels.",
      "Vous %%rendrez%% le contrat signé?",
      "Ils %%rendront%% le lieu plus agréable.",
      "Le directeur %%rendra%% la scène dramatique.",
      "Je %%rendrai%% le bien pour le mal.",
      "Les joueurs %%rendront%% la partie excitante.",
      "Vous %%rendrez%% le service rendu?",
      "La lumière %%rendra%% la pièce plus accueillante.",
      "Elles %%rendront%% le rapport complet.",
      "On %%rendra%% les objets trouvés.",
      "Tu %%rendras%% la faveur?",
      "Le temps %%rendra%% la vérité évidente.",
      "Vous %%rendrez%% les vêtements défectueux.",
    ],
  },
};

export default rendre;
