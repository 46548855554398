let devoirConjugations = {
  present: [
    "je %%dois%%",
    "tu %%dois%%",
    "il/elle %%doit%%",
    "nous %%devons%%",
    "vous %%devez%%",
    "ils/elles %%doivent%%",
  ],
  passeCompose: [
    "j'%%ai dû%%",
    "tu %%as dû%%",
    "il/elle %%a dû%%",
    "nous %%avons dû%%",
    "vous %%avez dû%%",
    "ils/elles %%ont dû%%",
  ],
  imparfait: [
    "je %%devais%%",
    "tu %%devais%%",
    "il/elle %%devait%%",
    "nous %%devions%%",
    "vous %%deviez%%",
    "ils/elles %%devaient%%",
  ],
  plusQueParfait: [
    "j'%%avais dû%%",
    "tu %%avais dû%%",
    "il/elle %%avait dû%%",
    "nous %%avions dû%%",
    "vous %%aviez dû%%",
    "ils/elles %%avaient dû%%",
  ],
  passeSimple: [
    "je %%dus%%",
    "tu %%dus%%",
    "il/elle %%dut%%",
    "nous %%dûmes%%",
    "vous %%dûtes%%",
    "ils/elles %%durent%%",
  ],
  passeAnterieur: [
    "j'%%eus dû%%",
    "tu %%eus dû%%",
    "il/elle %%eut dû%%",
    "nous %%eûmes dû%%",
    "vous %%eûtes dû%%",
    "ils/elles %%eurent dû%%",
  ],
  futurSimple: [
    "je %%devrai%%",
    "tu %%devras%%",
    "il/elle %%devra%%",
    "nous %%devrons%%",
    "vous %%devrez%%",
    "ils/elles %%devront%%",
  ],
  futurAnterieur: [
    "j'%%aurai dû%%",
    "tu %%auras dû%%",
    "il/elle %%aura dû%%",
    "nous %%aurons dû%%",
    "vous %%aurez dû%%",
    "ils/elles %%auront dû%%",
  ],
  subjonctif_present: [
    "que je %%doive%%",
    "que tu %%doives%%",
    "qu'il/elle %%doive%%",
    "que nous %%devions%%",
    "que vous %%deviez%%",
    "qu'ils/elles %%doivent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie dû%%",
    "que tu %%aies dû%%",
    "qu'il/elle %%ait dû%%",
    "que nous %%ayons dû%%",
    "que vous %%ayez dû%%",
    "qu'ils/elles %%aient dû%%",
  ],
  subjonctif_imparfait: [
    "que je %%dusse%%",
    "que tu %%dusses%%",
    "qu'il/elle %%dût%%",
    "que nous %%dussions%%",
    "que vous %%dussiez%%",
    "qu'ils/elles %%dussent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse dû%%",
    "que tu %%eusses dû%%",
    "qu'il/elle %%eût dû%%",
    "que nous %%eussions dû%%",
    "que vous %%eussiez dû%%",
    "qu'ils/elles %%eussent dû%%",
  ],
  conditionnel_present: [
    "je %%devrais%%",
    "tu %%devrais%%",
    "il/elle %%devrait%%",
    "nous %%devrions%%",
    "vous %%devriez%%",
    "ils/elles %%devraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais dû%%",
    "tu %%aurais dû%%",
    "il/elle %%aurait dû%%",
    "nous %%aurions dû%%",
    "vous %%auriez dû%%",
    "ils/elles %%auraient dû%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais dû%%",
    "tu %%aurais dû%%",
    "il/elle %%aurait dû%%",
    "nous %%aurions dû%%",
    "vous %%auriez dû%%",
    "ils/elles %%auraient dû%%",
  ],
};

export default devoirConjugations;
