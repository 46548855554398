let venirConjugations = {
  present: [
    "je %%viens%%",
    "tu %%viens%%",
    "il/elle %%vient%%",
    "nous %%venons%%",
    "vous %%venez%%",
    "ils/elles %%viennent%%",
  ],
  passeCompose: [
    "je %%suis venu%%",
    "tu %%es venu%%",
    "il/elle %%est venu%%",
    "nous %%sommes venus%%",
    "vous %%êtes venus%%",
    "ils/elles %%sont venus%%",
  ],
  imparfait: [
    "je %%venais%%",
    "tu %%venais%%",
    "il/elle %%venait%%",
    "nous %%venions%%",
    "vous %%veniez%%",
    "ils/elles %%venaient%%",
  ],
  plusQueParfait: [
    "j'%%étais venu%%",
    "tu %%étais venu%%",
    "il/elle %%était venu%%",
    "nous %%étions venus%%",
    "vous %%étiez venus%%",
    "ils/elles %%étaient venus%%",
  ],
  passeSimple: [
    "je %%vins%%",
    "tu %%vins%%",
    "il/elle %%vint%%",
    "nous %%vînmes%%",
    "vous %%vîntes%%",
    "ils/elles %%vinrent%%",
  ],
  passeAnterieur: [
    "je %%fus venu%%",
    "tu %%fus venu%%",
    "il/elle %%fut venu%%",
    "nous %%fûmes venus%%",
    "vous %%fûtes venus%%",
    "ils/elles %%furent venus%%",
  ],
  futurSimple: [
    "je %%viendrai%%",
    "tu %%viendras%%",
    "il/elle %%viendra%%",
    "nous %%viendrons%%",
    "vous %%viendrez%%",
    "ils/elles %%viendront%%",
  ],
  futurAnterieur: [
    "je %%serai venu%%",
    "tu %%seras venu%%",
    "il/elle %%sera venu%%",
    "nous %%serons venus%%",
    "vous %%serez venus%%",
    "ils/elles %%seront venus%%",
  ],
  subjonctif_present: [
    "que je %%vienne%%",
    "que tu %%viennes%%",
    "qu'il/elle %%vienne%%",
    "que nous %%venions%%",
    "que vous %%veniez%%",
    "qu'ils/elles %%viennent%%",
  ],
  subjonctif_passe: [
    "que je %%sois venu%%",
    "que tu %%sois venu%%",
    "qu'il/elle %%soit venu%%",
    "que nous %%soyons venus%%",
    "que vous %%soyez venus%%",
    "qu'ils/elles %%soient venus%%",
  ],
  subjonctif_imparfait: [
    "que je %%vinsses%%",
    "que tu %%vinsses%%",
    "qu'il/elle %%vînt%%",
    "que nous %%vinssions%%",
    "que vous %%vinssiez%%",
    "qu'ils/elles %%vinssent%%",
  ],
  subjonctif_plusQueParfait: [
    "que je %%fusse venu%%",
    "que tu %%fusses venu%%",
    "qu'il/elle %%fût venu%%",
    "que nous %%fussions venus%%",
    "que vous %%fussiez venus%%",
    "qu'ils/elles %%fussent venus%%",
  ],
  conditionnel_present: [
    "je %%viendrais%%",
    "tu %%viendrais%%",
    "il/elle %%viendrait%%",
    "nous %%viendrions%%",
    "vous %%viendriez%%",
    "ils/elles %%viendraient%%",
  ],
  conditionnel_passePremiereForme: [
    "je %%serais venu%%",
    "tu %%serais venu%%",
    "il/elle %%serait venu%%",
    "nous %%serions venus%%",
    "vous %%seriez venus%%",
    "ils/elles %%seraient venus%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "je %%serais venu%%",
    "tu %%serais venu%%",
    "il/elle %%serait venu%%",
    "nous %%serions venus%%",
    "vous %%seriez venus%%",
    "ils/elles %%seraient venus%%",
  ],
};

export default venirConjugations;
