let allerConjugations = {
  present: [
    "je %%vais%%",
    "tu %%vas%%",
    "il/elle %%va%%",
    "nous %%allons%%",
    "vous %%allez%%",
    "ils/elles %%vont%%",
  ],
  passeCompose: [
    "je %%suis allé%%",
    "tu %%es allé%%",
    "il/elle %%est allé%%",
    "nous %%sommes allés%%",
    "vous %%êtes allés%%",
    "ils/elles %%sont allés%%",
  ],
  imparfait: [
    "j'%%allais%%",
    "tu %%allais%%",
    "il/elle %%allait%%",
    "nous %%allions%%",
    "vous %%alliez%%",
    "ils/elles %%allaient%%",
  ],
  plusQueParfait: [
    "j'%%étais allé%%",
    "tu %%étais allé%%",
    "il/elle %%était allé%%",
    "nous %%étions allés%%",
    "vous %%étiez allés%%",
    "ils/elles %%étaient allés%%",
  ],
  passeSimple: [
    "j'%%allai%%",
    "tu %%allas%%",
    "il/elle %%alla%%",
    "nous %%allâmes%%",
    "vous %%allâtes%%",
    "ils/elles %%allèrent%%",
  ],
  passeAnterieur: [
    "je %%fus allé%%",
    "tu %%fus allé%%",
    "il/elle %%fut allé%%",
    "nous %%fûmes allés%%",
    "vous %%fûtes allés%%",
    "ils/elles %%furent allés%%",
  ],
  futurSimple: [
    "j'%%irai%%",
    "tu %%iras%%",
    "il/elle %%ira%%",
    "nous %%irons%%",
    "vous %%irez%%",
    "ils/elles %%iront%%",
  ],
  futurAnterieur: [
    "j'%%aurai été%%",
    "tu %%auras été%%",
    "il/elle %%aura été%%",
    "nous %%aurons été%%",
    "vous %%aurez été%%",
    "ils/elles %%auront été%%",
  ],
  subjonctif_present: [
    "que j'%%aille%%",
    "que tu %%ailles%%",
    "qu'il/elle %%aille%%",
    "que nous %%allions%%",
    "que vous %%alliez%%",
    "qu'ils/elles %%aillent%%",
  ],
  subjonctif_passe: [
    "que je %%sois allé%%",
    "que tu %%sois allé%%",
    "qu'il/elle %%soit allé%%",
    "que nous %%soyons allés%%",
    "que vous %%soyez allés%%",
    "qu'ils/elles %%soient allés%%",
  ],
  subjonctif_imparfait: [
    "que j'%%allasse%%",
    "que tu %%allasses%%",
    "qu'il/elle %%allât%%",
    "que nous %%allassions%%",
    "que vous %%allassiez%%",
    "qu'ils/elles %%allassent%%",
  ],
  subjonctif_plusQueParfait: [
    "que je %%fusse allé%%",
    "que tu %%fusses allé%%",
    "qu'il/elle %%fût allé%%",
    "que nous %%fussions allés%%",
    "que vous %%fussiez allés%%",
    "qu'ils/elles %%fussent allés%%",
  ],
  conditionnel_present: [
    "j'%%irais%%",
    "tu %%irais%%",
    "il/elle %%irait%%",
    "nous %%irions%%",
    "vous %%iriez%%",
    "ils/elles %%iraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais été%%",
    "tu %%aurais été%%",
    "il/elle %%aurait été%%",
    "nous %%aurions été%%",
    "vous %%auriez été%%",
    "ils/elles %%auraient été%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais été%%",
    "tu %%aurais été%%",
    "il/elle %%aurait été%%",
    "nous %%aurions été%%",
    "vous %%auriez été%%",
    "ils/elles %%auraient été%%",
  ],
};

export default allerConjugations;
