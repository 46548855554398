import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SelectionPage from "./pages/SelectionPage";
import ExercisePage from "./pages/ExercisePage";
import NotFoundRedirect from "./pages/NotFoundRedirect";
import Header from "./components/Header";
import Footer from "./components/Footer";
import './App.css';
import ConjugationPage from './pages/ConjugationPage';

const App: React.FC = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Header />
      <main className="bg-light flex-grow-1 py-3">
        <Router>
          <Routes>
            <Route path="/" element={<SelectionPage />} />
            <Route path="/exercise/:tense/:verb" element={<ExercisePage />} />
            <Route path="/conjugation/:verb" element={<ConjugationPage />} />
            <Route path="*" element={<NotFoundRedirect />} />
          </Routes>
        </Router>
      </main>
      <Footer />
    </div>
  );
};

export default App;
