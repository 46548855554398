// src/components/ExerciseArea.tsx
import React, { useState } from "react";
import AccentKeyboard from "./common/AccentKeyboard";

interface ExerciseAreaProps {
  verb: string;
  tense: string;
  phraseList: string[];
  onUserAnswersChange: (answers: string[]) => void;
  feedback: boolean[] | null;
}

const ExerciseArea: React.FC<ExerciseAreaProps> = ({
  onUserAnswersChange,
  feedback,
  phraseList,
}) => {
  const [userAnswers, setUserAnswers] = useState<string[]>(Array(10).fill(""));
  const [currentInputIndex, setCurrentInputIndex] = useState<number>(-1);
  const [currentInput, setCurrentInput] = useState<HTMLInputElement | null>(
    null
  );
  const [showAccentKeyboard, setShowAccentKeyboard] = useState<boolean>(false);

  const handleInputChange = (index: number, value: string) => {
    const newAnswers = [...userAnswers];
    newAnswers[index] = value;
    setUserAnswers(newAnswers);
    onUserAnswersChange(newAnswers);
  };

  const handleCharClick = (char: string) => {
    if (currentInput === null) return;
    const currentAnswer = userAnswers[currentInputIndex];
    const cursorPos = currentInput.selectionStart;
    if (cursorPos === null) {
      handleInputChange(
        currentInputIndex,
        userAnswers[currentInputIndex] + char
      );
      return;
    }
    const newAnswer =
      currentAnswer.slice(0, cursorPos) + char + currentAnswer.slice(cursorPos);
    handleInputChange(currentInputIndex, newAnswer);
    currentInput.focus();
    requestAnimationFrame(() => {
      currentInput.selectionStart = currentInput.selectionEnd = cursorPos + 1;
    });
  };

  return (
    <div className="row">
      <div className="col">
        <button
          type="button"
          className="btn btn-sm btn-secondary active"
          data-bs-toggle="button"
          onClick={() => setShowAccentKeyboard(!showAccentKeyboard)}
        >
          éôç
        </button>
        {showAccentKeyboard && (
          <AccentKeyboard
            onCharClick={handleCharClick}
            index={currentInputIndex}
          />
        )}
        <ul className="list-group">
          {phraseList.map((sentence: string, index: number) => {
            const [before, correctAnswer, after] = sentence.split("%%");
            const isCorrect = feedback ? feedback[index] : null;
            const showCorrectAnswer = false;

            return (
              <li
                className={`list-group-item px-3 ${
                  isCorrect === null
                    ? ""
                    : isCorrect
                    ? "list-group-item-success"
                    : "list-group-item-danger"
                }`}
                key={index}
              >
                {isCorrect !== null && (
                  <i
                    className={`bi ${
                      isCorrect
                        ? "bi-check-circle-fill text-success"
                        : "bi-x-circle-fill text-danger"
                    }`}
                  ></i>
                )}{" "}
                <span>{before}</span>
                <input
                  size={10}
                  type="text"
                  value={userAnswers[index]}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onFocus={(e) => {
                    setCurrentInputIndex(index);
                    setCurrentInput(e.target);
                  }}
                />
                <span>{after}</span>
                {showCorrectAnswer && !isCorrect && (
                  <span> {correctAnswer} </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ExerciseArea;
