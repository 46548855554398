import { useParams } from "react-router-dom";
import conjugation from "../data/tables";
import { verbs, tenses as tenseDictionary } from "../data/constants";
import ReturnButton from "../components/common/ReturnButton";

function ConjugationPage() {
  const { verb } = useParams();
  const { title: verbTitle } = verbs.find((v) => v.id === verb) || {
    title: "",
  };

  const tensesGroup = {
    Indicatif: [
      "present",
      "passeCompose",
      "imparfait",
      "plusQueParfait",
      "passeSimple",
      "passeAnterieur",
      "futurSimple",
      "futurAnterieur",
    ],
    Subjonctif: [
      "subjonctif_present",
      "subjonctif_passe",
      "subjonctif_imparfait",
      "subjonctif_plusQueParfait",
    ],
    Conditionnel: [
      "conditionnel_present",
      "conditionnel_passePremiereForme",
      "conditionnel_passeDeuxiemeForme",
    ],
  };

  return (
    <div className="container">
      <ReturnButton />
      {verb && verbTitle && conjugation[verb] && (
        <>
          <h1 className="text-center mt-3 mb-3">{verbTitle}</h1>
          {Object.entries(tensesGroup).map(([group, tenses]) => (
            <div key={group} className="row">
              <h2 className="text-center mt-3 mb-3">{group}</h2>
              {tenses.map((tense) => {
                const tenseTitle = tenseDictionary.find(
                  (t) => t.id === tense
                )?.title;

                return (
                  <div key={tense} className="mb-3 col-sm-12 col-md-6 col-lg-3">
                    <ul className="list-group">
                      <li className="list-group-item capitalize fw-bold active">
                        {tenseTitle}
                      </li>
                      {conjugation[verb][tense].map((conjugation, index) => {
                        const [pronoun, conjugationText] =
                          conjugation.split("%%");
                        return (
                          <li key={index} className="list-group-item py-2">
                            {pronoun}
                            <span className="fw-bold">{conjugationText}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          ))}
          <ReturnButton />
        </>
      )}
    </div>
  );
}

export default ConjugationPage;
