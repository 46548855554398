const pouvoir: any = {
  pouvoir: {
    present: [
      "Je %%peux%% aller à la gym ce soir.",
      "Tu %%peux%% prendre le paraplu, il va pleuvoir.",
      "Il %%peut%% résoudre des équations complexes.",
      "Nous %%pouvons%% voyager en Europe cet été.",
      "Le directeur %%peut%% accorder une augmentation.",
      "Vous %%pouvez%% participer à la réunion en ligne.",
      "Ils %%peuvent%% assister au concert demain.",
      "Ma sœur %%peut%% parler trois langues.",
      "Les enfants %%peuvent%% manger des légumes.",
      "On %%peut%% faire une différence si on essaie.",
      "Les employés %%peuvent%% prendre une pause-café.",
      "Mes collègues %%peuvent%% aider avec le projet.",
      "Tu %%peux%% écrire un article pour le blog.",
      "L'arbitre %%peut%% donner un carton rouge.",
      "Vous %%pouvez%% tourner à droite au feu.",
      "Les étudiants %%peuvent%% utiliser leurs ordinateurs.",
      "Ma mère %%peut%% venir me chercher à l'école.",
      "Le chien %%peut%% aboyer quand il est seul.",
      "Elle %%peut%% cuisiner des plats délicieux.",
      "Le photographe %%peut%% capturer de beaux moments.",
      "Nous %%pouvons%% regarder un film ce soir.",
      "Les scientifiques %%peuvent%% trouver un remède.",
      "Je %%peux%% dessiner un portrait pour toi.",
      "Les joueurs %%peuvent%% pratiquer le lundi.",
      "Tu %%peux%% choisir entre ces deux options.",
      "Les bénévoles %%peuvent%% collecter des dons.",
      "Ma tante %%peut%% tricoter une écharpe.",
      "Le serveur %%peut%% apporter l'addition.",
      "Vous %%pouvez%% arrêter de fumer.",
      "Ils %%peuvent%% courir un marathon.",
    ],
    imparfait: [
      "Je %%pouvais%% nager pendant des heures.",
      "Tu %%pouvais%% jouer du piano assez bien.",
      "Il %%pouvait%% résoudre des problèmes difficiles.",
      "Nous %%pouvions%% aller à la plage chaque dimanche.",
      "Le professeur %%pouvait%% enseigner plusieurs matières.",
      "Vous %%pouviez%% terminer le travail rapidement.",
      "Elles %%pouvaient%% courir très vite.",
      "Ma mère %%pouvait%% cuisiner des repas délicieux.",
      "Les étudiants %%pouvaient%% rester en bibliothèque jusqu'à tard.",
      "On %%pouvait%% voir les étoiles depuis le jardin.",
      "Les voisins %%pouvaient%% entendre la musique.",
      "Mes amis %%pouvaient%% rester jusqu'à minuit.",
      "Tu %%pouvais%% prendre le bus pour aller au lycée.",
      "La foule %%pouvait%% être bruyante lors des fêtes.",
      "Vous %%pouviez%% acheter des billets en ligne.",
      "Les enfants %%pouvaient%% grimper aux arbres.",
      "Mon père %%pouvait%% réparer n'importe quelle voiture.",
      "Les oiseaux %%pouvaient%% chanter toute la journée.",
      "Elle %%pouvait%% lire des romans en une journée.",
      "Le vendeur %%pouvait%% négocier les prix.",
      "Nous %%pouvions%% camper dans la forêt.",
      "Les clients %%pouvaient%% payer en espèces ou par carte.",
      "Je %%pouvais%% dessiner des portraits réalistes.",
      "Les médecins %%pouvaient%% travailler de longues heures.",
      "Tu %%pouvais%% me battre aux échecs.",
      "Les danseurs %%pouvaient%% s'entraîner tous les jours.",
      "Ma grand-mère %%pouvait%% raconter des histoires incroyables.",
      "Le soleil %%pouvait%% briller même en hiver.",
      "Vous %%pouviez%% compter sur moi.",
      "Ils %%pouvaient%% faire du ski chaque hiver.",
    ],
    futurSimple: [
      "Je %%pourrai%% aller à la conférence demain.",
      "Tu %%pourras%% visiter tes grands-parents le week-end prochain.",
      "Il %%pourra%% terminer le projet d'ici lundi.",
      "Nous %%pourrons%% assister au concert en juillet.",
      "La police %%pourra%% enquêter sur l'affaire.",
      "Vous %%pourrez%% vous détendre après les examens.",
      "Elles %%pourront%% voyager après la pandémie.",
      "Mes parents %%pourront%% prendre leur retraite l'année prochaine.",
      "Les athlètes %%pourront%% reprendre les compétitions.",
      "On %%pourra%% faire une fête quand tout sera fini.",
      "Les journalistes %%pourront%% poser leurs questions.",
      "Ma sœur et moi %%pourrons%% partager la chambre d'hôtel.",
      "Tu %%pourras%% prendre des photos à la cérémonie.",
      "Les ingénieurs %%pourront%% résoudre le problème.",
      "Vous %%pourrez%% vous inscrire en septembre.",
      "Les élèves %%pourront%% utiliser les ordinateurs pour la recherche.",
      "Mon oncle %%pourra%% nous rendre visite pendant les vacances.",
      "Le gouvernement %%pourra%% appliquer les nouvelles lois.",
      "Elle %%pourra%% finir son livre cette semaine.",
      "Les fans %%pourront%% acheter des billets en ligne.",
      "Nous %%pourrons%% dîner ensemble vendredi soir.",
      "Les professeurs %%pourront%% corriger les copies plus rapidement.",
      "Je %%pourrai%% t'aider avec tes devoirs demain.",
      "Les agriculteurs %%pourront%% récolter les fruits en automne.",
      "Tu %%pourras%% rejoindre notre club de sport.",
      "Les cinéphiles %%pourront%% regarder le film en avant-première.",
      "Ma tante %%pourra%% assister au mariage.",
      "Les astronautes %%pourront%% explorer Mars.",
      "Vous %%pourrez%% postuler à des emplois après la formation.",
      "Ils %%pourront%% participer à la course de vélo."
    ]
  },
};

export default pouvoir;