const voir: any = {
  voir: {
    present: [
      "Je %%vois%% clairement la solution.",
      "Tu %%vois%% les étoiles la nuit?",
      "Il %%voit%% le verre à moitié plein.",
      "Nous %%voyons%% régulièrement nos grands-parents.",
      "Les enfants %%voient%% un arc-en-ciel.",
      "Vous %%voyez%% la différence?",
      "Elles %%voient%% la vie en rose.",
      "Mon médecin %%voit%% plusieurs patients par jour.",
      "Les touristes %%voient%% le monument pour la première fois.",
      "On %%voit%% bien depuis cette colline.",
      "Mes collègues %%voient%% les avantages du nouveau système.",
      "Tu %%vois%% le potentiel dans cette idée?",
      "Le photographe %%voit%% l'instant parfait pour le cliché.",
      "Vous %%voyez%% souvent des films le weekend?",
      "Les écologistes %%voient%% l'urgence du changement climatique.",
      "Mon chien %%voit%% un chat dans l'arbre.",
      "L'artiste %%voit%% la beauté dans les détails.",
      "Elle %%voit%% son avenir très clairement.",
      "Le pilote %%voit%% la piste d'atterrissage.",
      "Nous %%voyons%% l'importance de la solidarité.",
      "Les professeurs %%voient%% les progrès de leurs élèves.",
      "Je %%vois%% bien ce que tu veux dire.",
      "Les spectateurs %%voient%% un match passionnant.",
      "Tu %%vois%% beaucoup de clients dans ton travail?",
      "Les citoyens %%voient%% le besoin de réformes.",
      "Mon ami et moi %%voyons%% un film ce soir.",
      "La serveuse %%voit%% que nous sommes prêts à commander.",
      "Vous %%voyez%% vos erreurs comme des opportunités?",
      "Les architectes %%voient%% un espace sous-utilisé.",
      "Ils %%voient%% les choses différemment.",
    ],
    imparfait: [
      "Je %%voyais%% souvent des étoiles filantes dans mon enfance.",
      "Tu %%voyais%% des oiseaux exotiques lors de ton voyage?",
      "Il %%voyait%% toujours le bon côté des choses.",
      "Nous %%voyions%% nos voisins chaque weekend.",
      "Les musiciens %%voyaient%% leur public grandir.",
      "Vous %%voyiez%% des films d'art et essai?",
      "Elles %%voyaient%% les choses sous un autre angle.",
      "Mon oncle %%voyait%% des patients toute la journée.",
      "Les touristes %%voyaient%% rarement cette partie de la ville.",
      "On %%voyait%% clairement la montagne depuis la fenêtre.",
      "Mes amis %%voyaient%% souvent des spectacles.",
      "Tu %%voyais%% l'utilité de ce cours?",
      "La grand-mère %%voyait%% ses petits-enfants chaque dimanche.",
      "Vous %%voyiez%% souvent des concerts classiques?",
      "Les scientifiques %%voyaient%% les résultats de leurs expériences.",
      "Mon chat %%voyait%% des oiseaux par la fenêtre.",
      "Le peintre %%voyait%% le monde en couleurs vives.",
      "Elle %%voyait%% des opportunités partout.",
      "Le conducteur %%voyait%% la route sinueuse devant lui.",
      "Nous %%voyions%% la pluie tomber sans fin.",
      "Les joueurs %%voyaient%% leurs fans dans les gradins.",
      "Je %%voyais%% ce que tu voulais dire.",
      "Les écoliers %%voyaient%% leur professeur tous les jours.",
      "Tu %%voyais%% du monde dans ton café?",
      "Les fermiers %%voyaient%% leur récolte se développer.",
      "Ma sœur et moi %%voyions%% des dessins animés le samedi.",
      "Le gardien %%voyait%% les visiteurs arriver.",
      "Vous %%voyiez%% votre vie différemment avant?",
      "Les chefs d'entreprise %%voyaient%% la concurrence augmenter.",
      "Ils %%voyaient%% des signes encourageants.",
    ],
    futurSimple: [
      "Je %%verrai%% le résultat demain.",
      "Tu %%verras%% ce que je veux dire.",
      "Il %%verra%% les avantages bientôt.",
      "Nous %%verrons%% nos amis l'été prochain.",
      "Les voyageurs %%verront%% la nouvelle aile de l'aéroport.",
      "Vous %%verrez%% la différence immédiatement.",
      "Elles %%verront%% la beauté naturelle de la région.",
      "Ma tante %%verra%% le médecin la semaine prochaine.",
      "Les étudiants %%verront%% leurs notes à la fin du semestre.",
      "On %%verra%% comment ça se passe.",
      "Mes parents %%verront%% l'exposition d'art contemporain.",
      "Tu %%verras%% le nouveau film de ce réalisateur?",
      "Le chef d'orchestre %%verra%% les musiciens demain.",
      "Vous %%verrez%% la météo avant de partir?",
      "Les habitants %%verront%% les travaux se terminer enfin.",
      "Mon frère %%verra%% le match en direct.",
      "L'investisseur %%verra%% le potentiel de cette startup.",
      "Elle %%verra%% ses efforts récompensés.",
      "Le pilote %%verra%% le ciel dégagé.",
      "Nous %%verrons%% si c'est possible.",
      "Les enfants %%verront%% le Père Noël au centre commercial.",
      "Je %%verrai%% mon erreur quand je l'%%verrai%%.",
      "Les spectateurs %%verront%% un match inoubliable.",
      "Tu %%verras%% plusieurs options sur la carte.",
      "Les écologistes %%verront%% les effets du reboisement.",
      "Toi et moi %%verrons%% l'aube ensemble.",
      "Le président %%verra%% les ambassadeurs dans la matinée.",
      "Vous %%verrez%% de quoi je parle.",
      "Les architectes %%verront%% le nouveau projet urbain.",
      "Ils %%verront%% leurs rêves se réaliser.",
    ],
  },
};

export default voir;
