
const savoir: any = {
  savoir: {
    present: [
      "Je %%sais%% où se trouve la clé.",
      "Tu %%sais%% cuisiner comme un chef.",
      "Il %%sait%% nager très bien.",
      "Nous %%savons%% que la vérité finira par éclater.",
      "Les journalistes %%savent%% comment poser les bonnes questions.",
      "Vous %%savez%% jouer du piano?",
      "Elles %%savent%% danser toute la nuit.",
      "Ma tante %%sait%% parler quatre langues.",
      "Les médecins %%savent%% ce qu'ils font.",
      "On %%sait%% que le changement est nécessaire.",
      "Mes collègues %%savent%% utiliser le nouveau logiciel.",
      "Tu %%sais%% où sont mes clés?",
      "La police %%sait%% qui est le coupable.",
      "Vous %%savez%% comment éviter les embouteillages.",
      "Les ingénieurs %%savent%% résoudre des problèmes complexes.",
      "Mon frère %%sait%% jouer de la guitare.",
      "L'artiste %%sait%% capturer l'émotion.",
      "Elle %%sait%% courir un marathon.",
      "Le serveur %%sait%% ce qui est bon sur le menu.",
      "Nous %%savons%% qu'il va pleuvoir demain.",
      "Les investisseurs %%savent%% quand acheter et vendre.",
      "Je %%sais%% que tu as raison.",
      "Les enfants %%savent%% comment s'amuser.",
      "Tu %%sais%% négocier un bon contrat.",
      "Les avocats %%savent%% comment persuader le jury.",
      "Ma mère %%sait%% que j'ai menti.",
      "Le pilote %%sait%% comment atterrir en toute sécurité.",
      "Vous %%savez%% où se trouve le trésor caché?",
      "Les élèves %%savent%% que l'examen sera difficile.",
      "Ils %%savent%% comment survivre dans la jungle."
    ],
    imparfait: [
      "Je %%savais%% où se cachaient les bonbons.",
      "Tu %%savais%% comment faire les meilleurs gâteaux.",
      "Il %%savait%% résoudre des équations compliquées.",
      "Nous %%savions%% que quelque chose n'allait pas.",
      "Les détectives %%savaient%% suivre les pistes.",
      "Vous %%saviez%% jouer de plusieurs instruments.",
      "Elles %%savaient%% qu'elles étaient en retard.",
      "Ma grand-mère %%savait%% toujours quand j'étais triste.",
      "Les astronautes %%savaient%% les risques associés à la mission.",
      "On %%savait%% que la fête serait incroyable.",
      "Mes amis %%savaient%% comment me faire rire.",
      "Tu %%savais%% où trouver les meilleures affaires.",
      "L'acteur %%savait%% captiver son public.",
      "Vous %%saviez%% comment cultiver des légumes.",
      "Les journalistes %%savaient%% écrire de bons articles.",
      "Mon voisin %%savait%% pêcher comme un pro.",
      "La professeure %%savait%% intéresser ses élèves.",
      "Elle %%savait%% coudre ses propres vêtements.",
      "Le chauffeur %%savait%% éviter le trafic.",
      "Nous %%savions%% comment nous échapper en cas d'urgence.",
      "Les mécaniciens %%savaient%% réparer presque tout.",
      "Je %%savais%% qu'il était sincère.",
      "Les athlètes %%savaient%% comment gérer la pression.",
      "Tu %%savais%% jouer aux échecs.",
      "Les écrivains %%savaient%% créer des mondes imaginaires.",
      "Ma soeur %%savait%% que j'étais coupable.",
      "Le maire %%savait%% comment gagner les votes.",
      "Vous %%saviez%% où trouver de l'eau potable.",
      "Les scouts %%savaient%% faire un feu.",
      "Ils %%savaient%% se débrouiller en pleine nature."
    ],
    futurSimple: [
      "Je %%saurai%% la vérité tôt ou tard.",
      "Tu %%sauras%% cuisiner comme un chef après ce cours.",
      "Il %%saura%% résoudre ce problème, j'en suis sûr.",
      "Nous %%saurons%% si nous avons réussi demain.",
      "Les investisseurs %%sauront%% quand vendre leurs actions.",
      "Vous %%saurez%% jouer du violon avec du temps et de la pratique.",
      "Elles %%sauront%% si elles sont acceptées dans l'université la semaine prochaine.",
      "Mon père %%saura%% comment réparer cette voiture.",
      "Les experts %%sauront%% analyser les données.",
      "On %%saura%% qui a gagné après le décompte des votes.",
      "Mes parents %%sauront%% mon secret si je ne fais pas attention.",
      "Tu %%sauras%% quel est ton vrai talent un jour.",
      "Le jury %%saura%% qui est le gagnant à la fin de la compétition.",
      "Vous %%saurez%% où aller une fois que vous aurez la carte.",
      "Les danseurs %%sauront%% exécuter la routine parfaitement.",
      "Ma fille %%saura%% lire avant la fin de l'année.",
      "L'équipe %%saura%% comment gagner le championnat.",
      "Elle %%saura%% prendre les bonnes décisions en tant que leader.",
      "Le capitaine %%saura%% naviguer à travers la tempête.",
      "Nous %%saurons%% comment agir en cas d'urgence.",
      "Les ingénieurs %%sauront%% finaliser le projet à temps.",
      "Je %%saurai%% où investir mon argent.",
      "Les politiciens %%sauront%% ce que veulent les électeurs.",
      "Tu %%sauras%% quel chemin prendre.",
      "Les biologistes %%sauront%% si cette espèce est en danger.",
      "Mon ami %%saura%% comment m'aider.",
      "La directrice %%saura%% augmenter les profits de la société.",
      "Vous %%saurez%% quand partir pour éviter le trafic.",
      "Les étudiants %%sauront%% le résultat de leurs examens vendredi.",
      "Ils %%sauront%% survivre dans n'importe quelle situation."
    ]
  }
}

export default savoir;