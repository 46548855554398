import React from "react";

const Header: React.FC = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-dark bg-primary">
        <div className="container justify-content-center">
          <span className="navbar-text display-6 cursor-pointer text-white fw-semibold">
            Conjugues!
          </span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
