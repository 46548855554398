import React from "react";
import { useNavigate } from "react-router-dom";

const ReturnButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <button type="button" className="btn" onClick={() => navigate("/")}>
      <i className="bi bi-arrow-left"></i> Retour
    </button>
  );
};

export default ReturnButton;
