import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="footer bg-primary text-center text-white cursor-pointer">
      <div className="container small">
        <span>© 2023 AZ Design Studio. Tous droits réservés.</span>
        <br />
        <span>
          Soutien technique:
          <a
            className="link-body-emphasis mx-1"
            href="mailto:conjugues@az-design.ca"
            target="_blank"
            rel="noreferrer"
          >
            conjugues@az-design.ca
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
