const aimer = {
  aimer: {
    present: [
      "J'%%aime%% les pommes.",
      "Tu %%aimes%% courir le matin.",
      "Elle %%aime%% les films romantiques.",
      "Nous %%aimons%% le café.",
      "Vous %%aimez%% voyager.",
      "Ils %%aiment%% la musique classique.",
      "On %%aime%% se promener.",
      "Qui %%aime%% les mathématiques ?",
      "Que %%aimes%%-tu faire pendant ton temps libre ?",
      "Qu'est-ce que nous %%aimons%% dans ce pays ?",
      "Mon chien %%aime%% la nourriture.",
      "Les enfants %%aiment%% le parc.",
      "Ma mère %%aime%% le thé.",
      "Les professeurs %%aiment%% les étudiants studieux.",
      "Notre équipe %%aime%% ce nouveau projet.",
      "Le public %%aime%% cette pièce de théâtre.",
      "Mon voisin %%aime%% le jardinage.",
      "La foule %%aime%% la musique.",
      "Le chef %%aime%% ce nouveau menu.",
      "Mon ami %%aime%% voyager.",
    ],
    imparfait: [
      "J'%%aimais%% les étoiles.",
      "Tu %%aimais%% aller à la pêche.",
      "Elle %%aimait%% dessiner.",
      "Nous %%aimions%% jouer au football.",
      "Vous %%aimiez%% les concerts de rock.",
      "Ils %%aimaient%% cuisiner ensemble.",
      "On %%aimait%% faire du shopping.",
      "Qui %%aimait%% la science-fiction ?",
      "Ma soeur %%aimait%% les romans historiques.",
      "Le boulanger %%aimait%% préparer du pain frais.",
    ],
    passeSimple: [
      "J'%%aimai%% le jazz.",
      "Tu %%aimas%% le voyage en Espagne.",
      "Elle %%aima%% le spectacle de magie.",
      "Nous %%aimâmes%% la conférence sur les dinosaures.",
      "Vous %%aimâtes%% le dessert italien.",
      "Ils %%aimèrent%% la pièce de théâtre.",
      "On %%aima%% le discours du maire.",
      "Qui %%aima%% la leçon de philosophie ?",
      "Ma grand-mère %%aima%% le bouquet de fleurs.",
      "Le public %%aima%% le discours du comédien.",
    ],
    passeCompose: [
      "J'%%ai aimé%% l'exposition d'art moderne.",
      "Tu %%as aimé%% le film d'action.",
      "Elle %%a aimé%% la promenade en forêt.",
      "Nous %%avons aimé%% la pièce de Mozart.",
      "Vous %%avez aimé%% la visite du musée.",
      "Ils %%ont aimé%% la randonnée en montagne.",
      "On %%a aimé%% le concert de jazz.",
      "Qui %%a aimé%% le roman policier ?",
      "Mon frère %%a aimé%% le match de football.",
      "La critique %%a aimé%% la nouvelle pièce de théâtre.",
    ],
    futurSimple: [
      "J'%%aimerai%% la nouvelle chanson de mon groupe préféré.",
      "Tu %%aimeras%% sûrement ce livre de science-fiction.",
      "Il %%aimera%% certainement son cadeau d'anniversaire.",
      "Elle %%aimera%% le spectacle de danse, je suis sûr.",
      "Nous %%aimerons%% visiter ce nouveau musée d'art moderne.",
      "Vous %%aimerez%% les plats dans ce restaurant italien.",
      "Ils %%aimeront%% leur voyage en Europe, sans aucun doute.",
      "Mes parents %%aimeront%% sûrement le concert de piano.",
      "Mon frère et moi %%aimerons%% certainement le nouveau film de science-fiction.",
      "Le chat %%aimera%% sa nouvelle maison.",
      "Ma cousine %%aimera%% son nouvel emploi.",
      "Mon oncle et ma tante %%aimeront%% le spectacle de cirque.",
      "Les étudiants %%aimeront%% le cours de philosophie, je pense.",
      "Les touristes %%aimeront%% la vue panoramique de la tour.",
      "L'audience %%aimera%% le discours du conférencier.",
      "Le jardinier %%aimera%% les nouvelles graines de fleurs.",
      "Ma nièce %%aimera%% le parc d'attractions.",
      "Le directeur %%aimera%% l'initiative des employés.",
      "Le jury %%aimera%% son audition, elle a beaucoup de talent.",
      "Les enfants %%aimeront%% le spectacle de marionnettes.",
      "La foule %%aimera%% le spectacle de feux d'artifice.",
      "Le lecteur %%aimera%% ce nouveau roman d'aventure.",
      "Le professeur %%aimera%% l'enthousiasme des étudiants.",
      "Le cuisinier %%aimera%% ces nouveaux ustensiles de cuisine.",
      "Mon ami et toi %%aimerez%% ce café près de la rivière.",
      "Les clients %%aimeront%% la nouvelle collection de vêtements.",
      "Le photographe %%aimera%% le paysage montagneux.",
      "Les spectateurs %%aimeront%% la nouvelle saison de la série.",
      "Le peintre %%aimera%% l'inspiration que lui apporte la nature.",
      "Le public %%aimera%% le nouveau spectacle de comédie musicale.",
    ],
  },
};

export default aimer;
