let prendreConjugations = {
  present: [
    "je %%prends%%",
    "tu %%prends%%",
    "il/elle %%prend%%",
    "nous %%prenons%%",
    "vous %%prenez%%",
    "ils/elles %%prennent%%",
  ],
  passeCompose: [
    "j'%%ai pris%%",
    "tu %%as pris%%",
    "il/elle %%a pris%%",
    "nous %%avons pris%%",
    "vous %%avez pris%%",
    "ils/elles %%ont pris%%",
  ],
  imparfait: [
    "je %%prenais%%",
    "tu %%prenais%%",
    "il/elle %%prenait%%",
    "nous %%prenions%%",
    "vous %%preniez%%",
    "ils/elles %%prenaient%%",
  ],
  plusQueParfait: [
    "j'%%avais pris%%",
    "tu %%avais pris%%",
    "il/elle %%avait pris%%",
    "nous %%avions pris%%",
    "vous %%aviez pris%%",
    "ils/elles %%avaient pris%%",
  ],
  passeSimple: [
    "je %%pris%%",
    "tu %%pris%%",
    "il/elle %%prit%%",
    "nous %%prîmes%%",
    "vous %%prîtes%%",
    "ils/elles %%prirent%%",
  ],
  passeAnterieur: [
    "j'%%eus pris%%",
    "tu %%eus pris%%",
    "il/elle %%eut pris%%",
    "nous %%eûmes pris%%",
    "vous %%eûtes pris%%",
    "ils/elles %%eurent pris%%",
  ],
  futurSimple: [
    "je %%prendrai%%",
    "tu %%prendras%%",
    "il/elle %%prendra%%",
    "nous %%prendrons%%",
    "vous %%prendrez%%",
    "ils/elles %%prendront%%",
  ],
  futurAnterieur: [
    "j'%%aurai pris%%",
    "tu %%auras pris%%",
    "il/elle %%aura pris%%",
    "nous %%aurons pris%%",
    "vous %%aurez pris%%",
    "ils/elles %%auront pris%%",
  ],
  subjonctif_present: [
    "que je %%prenne%%",
    "que tu %%prennes%%",
    "qu'il/elle %%prenne%%",
    "que nous %%prenions%%",
    "que vous %%preniez%%",
    "qu'ils/elles %%prennent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie pris%%",
    "que tu %%aies pris%%",
    "qu'il/elle %%ait pris%%",
    "que nous %%ayons pris%%",
    "que vous %%ayez pris%%",
    "qu'ils/elles %%aient pris%%",
  ],
  subjonctif_imparfait: [
    "que je %%prisse%%",
    "que tu %%prisses%%",
    "qu'il/elle %%prît%%",
    "que nous %%prissions%%",
    "que vous %%prissiez%%",
    "qu'ils/elles %%prissent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse pris%%",
    "que tu %%eusses pris%%",
    "qu'il/elle %%eût pris%%",
    "que nous %%eussions pris%%",
    "que vous %%eussiez pris%%",
    "qu'ils/elles %%eussent pris%%",
  ],
  conditionnel_present: [
    "je %%prendrais%%",
    "tu %%prendrais%%",
    "il/elle %%prendrait%%",
    "nous %%prendrions%%",
    "vous %%prendriez%%",
    "ils/elles %%prendraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais pris%%",
    "tu %%aurais pris%%",
    "il/elle %%aurait pris%%",
    "nous %%aurions pris%%",
    "vous %%auriez pris%%",
    "ils/elles %%auraient pris%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais pris%%",
    "tu %%aurais pris%%",
    "il/elle %%aurait pris%%",
    "nous %%aurions pris%%",
    "vous %%auriez pris%%",
    "ils/elles %%auraient pris%%",
  ],
};

export default prendreConjugations;
