const vouloir: any = {
  vouloir: {
    present: [
      "Je %%veux%% un café, s'il te plaît.",
      "Tu %%veux%% aller au cinéma ce soir?",
      "Il %%veut%% réussir dans la vie.",
      "Nous %%voulons%% des vacances à la plage.",
      "Le maire %%veut%% augmenter les impôts.",
      "Vous %%voulez%% prendre l'apéro?",
      "Ils %%veulent%% acheter une nouvelle voiture.",
      "Mon père %%veut%% prendre sa retraite.",
      "Les enfants %%veulent%% des bonbons.",
      "On %%veut%% plus de justice sociale.",
      "Les voisins %%veulent%% organiser une fête.",
      "Mes amis %%veulent%% aller en randonnée.",
      "Tu %%veux%% une part de gâteau?",
      "La professeure %%veut%% plus d'attention en classe.",
      "Vous %%voulez%% encore du thé?",
      "Les employés %%veulent%% une augmentation.",
      "Ma sœur %%veut%% un nouvel ordinateur.",
      "Les touristes %%veulent%% visiter la tour Eiffel.",
      "Elle %%veut%% un chien pour son anniversaire.",
      "Le joueur %%veut%% marquer un but.",
      "Nous %%voulons%% du changement dans la société.",
      "Les actionnaires %%veulent%% des dividendes.",
      "Je %%veux%% voir les résultats.",
      "Les étudiants %%veulent%% moins de devoirs.",
      "Tu %%veux%% de l'aide avec ça?",
      "Les citoyens %%veulent%% plus de transparence.",
      "Ma mère %%veut%% que je range ma chambre.",
      "Le directeur %%veut%% un rapport complet.",
      "Vous %%voulez%% des glaçons dans votre boisson?",
      "Ils %%veulent%% un avenir meilleur."
    ],
    imparfait: [
      "Je %%voulais%% devenir astronaute quand j'étais petit.",
      "Tu %%voulais%% une nouvelle bicyclette pour Noël.",
      "Il %%voulait%% partir tôt pour éviter la circulation.",
      "Nous %%voulions%% adopter un chat de la SPA.",
      "Le directeur %%voulait%% organiser une réunion d'urgence.",
      "Vous %%vouliez%% finir le travail avant le week-end.",
      "Elles %%voulaient%% s'inscrire au cours de yoga.",
      "Mon ami %%voulait%% déménager à New York.",
      "Les étudiants %%voulaient%% plus de temps pour le projet.",
      "On %%voulait%% jouer au foot mais il pleuvait.",
      "Mes collègues %%voulaient%% déjeuner en terrasse.",
      "Tu %%voulais%% participer au concours de danse.",
      "La foule %%voulait%% voir la star de cinéma.",
      "Vous %%vouliez%% des nouvelles chaussures de sport.",
      "Les investisseurs %%voulaient%% rencontrer le PDG.",
      "Ma soeur %%voulait%% aller au parc d'attractions.",
      "Le professeur %%voulait%% corriger les copies avant midi.",
      "Elle %%voulait%% lire tous les livres de la bibliothèque.",
      "Le serveur %%voulait%% prendre notre commande.",
      "Nous %%voulions%% aller voir ce film depuis longtemps.",
      "Les clients %%voulaient%% un remboursement immédiat.",
      "Je %%voulais%% t'offrir des fleurs pour ton anniversaire.",
      "Les militants %%voulaient%% plus d'actions écologiques.",
      "Tu %%voulais%% apprendre à jouer de la guitare.",
      "Les journalistes %%voulaient%% des réponses claires.",
      "Ma grand-mère %%voulait%% que nous mangions bien.",
      "Le capitaine %%voulait%% éviter la tempête.",
      "Vous %%vouliez%% visiter le musée du Louvre.",
      "Les enfants %%voulaient%% dessiner toute la journée.",
      "Ils %%voulaient%% aller à la plage chaque été."
    ],
    futurSimple: [
      "Je %%voudrai%% un rapport complet d'ici demain.",
      "Tu %%voudras%% sans doute prendre des vacances après ce projet.",
      "Il %%voudra%% négocier les termes du contrat.",
      "Nous %%voudrons%% une table près de la fenêtre.",
      "Le jury %%voudra%% voir toutes les pièces à conviction.",
      "Vous %%voudrez%% certainement en savoir plus.",
      "Elles %%voudront%% s'inscrire au gym ensemble.",
      "Mon frère %%voudra%% participer à la compétition.",
      "Les candidats %%voudront%% connaître les résultats immédiatement.",
      "On %%voudra%% faire une grande fête quand tout sera terminé.",
      "Mes parents %%voudront%% rencontrer tes parents avant le mariage.",
      "Tu %%voudras%% réserver nos billets à l'avance.",
      "L'équipe %%voudra%% fêter si nous gagnons le match.",
      "Vous %%voudrez%% vous réchauffer avec une tasse de café.",
      "Les voyageurs %%voudront%% un remboursement après cette expérience.",
      "Ma cousine %%voudra%% aller au concert avec nous.",
      "Les enseignants %%voudront%% un planning détaillé.",
      "Elle %%voudra%% l'acheter dès qu'elle le verra.",
      "Le commerçant %%voudra%% augmenter ses prix.",
      "Nous %%voudrons%% tous un monde meilleur pour nos enfants.",
      "Les avocats %%voudront%% des déclarations écrites.",
      "Je %%voudrai%% te parler en privé plus tard.",
      "Les spectateurs %%voudront%% un rappel après le spectacle.",
      "Tu %%voudras%% essayer ce nouveau restaurant.",
      "La police %%voudra%% interroger tous les témoins.",
      "Mon amie %%voudra%% partir en vacances en Italie.",
      "Le chef %%voudra%% goûter avant de servir.",
      "Vous %%voudrez%% prendre des photos, c'est sûr.",
      "Les musiciens %%voudront%% répéter avant le concert.",
      "Ils %%voudront%% s'installer dans une grande maison."
    ]
  }
}

export default vouloir;