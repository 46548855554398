const commencerConjugations = {
  present: [
    "je %%commence%%",
    "tu %%commences%%",
    "il/elle %%commence%%",
    "nous %%commençons%%",
    "vous %%commencez%%",
    "ils/elles %%commencent%%",
  ],
  passeCompose: [
    "j'%%ai commencé%%",
    "tu %%as commencé%%",
    "il/elle %%a commencé%%",
    "nous %%avons commencé%%",
    "vous %%avez commencé%%",
    "ils/elles %%ont commencé%%",
  ],
  imparfait: [
    "je %%commençais%%",
    "tu %%commençais%%",
    "il/elle %%commençait%%",
    "nous %%commencions%%",
    "vous %%commenciez%%",
    "ils/elles %%commençaient%%",
  ],
  plusQueParfait: [
    "j'%%avais commencé%%",
    "tu %%avais commencé%%",
    "il/elle %%avait commencé%%",
    "nous %%avions commencé%%",
    "vous %%aviez commencé%%",
    "ils/elles %%avaient commencé%%",
  ],
  passeSimple: [
    "je %%commençai%%",
    "tu %%commenças%%",
    "il/elle %%commença%%",
    "nous %%commençâmes%%",
    "vous %%commençâtes%%",
    "ils/elles %%commencèrent%%",
  ],
  passeAnterieur: [
    "j'%%eus commencé%%",
    "tu %%eus commencé%%",
    "il/elle %%eut commencé%%",
    "nous %%eûmes commencé%%",
    "vous %%eûtes commencé%%",
    "ils/elles %%eurent commencé%%",
  ],
  futurSimple: [
    "je %%commencerai%%",
    "tu %%commenceras%%",
    "il/elle %%commencera%%",
    "nous %%commencerons%%",
    "vous %%commencerez%%",
    "ils/elles %%commenceront%%",
  ],
  futurAnterieur: [
    "j'%%aurai commencé%%",
    "tu %%auras commencé%%",
    "il/elle %%aura commencé%%",
    "nous %%aurons commencé%%",
    "vous %%aurez commencé%%",
    "ils/elles %%auront commencé%%",
  ],
  subjonctif_present: [
    "que je %%commence%%",
    "que tu %%commences%%",
    "qu'il/elle %%commence%%",
    "que nous %%commencions%%",
    "que vous %%commenciez%%",
    "qu'ils/elles %%commencent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie commencé%%",
    "que tu %%aies commencé%%",
    "qu'il/elle %%ait commencé%%",
    "que nous %%ayons commencé%%",
    "que vous %%ayez commencé%%",
    "qu'ils/elles %%aient commencé%%",
  ],
  subjonctif_imparfait: [
    "que je %%commençasse%%",
    "que tu %%commençasses%%",
    "qu'il/elle %%commençât%%",
    "que nous %%commençassions%%",
    "que vous %%commençassiez%%",
    "qu'ils/elles %%commençassent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse commencé%%",
    "que tu %%eusses commencé%%",
    "qu'il/elle %%eût commencé%%",
    "que nous %%eussions commencé%%",
    "que vous %%eussiez commencé%%",
    "qu'ils/elles %%eussent commencé%%",
  ],
  conditionnel_present: [
    "je %%commencerais%%",
    "tu %%commencerais%%",
    "il/elle %%commencerait%%",
    "nous %%commencerions%%",
    "vous %%commenceriez%%",
    "ils/elles %%commenceraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais commencé%%",
    "tu %%aurais commencé%%",
    "il/elle %%aurait commencé%%",
    "nous %%aurions commencé%%",
    "vous %%auriez commencé%%",
    "ils/elles %%auraient commencé%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais commencé%%",
    "tu %%aurais commencé%%",
    "il/elle %%aurait commencé%%",
    "nous %%aurions commencé%%",
    "vous %%auriez commencé%%",
    "ils/elles %%auraient commencé%%",
  ],
};

export default commencerConjugations;
