let voirConjugations = {
  present: [
    "je %%vois%%",
    "tu %%vois%%",
    "il/elle %%voit%%",
    "nous %%voyons%%",
    "vous %%voyez%%",
    "ils/elles %%voient%%",
  ],
  passeCompose: [
    "j'%%ai vu%%",
    "tu %%as vu%%",
    "il/elle %%a vu%%",
    "nous %%avons vu%%",
    "vous %%avez vu%%",
    "ils/elles %%ont vu%%",
  ],
  imparfait: [
    "je %%voyais%%",
    "tu %%voyais%%",
    "il/elle %%voyait%%",
    "nous %%voyions%%",
    "vous %%voyiez%%",
    "ils/elles %%voyaient%%",
  ],
  plusQueParfait: [
    "j'%%avais vu%%",
    "tu %%avais vu%%",
    "il/elle %%avait vu%%",
    "nous %%avions vu%%",
    "vous %%aviez vu%%",
    "ils/elles %%avaient vu%%",
  ],
  passeSimple: [
    "je %%vis%%",
    "tu %%vis%%",
    "il/elle %%vit%%",
    "nous %%vîmes%%",
    "vous %%vîtes%%",
    "ils/elles %%virent%%",
  ],
  passeAnterieur: [
    "j'%%eus vu%%",
    "tu %%eus vu%%",
    "il/elle %%eut vu%%",
    "nous %%eûmes vu%%",
    "vous %%eûtes vu%%",
    "ils/elles %%eurent vu%%",
  ],
  futurSimple: [
    "je %%verrai%%",
    "tu %%verras%%",
    "il/elle %%verra%%",
    "nous %%verrons%%",
    "vous %%verrez%%",
    "ils/elles %%verront%%",
  ],
  futurAnterieur: [
    "j'%%aurai vu%%",
    "tu %%auras vu%%",
    "il/elle %%aura vu%%",
    "nous %%aurons vu%%",
    "vous %%aurez vu%%",
    "ils/elles %%auront vu%%",
  ],
  subjonctif_present: [
    "que je %%voie%%",
    "que tu %%voies%%",
    "qu'il/elle %%voie%%",
    "que nous %%voyions%%",
    "que vous %%voyiez%%",
    "qu'ils/elles %%voient%%",
  ],
  subjonctif_passe: [
    "que j'%%aie vu%%",
    "que tu %%aies vu%%",
    "qu'il/elle %%ait vu%%",
    "que nous %%ayons vu%%",
    "que vous %%ayez vu%%",
    "qu'ils/elles %%aient vu%%",
  ],
  subjonctif_imparfait: [
    "que je %%visse%%",
    "que tu %%visses%%",
    "qu'il/elle %%vît%%",
    "que nous %%vissions%%",
    "que vous %%vissiez%%",
    "qu'ils/elles %%vissent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse vu%%",
    "que tu %%eusses vu%%",
    "qu'il/elle %%eût vu%%",
    "que nous %%eussions vu%%",
    "que vous %%eussiez vu%%",
    "qu'ils/elles %%eussent vu%%",
  ],
  conditionnel_present: [
    "je %%verrais%%",
    "tu %%verrais%%",
    "il/elle %%verrait%%",
    "nous %%verrions%%",
    "vous %%verriez%%",
    "ils/elles %%verraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais vu%%",
    "tu %%aurais vu%%",
    "il/elle %%aurait vu%%",
    "nous %%aurions vu%%",
    "vous %%auriez vu%%",
    "ils/elles %%auraient vu%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais vu%%",
    "tu %%aurais vu%%",
    "il/elle %%aurait vu%%",
    "nous %%aurions vu%%",
    "vous %%auriez vu%%",
    "ils/elles %%auraient vu%%",
  ],
};

export default voirConjugations;
