let rendreConjugations = {
  present: [
    "je %%rends%%",
    "tu %%rends%%",
    "il/elle %%rend%%",
    "nous %%rendons%%",
    "vous %%rendez%%",
    "ils/elles %%rendent%%",
  ],
  passeCompose: [
    "j'%%ai rendu%%",
    "tu %%as rendu%%",
    "il/elle %%a rendu%%",
    "nous %%avons rendu%%",
    "vous %%avez rendu%%",
    "ils/elles %%ont rendu%%",
  ],
  imparfait: [
    "je %%rendais%%",
    "tu %%rendais%%",
    "il/elle %%rendait%%",
    "nous %%rendions%%",
    "vous %%rendiez%%",
    "ils/elles %%rendaient%%",
  ],
  plusQueParfait: [
    "j'%%avais rendu%%",
    "tu %%avais rendu%%",
    "il/elle %%avait rendu%%",
    "nous %%avions rendu%%",
    "vous %%aviez rendu%%",
    "ils/elles %%avaient rendu%%",
  ],
  passeSimple: [
    "je %%rendis%%",
    "tu %%rendis%%",
    "il/elle %%rendit%%",
    "nous %%rendîmes%%",
    "vous %%rendîtes%%",
    "ils/elles %%rendirent%%",
  ],
  passeAnterieur: [
    "j'%%eus rendu%%",
    "tu %%eus rendu%%",
    "il/elle %%eut rendu%%",
    "nous %%eûmes rendu%%",
    "vous %%eûtes rendu%%",
    "ils/elles %%eurent rendu%%",
  ],
  futurSimple: [
    "je %%rendrai%%",
    "tu %%rendras%%",
    "il/elle %%rendra%%",
    "nous %%rendrons%%",
    "vous %%rendrez%%",
    "ils/elles %%rendront%%",
  ],
  futurAnterieur: [
    "j'%%aurai rendu%%",
    "tu %%auras rendu%%",
    "il/elle %%aura rendu%%",
    "nous %%aurons rendu%%",
    "vous %%aurez rendu%%",
    "ils/elles %%auront rendu%%",
  ],
  subjonctif_present: [
    "que je %%rende%%",
    "que tu %%rendes%%",
    "qu'il/elle %%rende%%",
    "que nous %%rendions%%",
    "que vous %%rendiez%%",
    "qu'ils/elles %%rendent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie rendu%%",
    "que tu %%aies rendu%%",
    "qu'il/elle %%ait rendu%%",
    "que nous %%ayons rendu%%",
    "que vous %%ayez rendu%%",
    "qu'ils/elles %%aient rendu%%",
  ],
  subjonctif_imparfait: [
    "que je %%rendisse%%",
    "que tu %%rendisses%%",
    "qu'il/elle %%rendît%%",
    "que nous %%rendissions%%",
    "que vous %%rendissiez%%",
    "qu'ils/elles %%rendissent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse rendu%%",
    "que tu %%eusses rendu%%",
    "qu'il/elle %%eût rendu%%",
    "que nous %%eussions rendu%%",
    "que vous %%eussiez rendu%%",
    "qu'ils/elles %%eussent rendu%%",
  ],
  conditionnel_present: [
    "je %%rendrais%%",
    "tu %%rendrais%%",
    "il/elle %%rendrait%%",
    "nous %%rendrions%%",
    "vous %%rendriez%%",
    "ils/elles %%rendraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais rendu%%",
    "tu %%aurais rendu%%",
    "il/elle %%aurait rendu%%",
    "nous %%aurions rendu%%",
    "vous %%auriez rendu%%",
    "ils/elles %%auraient rendu%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais rendu%%",
    "tu %%aurais rendu%%",
    "il/elle %%aurait rendu%%",
    "nous %%aurions rendu%%",
    "vous %%auriez rendu%%",
    "ils/elles %%auraient rendu%%",
  ],
};

export default rendreConjugations;
