const commencer: any = {
  commencer: {
    present: [
      "Je %%commence%% à apprendre le piano cette semaine.",
      "Tu %%commences%% toujours tes journées avec un bon café.",
      "Il %%commence%% son nouveau travail demain.",
      "Elle %%commence%% à lire un nouveau livre ce soir.",
      "Nous %%commençons%% à regarder la nouvelle saison de notre série favorite.",
      "Vous %%commencez%% à comprendre le français, n'est-ce pas?",
      "Ils %%commencent%% leur voyage en Asie en août.",
      "Les enfants %%commencent%% l'école la semaine prochaine.",
      "Mon frère et moi %%commençons%% un régime alimentaire sain.",
      "Le professeur %%commence%% toujours le cours par une question.",
      "Les étudiants %%commencent%% à réviser pour les examens.",
      "Le soleil %%commence%% à se coucher plus tôt en automne.",
      "Mon ami %%commence%% à s'intéresser à la photographie.",
      "Les touristes %%commencent%% leur visite par le musée d'histoire.",
      "Le maire %%commence%% son mandat en janvier.",
      "Les oiseaux %%commencent%% à chanter à l'aube.",
      "La lune %%commence%% à briller dans le ciel.",
      "Les athlètes %%commencent%% leur entraînement très tôt le matin.",
      "Ma sœur %%commence%% un stage en entreprise lundi.",
      "Le boulanger %%commence%% à pétrir la pâte à minuit.",
      "Les jardiniers %%commencent%% à planter des fleurs au printemps.",
      "Le bébé %%commence%% à marcher tout seul.",
      "Les pompiers %%commencent%% à éteindre le feu.",
      "Les végétaliens %%commencent%% à trouver plus d'options au supermarché.",
      "Le peintre %%commence%% une nouvelle œuvre d'art.",
      "Les voisins %%commencent%% à sortir leur poubelle le dimanche soir.",
      "Le fermier %%commence%% à traire les vaches à l'aube.",
      "Les collègues %%commencent%% à arriver au bureau.",
      "Le musicien %%commence%% son concert à 20 heures.",
      "La cérémonie %%commence%% dans quelques minutes.",
    ],
    imparfait: [
      "Je %%commençais%% toujours la journée par une séance de yoga.",
      "Tu %%commençais%% à peine à comprendre la leçon quand la sonnerie a retenti.",
      "Il %%commençait%% à s'obséder par les étoiles et les planètes.",
      "Elle %%commençait%% à prendre goût à la cuisine végétalienne.",
      "Nous %%commencions%% chaque réunion par un point sur l'agenda.",
      "Vous %%commenciez%% toujours à jardiner dès le début du printemps.",
      "Ils %%commençaient%% à montrer des signes de fatigue après la longue marche.",
      "Les danseurs %%commençaient%% à se réchauffer avant le spectacle.",
      "Mon père et moi %%commencions%% à pêcher au lever du soleil.",
      "La pluie %%commençait%% à tomber doucement sur les toits.",
      "Les artistes %%commençaient%% à arriver pour le festival.",
      "Le brouillard %%commençait%% à se dissiper au petit matin.",
      "Mon ami %%commençait%% à s'intéresser à la philosophie.",
      "Les oiseaux %%commençaient%% à migrer vers le sud.",
      "Le capitaine %%commençait%% les instructions de sécurité avant le départ.",
      "Les spectateurs %%commençaient%% à s'installer dans le stade.",
      "Le feu %%commençait%% à prendre dans la cheminée.",
      "La fête %%commençait%% généralement en fin d'après-midi.",
      "Les enfants %%commençaient%% à s'exciter à l'approche de Noël.",
      "Ma grand-mère %%commençait%% toujours ses histoires par 'Il était une fois'.",
      "Les musiciens %%commençaient%% à accorder leurs instruments.",
      "Le jardin %%commençait%% à fleurir au mois de mai.",
      "Les collègues %%commençaient%% à se détendre après une semaine chargée.",
      "Le soleil %%commençait%% à dorer les cimes des arbres.",
      "Le cours %%commençait%% toujours par un quiz rapide.",
      "Les invités %%commençaient%% à arriver pour le dîner.",
      "Le chat %%commençait%% à ronronner en me voyant.",
      "Le film %%commençait%% à devenir intéressant.",
      "Les voisins %%commençaient%% à faire du bruit, comme d'habitude.",
      "Le boulanger %%commençait%% à vendre du pain dès 6 heures du matin.",
    ],
    futurSimple: [
      "Je %%commencerai%% mon cours de natation la semaine prochaine.",
      "Tu %%commenceras%% ta nouvelle fonction dès lundi.",
      "Il %%commencera%% à travailler sur son projet demain.",
      "Elle %%commencera%% son régime dès que les fêtes seront terminées.",
      "Nous %%commencerons%% à planter le jardin au printemps.",
      "Vous %%commencerez%% à voir des résultats après quelques semaines d'exercice.",
      "Ils %%commenceront%% leur tournée en France le mois prochain.",
      "Les cours %%commenceront%% à 9 heures, soyez à l'heure.",
      "Mes parents %%commenceront%% à prendre leur retraite l'année prochaine.",
      "Le musée %%commencera%% à exposer la nouvelle collection en juin.",
      "Le soleil %%commencera%% à se lever vers 6 heures du matin.",
      "Les oiseaux %%commenceront%% à chanter dès l'aube.",
      "Le gouvernement %%commencera%% les travaux de rénovation en automne.",
      "Le film %%commencera%% dans 10 minutes, dépêchez-vous !",
      "Le train %%commencera%% à ralentir avant la gare.",
      "Le chef %%commencera%% à cuisiner pour le banquet demain.",
      "La lune %%commencera%% à s'éclipser vers minuit.",
      "Les employés %%commenceront%% à recevoir leurs bonus en janvier.",
      "Le vent %%commencera%% à souffler fort cet après-midi.",
      "La conférence %%commencera%% à 11 heures précises.",
      "La boutique %%commencera%% ses soldes la semaine prochaine.",
      "Le marathon %%commencera%% dès 8 heures du matin.",
      "Le chanteur %%commencera%% sa tournée en Amérique latine.",
      "Les étudiants %%commenceront%% leurs stages en entreprise en février.",
      "Le comité %%commencera%% à évaluer les propositions demain.",
      "La neige %%commencera%% à tomber en fin de journée.",
      "La pièce %%commencera%% par une introduction du directeur.",
      "Les enfants %%commenceront%% leurs vacances en juillet.",
      "L'éclipse %%commencera%% à 14 heures et durera deux minutes.",
      "Les festivités %%commenceront%% au coucher du soleil.",
    ],
  },
};

export default commencer;
