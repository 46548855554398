import aimer from "./aimer";
import finir from "./finir";
import avoir from "./avoir";
import etre from "./etre";
import aller from "./aller";
import manger from "./manger";
import commencer from "./commencer";
import venir from "./venir";
import tenir from "./tenir";
import partir from "./partir";
import ouvrir from "./ouvrir";
import devoir from "./devoir";
import pouvoir from "./pouvoir";
import vouloir from "./vouloir";
import savoir from "./savoir";
import voir from "./voir";
import prendre from "./prendre";
import rendre from "./rendre";
import dire from "./dire";
import faire from "./faire";
import mettre from "./mettre";

type VerbPhrases = {
  [key: string]: string[];
};

type VerbTenses = {
  [key: string]: VerbPhrases;
};

const allVerbs: VerbTenses = {
  ...etre,
  ...avoir,
  ...aimer,
  ...aller,
  ...manger,
  ...commencer,
  ...finir,
  ...tenir,
  ...venir,
  ...partir,
  ...ouvrir,
  ...devoir,
  ...pouvoir,
  ...vouloir,
  ...savoir,
  ...voir,
  ...prendre,
  ...rendre,
  ...dire,
  ...faire,
  ...mettre,
};

export default allVerbs;

// bloc B: finir, tenir, venir, partir, ouvrir
// bloc C: devoir, pouvoir, vouloir, savoir, voir
// bloc D: prendre, rendre, mettre, dire, faire
