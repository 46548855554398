import etre from "./etre";
import aimer from "./aimer";
import avoir from "./avoir";
import aller from "./aller";
import finir from "./finir";
import manger from "./manger";
import commencer from "./commencer";
import ouvrir from "./ouvrir";
import partir from "./partir";
import tenir from "./tenir";
import venir from "./venir";
import devoir from "./devoir";
import pouvoir from "./pouvoir";
import vouloir from "./vouloir";
import savoir from "./savoir";
import voir from "./voir";
import prendre from "./prendre";
import rendre from "./rendre";
import dire from "./dire";
import faire from "./faire";
import mettre from "./mettre";

type ConjugationTableVerb = { [k in string]: string[] };

type ConjugationTable = { [k in string]: ConjugationTableVerb };

const conjugation: ConjugationTable = {
  etre,
  aimer,
  avoir,
  aller,
  manger,
  commencer,
  finir,
  tenir,
  venir,
  partir,
  ouvrir,
  devoir,
  pouvoir,
  vouloir,
  savoir,
  voir,
  prendre,
  rendre,
  dire,
  faire,
  mettre,
};

export default conjugation;
