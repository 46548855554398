let partirConjugations = {
  present: [
    "je %%pars%%",
    "tu %%pars%%",
    "il/elle %%part%%",
    "nous %%partons%%",
    "vous %%partez%%",
    "ils/elles %%partent%%",
  ],
  passeCompose: [
    "je %%suis parti%%",
    "tu %%es parti%%",
    "il/elle %%est parti%%",
    "nous %%sommes partis%%",
    "vous %%êtes partis%%",
    "ils/elles %%sont partis%%",
  ],
  imparfait: [
    "je %%partais%%",
    "tu %%partais%%",
    "il/elle %%partait%%",
    "nous %%partions%%",
    "vous %%partiez%%",
    "ils/elles %%partaient%%",
  ],
  plusQueParfait: [
    "j'%%étais parti%%",
    "tu %%étais parti%%",
    "il/elle %%était parti%%",
    "nous %%étions partis%%",
    "vous %%étiez partis%%",
    "ils/elles %%étaient partis%%",
  ],
  passeSimple: [
    "je %%partis%%",
    "tu %%partis%%",
    "il/elle %%partit%%",
    "nous %%partîmes%%",
    "vous %%partîtes%%",
    "ils/elles %%partirent%%",
  ],
  passeAnterieur: [
    "je %%fus parti%%",
    "tu %%fus parti%%",
    "il/elle %%fut parti%%",
    "nous %%fûmes partis%%",
    "vous %%fûtes partis%%",
    "ils/elles %%furent partis%%",
  ],
  futurSimple: [
    "je %%partirai%%",
    "tu %%partiras%%",
    "il/elle %%partira%%",
    "nous %%partirons%%",
    "vous %%partirez%%",
    "ils/elles %%partiront%%",
  ],
  futurAnterieur: [
    "je %%serai parti%%",
    "tu %%seras parti%%",
    "il/elle %%sera parti%%",
    "nous %%serons partis%%",
    "vous %%serez partis%%",
    "ils/elles %%seront partis%%",
  ],
  subjonctif_present: [
    "que je %%parte%%",
    "que tu %%partes%%",
    "qu'il/elle %%parte%%",
    "que nous %%partions%%",
    "que vous %%partiez%%",
    "qu'ils/elles %%partent%%",
  ],
  subjonctif_passe: [
    "que je %%sois parti%%",
    "que tu %%sois parti%%",
    "qu'il/elle %%soit parti%%",
    "que nous %%soyons partis%%",
    "que vous %%soyez partis%%",
    "qu'ils/elles %%soient partis%%",
  ],
  subjonctif_imparfait: [
    "que je %%partisse%%",
    "que tu %%partisses%%",
    "qu'il/elle %%partît%%",
    "que nous %%partissions%%",
    "que vous %%partissiez%%",
    "qu'ils/elles %%partissent%%",
  ],
  subjonctif_plusQueParfait: [
    "que je %%fusse parti%%",
    "que tu %%fusses parti%%",
    "qu'il/elle %%fût parti%%",
    "que nous %%fussions partis%%",
    "que vous %%fussiez partis%%",
    "qu'ils/elles %%fussent partis%%",
  ],
  conditionnel_present: [
    "je %%partirais%%",
    "tu %%partirais%%",
    "il/elle %%partirait%%",
    "nous %%partirions%%",
    "vous %%partiriez%%",
    "ils/elles %%partiraient%%",
  ],
  conditionnel_passePremiereForme: [
    "je %%serais parti%%",
    "tu %%serais parti%%",
    "il/elle %%serait parti%%",
    "nous %%serions partis%%",
    "vous %%seriez partis%%",
    "ils/elles %%seraient partis%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "je %%serais parti%%",
    "tu %%serais parti%%",
    "il/elle %%serait parti%%",
    "nous %%serions partis%%",
    "vous %%seriez partis%%",
    "ils/elles %%seraient partis%%",
  ],
};

export default partirConjugations;
