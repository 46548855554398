import verbs from "./conjugation/verbs";

export type Counters = {
  [key: string]: number;
};

export function phraseCountByVerb(verb: string): Counters {
  if (verbs.hasOwnProperty(verb)) {
    const tenses = verbs[verb];
    const result: Counters = {};

    for (const tense in tenses) {
      if (tenses.hasOwnProperty(tense)) {
        result[tense] = tenses[tense].length;
      }
    }

    return result;
  }

  return {};
}

export function phraseCountByTense(tense: string): Counters {
  const result: Counters = {};
  let hasTense = false;

  for (const verb in verbs) {
    if (verbs.hasOwnProperty(verb) && verbs[verb].hasOwnProperty(tense)) {
      result[verb] = verbs[verb][tense].length;
      hasTense = true;
    }
  }

  return hasTense ? result : {};
}

export function getRandomElements(array: string[], count: number): string[] {
  const shuffledArray = array.slice(); // Create a copy of the original array
  let currentIndex = shuffledArray.length;

  // While there are elements to shuffle
  while (currentIndex !== 0) {
    // Pick a remaining element
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // Swap the current element with the random element
    [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [
      shuffledArray[randomIndex],
      shuffledArray[currentIndex],
    ];
  }

  return shuffledArray.slice(0, count);
}
