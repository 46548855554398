let tenirConjugations = {
  present: [
    "je %%tiens%%",
    "tu %%tiens%%",
    "il/elle %%tient%%",
    "nous %%tenons%%",
    "vous %%tenez%%",
    "ils/elles %%tiennent%%",
  ],
  passeCompose: [
    "j'%%ai tenu%%",
    "tu %%as tenu%%",
    "il/elle %%a tenu%%",
    "nous %%avons tenu%%",
    "vous %%avez tenu%%",
    "ils/elles %%ont tenu%%",
  ],
  imparfait: [
    "je %%tenais%%",
    "tu %%tenais%%",
    "il/elle %%tenait%%",
    "nous %%tenions%%",
    "vous %%teniez%%",
    "ils/elles %%tenaient%%",
  ],
  plusQueParfait: [
    "j'%%avais tenu%%",
    "tu %%avais tenu%%",
    "il/elle %%avait tenu%%",
    "nous %%avions tenu%%",
    "vous %%aviez tenu%%",
    "ils/elles %%avaient tenu%%",
  ],
  passeSimple: [
    "je %%tins%%",
    "tu %%tins%%",
    "il/elle %%tint%%",
    "nous %%tînmes%%",
    "vous %%tîntes%%",
    "ils/elles %%tinrent%%",
  ],
  passeAnterieur: [
    "j'%%eus tenu%%",
    "tu %%eus tenu%%",
    "il/elle %%eut tenu%%",
    "nous %%eûmes tenu%%",
    "vous %%eûtes tenu%%",
    "ils/elles %%eurent tenu%%",
  ],
  futurSimple: [
    "je %%tiendrai%%",
    "tu %%tiendras%%",
    "il/elle %%tiendra%%",
    "nous %%tiendrons%%",
    "vous %%tiendrez%%",
    "ils/elles %%tiendront%%",
  ],
  futurAnterieur: [
    "j'%%aurai tenu%%",
    "tu %%auras tenu%%",
    "il/elle %%aura tenu%%",
    "nous %%aurons tenu%%",
    "vous %%aurez tenu%%",
    "ils/elles %%auront tenu%%",
  ],
  subjonctif_present: [
    "que je %%tienne%%",
    "que tu %%tiennes%%",
    "qu'il/elle %%tienne%%",
    "que nous %%tenions%%",
    "que vous %%teniez%%",
    "qu'ils/elles %%tiennent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie tenu%%",
    "que tu %%aies tenu%%",
    "qu'il/elle %%ait tenu%%",
    "que nous %%ayons tenu%%",
    "que vous %%ayez tenu%%",
    "qu'ils/elles %%aient tenu%%",
  ],
  subjonctif_imparfait: [
    "que je %%tinsse%%",
    "que tu %%tinsses%%",
    "qu'il/elle %%tînt%%",
    "que nous %%tinssions%%",
    "que vous %%tinssiez%%",
    "qu'ils/elles %%tinssent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse tenu%%",
    "que tu %%eusses tenu%%",
    "qu'il/elle %%eût tenu%%",
    "que nous %%eussions tenu%%",
    "que vous %%eussiez tenu%%",
    "qu'ils/elles %%eussent tenu%%",
  ],
  conditionnel_present: [
    "je %%tiendrais%%",
    "tu %%tiendrais%%",
    "il/elle %%tiendrait%%",
    "nous %%tiendrions%%",
    "vous %%tiendriez%%",
    "ils/elles %%tiendraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais tenu%%",
    "tu %%aurais tenu%%",
    "il/elle %%aurait tenu%%",
    "nous %%aurions tenu%%",
    "vous %%auriez tenu%%",
    "ils/elles %%auraient tenu%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais tenu%%",
    "tu %%aurais tenu%%",
    "il/elle %%aurait tenu%%",
    "nous %%aurions tenu%%",
    "vous %%auriez tenu%%",
    "ils/elles %%auraient tenu%%",
  ],
};

export default tenirConjugations;
