let vouloirConjugations = {
  present: [
    "je %%veux%%",
    "tu %%veux%%",
    "il/elle %%veut%%",
    "nous %%voulons%%",
    "vous %%voulez%%",
    "ils/elles %%veulent%%",
  ],
  passeCompose: [
    "j'%%ai voulu%%",
    "tu %%as voulu%%",
    "il/elle %%a voulu%%",
    "nous %%avons voulu%%",
    "vous %%avez voulu%%",
    "ils/elles %%ont voulu%%",
  ],
  imparfait: [
    "je %%voulais%%",
    "tu %%voulais%%",
    "il/elle %%voulait%%",
    "nous %%voulions%%",
    "vous %%vouliez%%",
    "ils/elles %%voulaient%%",
  ],
  plusQueParfait: [
    "j'%%avais voulu%%",
    "tu %%avais voulu%%",
    "il/elle %%avait voulu%%",
    "nous %%avions voulu%%",
    "vous %%aviez voulu%%",
    "ils/elles %%avaient voulu%%",
  ],
  passeSimple: [
    "je %%voulus%%",
    "tu %%voulus%%",
    "il/elle %%voulut%%",
    "nous %%voulûmes%%",
    "vous %%voulûtes%%",
    "ils/elles %%voulurent%%",
  ],
  passeAnterieur: [
    "j'%%eus voulu%%",
    "tu %%eus voulu%%",
    "il/elle %%eut voulu%%",
    "nous %%eûmes voulu%%",
    "vous %%eûtes voulu%%",
    "ils/elles %%eurent voulu%%",
  ],
  futurSimple: [
    "je %%voudrai%%",
    "tu %%voudras%%",
    "il/elle %%voudra%%",
    "nous %%voudrons%%",
    "vous %%voudrez%%",
    "ils/elles %%voudront%%",
  ],
  futurAnterieur: [
    "j'%%aurai voulu%%",
    "tu %%auras voulu%%",
    "il/elle %%aura voulu%%",
    "nous %%aurons voulu%%",
    "vous %%aurez voulu%%",
    "ils/elles %%auront voulu%%",
  ],
  subjonctif_present: [
    "que je %%veuille%%",
    "que tu %%veuilles%%",
    "qu'il/elle %%veuille%%",
    "que nous %%voulions%%",
    "que vous %%vouliez%%",
    "qu'ils/elles %%veuillent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie voulu%%",
    "que tu %%aies voulu%%",
    "qu'il/elle %%ait voulu%%",
    "que nous %%ayons voulu%%",
    "que vous %%ayez voulu%%",
    "qu'ils/elles %%aient voulu%%",
  ],
  subjonctif_imparfait: [
    "que je %%voulusse%%",
    "que tu %%voulusses%%",
    "qu'il/elle %%voulût%%",
    "que nous %%voulussions%%",
    "que vous %%voulussiez%%",
    "qu'ils/elles %%voulussent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse voulu%%",
    "que tu %%eusses voulu%%",
    "qu'il/elle %%eût voulu%%",
    "que nous %%eussions voulu%%",
    "que vous %%eussiez voulu%%",
    "qu'ils/elles %%eussent voulu%%",
  ],
  conditionnel_present: [
    "je %%voudrais%%",
    "tu %%voudrais%%",
    "il/elle %%voudrait%%",
    "nous %%voudrions%%",
    "vous %%voudriez%%",
    "ils/elles %%voudraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais voulu%%",
    "tu %%aurais voulu%%",
    "il/elle %%aurait voulu%%",
    "nous %%aurions voulu%%",
    "vous %%auriez voulu%%",
    "ils/elles %%auraient voulu%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais voulu%%",
    "tu %%aurais voulu%%",
    "il/elle %%aurait voulu%%",
    "nous %%aurions voulu%%",
    "vous %%auriez voulu%%",
    "ils/elles %%auraient voulu%%",
  ],
};

export default vouloirConjugations;
