export const tenses = [
  { id: "present", title: "présent", hide: false },
  { id: "imparfait", title: "imparfait", hide: false },
  { id: "passeSimple", title: "passé simple", hide: true },
  { id: "passeCompose", title: "passé composé", hide: true },
  { id: "plusQueParfait", title: "plus-que-parfait", hide: true },
  { id: "passeAnterieur", title: "passé antérieur", hide: true },
  { id: "futurSimple", title: "futur simple", hide: false },
  { id: "futurAnterieur", title: "futur antérieur", hide: true },
  { id: "subjonctif_present", title: "subjonctif présent", hide: true },
  { id: "subjonctif_passe", title: "subjonctif passé", hide: true },
  { id: "subjonctif_imparfait", title: "subjonctif imparfait", hide: true },
  {
    id: "subjonctif_plusQueParfait",
    title: "subjonctif plus-que-parfait",
    hide: true,
  },
  { id: "conditionnel_present", title: "conditionnel présent", hide: true },
  {
    id: "conditionnel_passePremiereForme",
    title: "conditionnel passé première forme",
    hide: true,
  },
  {
    id: "conditionnel_passeDeuxiemeForme",
    title: "conditionnel passé deuxième forme",
    hide: true,
  },
];

export const verbs = [
  { id: "prendre", title: "Prendre" },
  { id: "rendre", title: "Rendre" },
  { id: "dire", title: "Dire" },
  { id: "faire", title: "Faire" },
  { id: "mettre", title: "Mettre" },
  { id: "devoir", title: "Devoir" },
  { id: "pouvoir", title: "Pouvoir" },
  { id: "vouloir", title: "Vouloir" },
  { id: "savoir", title: "Savoir" },
  { id: "voir", title: "Voir" },
  { id: "etre", title: "Être" },
  { id: "avoir", title: "Avoir" },
  { id: "aimer", title: "Aimer" },
  { id: "aller", title: "Aller" },
  { id: "manger", title: "Manger" },
  { id: "commencer", title: "Commencer" },
  { id: "finir", title: "Finir" },
  { id: "tenir", title: "Tenir" },
  { id: "venir", title: "Venir" },
  { id: "partir", title: "Partir" },
  { id: "ouvrir", title: "Ouvrir" },
];
