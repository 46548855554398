const finir = {
  finir: {
    present: [
      "Je %%finis%% mon travail.",
      "Tu %%finis%% ton déjeuner.",
      "Il %%finit%% sa lecture.",
      "Nous %%finissons%% la réunion.",
      "Vous %%finissez%% la soirée avec un film.",
      "Elles %%finissent%% leurs devoirs.",
      "On %%finit%% toujours par comprendre.",
      "Qui %%finit%% le plus vite ?",
      "Que %%finis%%-tu en premier ?",
      "Comment %%finissons%%-nous le projet ?",
      "Mon père %%finit%% le livre.",
      "Les employés %%finissent%% le projet.",
      "Mon ami %%finit%% sa tâche.",
      "Les joueurs %%finissent%% la partie.",
      "Notre classe %%finit%% les devoirs.",
      "Le jardinier %%finit%% de tailler les arbustes.",
      "Le peintre %%finit%% le portrait.",
      "La serveuse %%finit%% de nettoyer les tables.",
      "L'équipe de construction %%finit%% le bâtiment.",
      "Le chef %%finit%% de préparer le repas.",
    ],
    imparfait: [
      "Je %%finissais%% ma lettre.",
      "Tu %%finissais%% ta collection de timbres.",
      "Il %%finissait%% son marathon.",
      "Nous %%finissions%% notre projet d'art.",
      "Vous %%finissiez%% votre excursion en montagne.",
      "Elles %%finissaient%% leur spectacle de danse.",
      "On %%finissait%% la journée avec une belle promenade.",
      "Mon oncle %%finissait%% son service dans l'armée.",
      "La plante %%finissait%% sa floraison.",
      "L'été %%finissait%% en beauté.",
    ],
    passeSimple: [
      "Je %%finis%% mon roman.",
      "Tu %%finis%% ton entraînement de basket.",
      "Il %%finit%% son dessin.",
      "Nous %%finîmes%% notre voyage en Asie.",
      "Vous %%finîtes%% votre dîner romantique.",
      "Elles %%finirent%% leur spectacle de gymnastique.",
      "On %%finit%% la construction du pont.",
      "Ma tante %%finit%% son tricot.",
      "Le musicien %%finit%% sa symphonie.",
      "Le printemps %%finit%% avec une douce pluie.",
    ],
    passeCompose: [
      "J'%%ai fini%% mon essai sur l'histoire médiévale.",
      "Tu %%as fini%% ton puzzle de 1000 pièces.",
      "Il %%a fini%% son discours.",
      "Nous %%avons fini%% notre entraînement de natation.",
      "Vous %%avez fini%% votre visite de la ville.",
      "Elles %%ont fini%% leur chorégraphie.",
      "On %%a fini%% la rénovation de la maison.",
      "Mon cousin %%a fini%% son stage de médecine.",
      "Le soleil %%a fini%% son coucher.",
      "L'hiver %%a fini%% avec une grande tempête.",
    ],
    futurSimple: [
      "Je %%finirai%% ce livre avant le week-end.",
      "Tu %%finiras%% tes devoirs avant de sortir, n'est-ce pas?",
      "Il %%finira%% son projet avant la fin du mois.",
      "Elle %%finira%% le marathon, je suis sûr.",
      "Nous %%finirons%% notre travail avant la date limite.",
      "Vous %%finirez%% votre voyage en Europe en Italie.",
      "Ils %%finiront%% de construire la maison avant l'hiver.",
      "Mon ami et moi %%finirons%% de peindre la chambre demain.",
      "Le chef %%finira%% de préparer le dîner dans une heure.",
      "La pluie %%finira%% bientôt, ne t'inquiète pas.",
      "Les musiciens %%finiront%% le concert avec leur chanson populaire.",
      "Mon chien %%finira%% son os plus tard.",
      "Les étudiants %%finiront%% l'examen avant midi.",
      "Les ouvriers %%finiront%% de réparer la route la semaine prochaine.",
      "Le soleil %%finira%% de se coucher dans une heure.",
      "Le jardinier %%finira%% de planter les fleurs avant le soir.",
      "Le réalisateur %%finira%% le tournage du film l'année prochaine.",
      "L'équipe de football %%finira%% l'entraînement à six heures.",
      "Le boulanger %%finira%% de cuire le pain à huit heures.",
      "Les enfants %%finiront%% leur goûter et iront jouer.",
      "Le serveur %%finira%% de nettoyer les tables avant de fermer le restaurant.",
      "Les oiseaux %%finiront%% de construire leur nid demain.",
      "Le peintre %%finira%% son tableau pour l'exposition.",
      "L'écrivain %%finira%% son roman d'ici la fin de l'année.",
      "Les danseurs %%finiront%% leur répétition avant le spectacle.",
      "Le conducteur %%finira%% son trajet à Paris.",
      "Le maçon %%finira%% le mur demain.",
      "Les explorateurs %%finiront%% leur expédition en montagne la semaine prochaine.",
      "Le sculpteur %%finira%% sa statue pour le concours.",
      "L'artiste %%finira%% sa performance avec une chanson surprise.",
    ],
  },
};

export default finir;
