let pouvoirConjugations = {
  present: [
    "je %%peux%%",
    "tu %%peux%%",
    "il/elle %%peut%%",
    "nous %%pouvons%%",
    "vous %%pouvez%%",
    "ils/elles %%peuvent%%",
  ],
  passeCompose: [
    "j'%%ai pu%%",
    "tu %%as pu%%",
    "il/elle %%a pu%%",
    "nous %%avons pu%%",
    "vous %%avez pu%%",
    "ils/elles %%ont pu%%",
  ],
  imparfait: [
    "je %%pouvais%%",
    "tu %%pouvais%%",
    "il/elle %%pouvait%%",
    "nous %%pouvions%%",
    "vous %%pouviez%%",
    "ils/elles %%pouvaient%%",
  ],
  plusQueParfait: [
    "j'%%avais pu%%",
    "tu %%avais pu%%",
    "il/elle %%avait pu%%",
    "nous %%avions pu%%",
    "vous %%aviez pu%%",
    "ils/elles %%avaient pu%%",
  ],
  passeSimple: [
    "je %%pus%%",
    "tu %%pus%%",
    "il/elle %%put%%",
    "nous %%pûmes%%",
    "vous %%pûtes%%",
    "ils/elles %%purent%%",
  ],
  passeAnterieur: [
    "j'%%eus pu%%",
    "tu %%eus pu%%",
    "il/elle %%eut pu%%",
    "nous %%eûmes pu%%",
    "vous %%eûtes pu%%",
    "ils/elles %%eurent pu%%",
  ],
  futurSimple: [
    "je %%pourrai%%",
    "tu %%pourras%%",
    "il/elle %%pourra%%",
    "nous %%pourrons%%",
    "vous %%pourrez%%",
    "ils/elles %%pourront%%",
  ],
  futurAnterieur: [
    "j'%%aurai pu%%",
    "tu %%auras pu%%",
    "il/elle %%aura pu%%",
    "nous %%aurons pu%%",
    "vous %%aurez pu%%",
    "ils/elles %%auront pu%%",
  ],
  subjonctif_present: [
    "que je %%puisse%%",
    "que tu %%puisses%%",
    "qu'il/elle %%puisse%%",
    "que nous %%puissions%%",
    "que vous %%puissiez%%",
    "qu'ils/elles %%puissent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie pu%%",
    "que tu %%aies pu%%",
    "qu'il/elle %%ait pu%%",
    "que nous %%ayons pu%%",
    "que vous %%ayez pu%%",
    "qu'ils/elles %%aient pu%%",
  ],
  subjonctif_imparfait: [
    "que je %%pusse%%",
    "que tu %%pusses%%",
    "qu'il/elle %%pût%%",
    "que nous %%pussions%%",
    "que vous %%pussiez%%",
    "qu'ils/elles %%pussent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse pu%%",
    "que tu %%eusses pu%%",
    "qu'il/elle %%eût pu%%",
    "que nous %%eussions pu%%",
    "que vous %%eussiez pu%%",
    "qu'ils/elles %%eussent pu%%",
  ],
  conditionnel_present: [
    "je %%pourrais%%",
    "tu %%pourrais%%",
    "il/elle %%pourrait%%",
    "nous %%pourrions%%",
    "vous %%pourriez%%",
    "ils/elles %%pourraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais pu%%",
    "tu %%aurais pu%%",
    "il/elle %%aurait pu%%",
    "nous %%aurions pu%%",
    "vous %%auriez pu%%",
    "ils/elles %%auraient pu%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais pu%%",
    "tu %%aurais pu%%",
    "il/elle %%aurait pu%%",
    "nous %%aurions pu%%",
    "vous %%auriez pu%%",
    "ils/elles %%auraient pu%%",
  ],
};

export default pouvoirConjugations;
