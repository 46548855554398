const dire: any = {
  dire: {
    present: [
      "Je %%dis%% la vérité.",
      "Tu %%dis%% n'importe quoi!",
      "Il %%dit%% son nom.",
      "Elle %%dit%% bonjour à ses collègues.",
      "Mon ami %%dit%% toujours des blagues.",
      "Nous %%disons%% nos prières.",
      "Vous %%dites%% souvent cela.",
      "Ils %%disent%% des choses différentes.",
      "Mes collègues %%disent%% des histoires intéressantes.",
      "Mes amis et moi %%disons%% des choses folles.",
      "Je %%dis%% que c'est une excellente idée.",
      "Tu %%dis%% toujours ce que tu penses, n'est-ce pas?",
      "Il %%dit%% qu'il veut changer de carrière.",
      "Elle %%dit%% qu'elle aime les films d'horreur.",
      "Mon professeur %%dit%% que la pratique rend parfait.",
      "Nous %%disons%% bonjour à nos voisins tous les matins.",
      "Vous %%dites%% souvent que le client est roi.",
      "Ils %%disent%% qu'ils vont déménager en Italie.",
      "Mes parents %%disent%% toujours de manger mes légumes.",
      "Luc et Marie %%disent%% qu'ils se marient l'année prochaine.",
      "La police %%dit%% de rester à l'intérieur.",
      "L'équipe %%dit%% que le projet est presque terminé.",
      "Mon frère %%dit%% qu'il veut devenir astronaute.",
      "Je %%dis%% souvent que le temps c'est de l'argent.",
      "Tu %%dis%% que tu as vu un ovni?",
      "Le directeur %%dit%% que les salaires vont augmenter.",
      "Ma soeur %%dit%% qu'elle n'aime pas les maths.",
      "Nous %%disons%% toujours que la santé est importante.",
      "Vous %%dites%% que l'économie va mal?",
      "Elles %%disent%% que la fête était incroyable.",
      "Le médecin %%dit%% de prendre ces médicaments.",
      "Mes amis %%disent%% que ce restaurant est génial.",
      "Le chien %%dit%% woof woof! (humor)",
      "Je %%dis%% que cette série est surcotée.",
      "Tu %%dis%% que tu veux apprendre le français?",
      "La météo %%dit%% qu'il va pleuvoir demain.",
      "Le critique %%dit%% que le film est un chef-d'œuvre.",
      "Nous %%disons%% que la vie est belle.",
      "Vous %%dites%% que ce vin est excellent.",
      "Ils %%disent%% que le changement climatique est urgent.",
    ],
    imparfait: [
      "Je %%disais%% souvent des histoires.",
      "Tu %%disais%% qu'il allait venir.",
      "Il %%disait%% qu'il était innocent.",
      "Ma mère %%disait%% des contes avant de dormir.",
      "Mon ami %%disait%% des choses étranges.",
      "Nous %%disions%% la même chose.",
      "Vous %%disiez%% des absurdités.",
      "Ils %%disaient%% la vérité.",
      "Les enfants %%disaient%% bonjour.",
      "Mon frère et moi %%disions%% des bêtises.",
      "Je %%disais%% que la situation était sous contrôle.",
      "Tu %%disais%% que tu voulais être végétarien.",
      "Il %%disait%% qu'il allait écrire un livre.",
      "Elle %%disait%% qu'elle ne croyait pas aux fantômes.",
      "Mon entraîneur %%disait%% que la douleur est temporaire.",
      "Nous %%disions%% que le monde était petit.",
      "Vous %%disiez%% que l'entreprise était en bonne santé.",
      "Ils %%disaient%% qu'ils allaient tout révolutionner.",
      "Mes grands-parents %%disaient%% que tout était mieux avant.",
      "Luc et Sarah %%disaient%% qu'ils n'avaient jamais mangé de sushis.",
      "L'artiste %%disait%% que l'inspiration venait la nuit.",
      "La chorale %%disait%% qu'ils avaient besoin de nouveaux membres.",
      "Mon oncle %%disait%% qu'il avait rencontré des célébrités.",
      "Je %%disais%% toujours que chaque jour est une nouvelle chance.",
      "Tu %%disais%% que tu pourrais résoudre ce casse-tête?",
      "La serveuse %%disait%% que le plat du jour était le poulet.",
      "Ma cousine %%disait%% qu'elle allait déménager à Paris.",
      "Nous %%disions%% souvent que l'amitié est précieuse.",
      "Vous %%disiez%% que vous n'aimiez pas voyager?",
      "Elles %%disaient%% qu'elles allaient ouvrir un café.",
      "Le fermier %%disait%% que la récolte était bonne cette année.",
      "Mes collègues %%disaient%% que le boss était en vacances.",
      "Le chat %%disait%% miaou quand il avait faim (humor).",
      "Je %%disais%% que je n'étais pas un grand fan de sport.",
      "Tu %%disais%% que tu avais peur des araignées?",
      "La radio %%disait%% que le trafic était horrible.",
      "L'acteur %%disait%% qu'il préparait un nouveau rôle.",
      "Nous %%disions%% que la famille est tout.",
      "Vous %%disiez%% que ce musée était ennuyeux.",
      "Ils %%disaient%% que le changement est inévitable.",
    ],
    futurSimple: [
      "Je %%dirai%% au revoir.",
      "Tu %%diras%% la vérité.",
      "Il %%dira%% qu'il a gagné.",
      "Elle %%dira%% quelque chose de choquant.",
      "Mon père %%dira%% ses pensées.",
      "Nous %%dirons%% nos adieux.",
      "Vous %%direz%% votre nom.",
      "Ils %%diront%% des choses surprenantes.",
      "Mes collègues %%diront%% des nouvelles idées.",
      "Mes amis et moi %%dirons%% des choses drôles.",
      "Je %%dirai%% que c'est un chef-d'œuvre.",
      "Tu %%diras%% ce que tu ressens lors de la réunion.",
      "Il %%dira%% s'il accepte l'offre d'emploi ou non.",
      "Elle %%dira%% que le soleil brille toujours après la pluie.",
      "Mon avocat %%dira%% si le cas est solide.",
      "Nous %%dirons%% à tous nos secrets à minuit.",
      "Vous %%direz%% que vous êtes satisfait du service.",
      "Ils %%diront%% s'ils viennent à la fête.",
      "Mes parents %%diront%% leur décision demain.",
      "Sophie et Paul %%diront%% s'ils restent ensemble.",
      "Le scientifique %%dira%% si la théorie est correcte.",
      "La société %%dira%% si elle lance un nouveau produit.",
      "Ma grand-mère %%dira%% toujours qu'elle nous aime.",
      "Je %%dirai%% si j'ai réussi l'examen.",
      "Tu %%diras%% quand tu es prêt pour partir.",
      "Le coach %%dira%% qui fait partie de l'équipe.",
      "Mon ami %%dira%% s'il déménage à l'étranger.",
      "Nous %%dirons%% si nous aimons le nouveau menu.",
      "Vous %%direz%% vos vœux à la cérémonie.",
      "Elles %%diront%% si elles continuent leurs études.",
      "Le pilote %%dira%% quand nous atterrirons.",
      "Mes élèves %%diront%% s'ils ont compris la leçon.",
      "Le clown %%dira%% une blague à la fin (humor).",
      "Je %%dirai%% si je pense que c'est une bonne idée.",
      "Tu %%diras%% tes pensées à haute voix.",
      "L'analyste %%dira%% si c'est un bon investissement.",
      "Le groupe %%dira%% s'il fait une tournée.",
      "Nous %%dirons%% si nous sommes pour ou contre.",
      "Vous %%direz%% si vous pouvez nous aider.",
      "Ils %%diront%% si le voyage était réussi.",
    ],
  },
};

export default dire;
