let avoirConjugations = {
  present: [
    "j'%%ai%%",
    "tu %%as%%",
    "il/elle %%a%%",
    "nous %%avons%%",
    "vous %%avez%%",
    "ils/elles %%ont%%",
  ],
  passeCompose: [
    "j'%%ai eu%%",
    "tu %%as eu%%",
    "il/elle %%a eu%%",
    "nous %%avons eu%%",
    "vous %%avez eu%%",
    "ils/elles %%ont eu%%",
  ],
  imparfait: [
    "j'%%avais%%",
    "tu %%avais%%",
    "il/elle %%avait%%",
    "nous %%avions%%",
    "vous %%aviez%%",
    "ils/elles %%avaient%%",
  ],
  plusQueParfait: [
    "j'%%avais eu%%",
    "tu %%avais eu%%",
    "il/elle %%avait eu%%",
    "nous %%avions eu%%",
    "vous %%aviez eu%%",
    "ils/elles %%avaient eu%%",
  ],
  passeSimple: [
    "j'%%eus%%",
    "tu %%eus%%",
    "il/elle %%eut%%",
    "nous %%eûmes%%",
    "vous %%eûtes%%",
    "ils/elles %%eurent%%",
  ],
  passeAnterieur: [
    "j'%%eus eu%%",
    "tu %%eus eu%%",
    "il/elle %%eut eu%%",
    "nous %%eûmes eu%%",
    "vous %%eûtes eu%%",
    "ils/elles %%eurent eu%%",
  ],
  futurSimple: [
    "j'%%aurai%%",
    "tu %%auras%%",
    "il/elle %%aura%%",
    "nous %%aurons%%",
    "vous %%aurez%%",
    "ils/elles %%auront%%",
  ],
  futurAnterieur: [
    "j'%%aurai eu%%",
    "tu %%auras eu%%",
    "il/elle %%aura eu%%",
    "nous %%aurons eu%%",
    "vous %%aurez eu%%",
    "ils/elles %%auront eu%%",
  ],
  subjonctif_present: [
    "que j'%%aie%%",
    "que tu %%aies%%",
    "qu'il/elle %%ait%%",
    "que nous %%ayons%%",
    "que vous %%ayez%%",
    "qu'ils/elles %%aient%%",
  ],
  subjonctif_passe: [
    "que j'%%aie eu%%",
    "que tu %%aies eu%%",
    "qu'il/elle %%ait eu%%",
    "que nous %%ayons eu%%",
    "que vous %%ayez eu%%",
    "qu'ils/elles %%aient eu%%",
  ],
  subjonctif_imparfait: [
    "que j'%%eusse%%",
    "que tu %%eusses%%",
    "qu'il/elle %%eût%%",
    "que nous %%eussions%%",
    "que vous %%eussiez%%",
    "qu'ils/elles %%eussent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse eu%%",
    "que tu %%eusses eu%%",
    "qu'il/elle %%eût eu%%",
    "que nous %%eussions eu%%",
    "que vous %%eussiez eu%%",
    "qu'ils/elles %%eussent eu%%",
  ],
  conditionnel_present: [
    "j'%%aurais%%",
    "tu %%aurais%%",
    "il/elle %%aurait%%",
    "nous %%aurions%%",
    "vous %%auriez%%",
    "ils/elles %%auraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais eu%%",
    "tu %%aurais eu%%",
    "il/elle %%aurait eu%%",
    "nous %%aurions eu%%",
    "vous %%auriez eu%%",
    "ils/elles %%auraient eu%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais eu%%",
    "tu %%aurais eu%%",
    "il/elle %%aurait eu%%",
    "nous %%aurions eu%%",
    "vous %%auriez eu%%",
    "ils/elles %%auraient eu%%",
  ],
};

export default avoirConjugations;
