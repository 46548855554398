let faireConjugations = {
  present: [
    "je %%fais%%",
    "tu %%fais%%",
    "il/elle %%fait%%",
    "nous %%faisons%%",
    "vous %%faites%%",
    "ils/elles %%font%%",
  ],
  passeCompose: [
    "j'%%ai fait%%",
    "tu %%as fait%%",
    "il/elle %%a fait%%",
    "nous %%avons fait%%",
    "vous %%avez fait%%",
    "ils/elles %%ont fait%%",
  ],
  imparfait: [
    "je %%faisais%%",
    "tu %%faisais%%",
    "il/elle %%faisait%%",
    "nous %%faisions%%",
    "vous %%faisiez%%",
    "ils/elles %%faisaient%%",
  ],
  plusQueParfait: [
    "j'%%avais fait%%",
    "tu %%avais fait%%",
    "il/elle %%avait fait%%",
    "nous %%avions fait%%",
    "vous %%aviez fait%%",
    "ils/elles %%avaient fait%%",
  ],
  passeSimple: [
    "je %%fis%%",
    "tu %%fis%%",
    "il/elle %%fit%%",
    "nous %%fîmes%%",
    "vous %%fîtes%%",
    "ils/elles %%firent%%",
  ],
  passeAnterieur: [
    "j'%%eus fait%%",
    "tu %%eus fait%%",
    "il/elle %%eut fait%%",
    "nous %%eûmes fait%%",
    "vous %%eûtes fait%%",
    "ils/elles %%eurent fait%%",
  ],
  futurSimple: [
    "je %%ferai%%",
    "tu %%feras%%",
    "il/elle %%fera%%",
    "nous %%ferons%%",
    "vous %%ferez%%",
    "ils/elles %%feront%%",
  ],
  futurAnterieur: [
    "j'%%aurai fait%%",
    "tu %%auras fait%%",
    "il/elle %%aura fait%%",
    "nous %%aurons fait%%",
    "vous %%aurez fait%%",
    "ils/elles %%auront fait%%",
  ],
  subjonctif_present: [
    "que je %%fasse%%",
    "que tu %%fasses%%",
    "qu'il/elle %%fasse%%",
    "que nous %%fassions%%",
    "que vous %%fassiez%%",
    "qu'ils/elles %%fassent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie fait%%",
    "que tu %%aies fait%%",
    "qu'il/elle %%ait fait%%",
    "que nous %%ayons fait%%",
    "que vous %%ayez fait%%",
    "qu'ils/elles %%aient fait%%",
  ],
  subjonctif_imparfait: [
    "que je %%fisse%%",
    "que tu %%fisses%%",
    "qu'il/elle %%fît%%",
    "que nous %%fissions%%",
    "que vous %%fissiez%%",
    "qu'ils/elles %%fissent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse fait%%",
    "que tu %%eusses fait%%",
    "qu'il/elle %%eût fait%%",
    "que nous %%eussions fait%%",
    "que vous %%eussiez fait%%",
    "qu'ils/elles %%eussent fait%%",
  ],
  conditionnel_present: [
    "je %%ferais%%",
    "tu %%ferais%%",
    "il/elle %%ferait%%",
    "nous %%ferions%%",
    "vous %%feriez%%",
    "ils/elles %%feraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais fait%%",
    "tu %%aurais fait%%",
    "il/elle %%aurait fait%%",
    "nous %%aurions fait%%",
    "vous %%auriez fait%%",
    "ils/elles %%auraient fait%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais fait%%",
    "tu %%aurais fait%%",
    "il/elle %%aurait fait%%",
    "nous %%aurions fait%%",
    "vous %%auriez fait%%",
    "ils/elles %%auraient fait%%",
  ],
};

export default faireConjugations;
