const venir: any = {
  venir: {
    present: [
      "Je %%viens%% de terminer mon travail.",
      "Tu %%viens%% souvent ici?",
      "Il %%vient%% de Rome pour étudier.",
      "Elle %%vient%% d'arriver à la fête.",
      "Nous %%venons%% tout juste de le découvrir.",
      "Vous %%venez%% de perdre votre chance.",
      "Ils %%viennent%% de différents pays.",
      "Mes amis %%viennent%% me rendre visite demain.",
      "Les oiseaux %%viennent%% du sud chaque printemps.",
      "Ma sœur et moi %%venons%% d'une petite ville.",
      "Les étudiants %%viennent%% pour le seminaire.",
      "Le professeur %%vient%% toujours en avance.",
      "Les nouvelles %%viennent%% de tomber.",
      "Les acteurs %%viennent%% pour le tournage.",
      "Les pompiers %%viennent%% en cas d'urgence.",
      "L'automne %%vient%% après l'été.",
      "Les nouvelles technologies %%viennent%% changer notre quotidien.",
      "Les émotions %%viennent%% et vont.",
      "Les bénévoles %%viennent%% aider au refuge pour animaux.",
      "Le bus %%vient%% dans cinq minutes.",
      "Le chef %%vient%% expliquer la recette.",
      "Les conseils %%viennent%% du cœur.",
      "Les poissons %%viennent%% à la surface pour manger.",
      "La pluie %%vient%% arroser les plantes.",
      "La directrice %%vient%% pour l'inspection.",
      "Le bruit %%vient%% de la rue.",
      "La lumière %%vient%% de l'est.",
      "Le chien %%vient%% quand il est appelé.",
      "Les idées %%viennent%% en discutant.",
      "La sagesse %%vient%% avec l'âge.",
    ],
    imparfait: [
      "Je %%venais%% de finir mon repas quand tu as appelé.",
      "Tu %%venais%% souvent dans ce parc étant enfant.",
      "Il %%venait%% de temps en temps nous voir.",
      "Elle %%venait%% tous les matins prendre son café ici.",
      "Nous %%venions%% d'une petite ville du sud.",
      "Vous %%veniez%% en vacances ici chaque été.",
      "Ils %%venaient%% souvent en retard.",
      "Mes parents %%venaient%% me chercher à l'école.",
      "Les idées %%venaient%% facilement à lui.",
      "Mon frère et moi %%venions%% d'apprendre la nouvelle.",
      "Les pompiers %%venaient%% immédiatement quand il y avait une alerte.",
      "Le professeur %%venait%% toujours avec un grand sourire.",
      "Les touristes %%venaient%% de partout pour voir le monument.",
      "Les enfants %%venaient%% jouer ici après l'école.",
      "La joie %%venait%% naturellement avec ces nouvelles.",
      "La pluie %%venait%% rafraîchir la terre en été.",
      "La professeure %%venait%% souvent avec des histoires intéressantes.",
      "Les nouvelles %%venaient%% généralement par la radio.",
      "La foule %%venait%% de plus en plus nombreuse.",
      "Le vent %%venait%% de la mer.",
      "Le chat %%venait%% ronronner près de moi.",
      "Les conseils %%venaient%% toujours de mes grands-parents.",
      "Les légumes %%venaient%% directement du jardin.",
      "La lumière %%venait%% doucement à travers les rideaux.",
      "L'inspiration %%venait%% en marchant dans la forêt.",
      "Les problèmes %%venaient%% toujours en groupe.",
      "Le calme %%venait%% après la tempête.",
      "Les réponses %%venaient%% en discutant avec elle.",
      "La paix %%venait%% avec la méditation.",
      "La motivation %%venait%% en accomplissant de petites tâches.",
    ],
    futurSimple: [
      "Je %%viendrai%% te chercher à 8 heures.",
      "Tu %%viendras%% avec nous au cinéma ce soir?",
      "Il %%viendra%% à la réunion demain.",
      "Elle %%viendra%% dîner chez nous la semaine prochaine.",
      "Nous %%viendrons%% te rendre visite pendant les vacances.",
      "Vous %%viendrez%% pour le mariage, n'est-ce pas?",
      "Ils %%viendront%% en grand nombre à la conférence.",
      "Mes amis %%viendront%% au match de football samedi.",
      "Les oiseaux migrateurs %%viendront%% au printemps.",
      "Mon frère et sa femme %%viendront%% pour Thanksgiving.",
      "Les spectateurs %%viendront%% de différentes villes.",
      "Le directeur %%viendra%% inaugurer le nouvel établissement.",
      "Les invités %%viendront%% à partir de 18 heures.",
      "Les enfants %%viendront%% ramasser les œufs de Pâques.",
      "La paix %%viendra%% lorsque le conflit sera résolu.",
      "Le changement %%viendra%% avec le temps.",
      "Les réponses %%viendront%% lors de la réunion.",
      "Le soleil %%viendra%% après la pluie.",
      "L'automne %%viendra%% avec ses couleurs chaudes.",
      "La marée %%viendra%% à son heure habituelle.",
      "La chance %%viendra%% si tu y crois.",
      "La livraison %%viendra%% demain matin.",
      "Le professeur %%viendra%% avec les examens corrigés.",
      "Les idées innovantes %%viendront%% des jeunes générations.",
      "La vérité %%viendra%% à la lumière.",
      "Les nouvelles technologies %%viendront%% révolutionner notre quotidien.",
      "Les vacances %%viendront%% après les examens.",
      "Le bonheur %%viendra%% avec la réussite.",
      "Le courrier %%viendra%% probablement demain.",
      "La neige %%viendra%% en décembre.",
    ],
  },
};

export default venir;
