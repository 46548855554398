const faire: any = {
  faire: {
    present: [
      "Je %%fais%% du yoga tous les matins.",
      "Tu %%fais%% souvent du shopping en ligne?",
      "Il %%fait%% ses devoirs juste avant le dîner.",
      "Elle %%fait%% des cupcakes pour l'anniversaire de son fils.",
      "Mon père %%fait%% toujours la vaisselle.",
      "Nous %%faisons%% une randonnée ce week-end.",
      "Vous %%faites%% vraiment du bon travail ici.",
      "Ils %%font%% une rénovation complète de leur maison.",
      "Mes amis %%font%% un barbecue ce soir.",
      "Sarah et toi %%faites%% du bénévolat à la bibliothèque.",
      "Le chef %%fait%% sa spécialité ce soir.",
      "L'équipe %%fait%% des efforts pour améliorer la qualité.",
      "Mon chat %%fait%% la sieste toute la journée.",
      "Je %%fais%% tout mon possible pour réussir.",
      "Tu %%fais%% un grand voyage cet été?",
      "Le vétérinaire %%fait%% des vaccinations gratuites ce mois-ci.",
      "Ma soeur %%fait%% de la natation tous les jours.",
      "Nous %%faisons%% confiance à nos instincts.",
      "Vous %%faites%% une énorme différence dans la communauté.",
      "Elles %%font%% du vélo tous les dimanches.",
      "Le jardinier %%fait%% des miracles avec ces fleurs.",
      "Mes collègues %%font%% un potluck demain.",
      "Le DJ %%fait%% danser tout le monde (humor).",
      "Je %%fais%% mes courses le samedi.",
      "Tu %%fais%% un bon point là.",
      "Le professeur %%fait%% une pause café maintenant.",
      "Le comité %%fait%% des plans pour la fête de fin d'année.",
      "Nous %%faisons%% une partie de cartes ce soir.",
      "Vous %%faites%% un don pour la charité?",
      "Ils %%font%% des études de marché pour le nouveau produit.",
    ],
    imparfait: [
      "Je %%faisais%% de la peinture tous les week-ends.",
      "Tu %%faisais%% souvent des balades en vélo?",
      "Il %%faisait%% du café chaque matin à 7 heures.",
      "Elle %%faisait%% toujours ses exercices de yoga.",
      "Mon professeur %%faisait%% la lecture chaque vendredi.",
      "Nous %%faisions%% des voyages en famille chaque été.",
      "Vous %%faisiez%% vos études à Paris, n'est-ce pas?",
      "Ils %%faisaient%% des tours de magie à la fête.",
      "Mes voisins %%faisaient%% du jardinage tous les samedis.",
      "Julie et toi %%faisiez%% souvent du ski en hiver.",
      "Le boulanger %%faisait%% du pain frais tous les jours.",
      "L'orchestre %%faisait%% des répétitions tous les mercredis.",
      "Ma grand-mère %%faisait%% toujours une soupe spéciale.",
      "Je %%faisais%% des puzzles pour me détendre.",
      "Tu %%faisais%% un effort pour être là à l'heure.",
      "Le maire %%faisait%% une inspection chaque mois.",
      "Mon frère %%faisait%% de la boxe le jeudi soir.",
      "Nous %%faisions%% souvent des barbecues le dimanche.",
      "Vous %%faisiez%% des visites guidées du musée.",
      "Elles %%faisaient%% du shopping tous les vendredis.",
      "Le gardien de nuit %%faisait%% des rondes régulières.",
      "Mes collègues %%faisaient%% des pauses café fréquentes.",
      "Le clown %%faisait%% rire les enfants (humor).",
      "Je %%faisais%% du babysitting tous les weekends.",
      "Tu %%faisais%% des voyages d'affaires chaque mois?",
      "La serveuse %%faisait%% des suggestions de menu.",
      "Les bénévoles %%faisaient%% des dons de sang.",
      "Nous %%faisions%% une pause toutes les deux heures.",
      "Vous %%faisiez%% un effort pour économiser de l'énergie.",
      "Ils %%faisaient%% des tours en montgolfière chaque été.",
    ],
    futurSimple: [
      "Je %%ferai%% une exposition de mes œuvres d'art.",
      "Tu %%feras%% une découverte incroyable, j'en suis sûr.",
      "Il %%fera%% une sieste après le déjeuner.",
      "Elle %%fera%% des recherches pour son projet de science.",
      "Mon patron %%fera%% une annonce importante demain.",
      "Nous %%ferons%% un feu de camp ce soir.",
      "Vous %%ferez%% vos adieux avant de partir.",
      "Ils %%feront%% une grande fête pour leur anniversaire de mariage.",
      "Mes enfants %%feront%% leurs devoirs après l'école.",
      "Paul et toi %%ferez%% du jogging tous les matins.",
      "Le juge %%fera%% sa décision la semaine prochaine.",
      "L'équipe %%fera%% de son mieux dans le tournoi.",
      "Ma sœur %%fera%% du patinage artistique cet hiver.",
      "Je %%ferai%% un don à une œuvre caritative.",
      "Tu %%feras%% la cuisine ce soir?",
      "Le guide %%fera%% une tournée du musée.",
      "Mon ami %%fera%% un discours à la conférence.",
      "Nous %%ferons%% un voyage en Europe l'année prochaine.",
      "Vous %%ferez%% des affaires avec notre nouvelle entreprise.",
      "Elles %%feront%% une performance en direct.",
      "Le photographe %%fera%% des portraits.",
      "Mes parents %%feront%% une croisière pour leur anniversaire.",
      "Le comique %%fera%% rire tout le monde (humor).",
      "Je %%ferai%% mes valises avant de partir.",
      "Tu %%feras%% une grande différence dans leur vie.",
      "La chanteuse %%fera%% un concert acoustique.",
      "Le club %%fera%% une levée de fonds ce mois-ci.",
      "Nous %%ferons%% un film documentaire.",
      "Vous %%ferez%% un saut en parachute?",
      "Ils %%feront%% des innovations technologiques.",
    ],
  },
};

export default faire;
