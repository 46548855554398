import React from "react";
import SelectorList from "./common/SelectorList";
import { verbs } from "../data/constants";
import { Counters } from "../data/helpers";

export type ListType = {
  onChange: (id: string) => void;
  counters: Counters;
};

const VerbList: React.FC<ListType> = ({ onChange, counters }) => {
  return (
    <>
      <h3 className="text-center">Les verbes</h3>
      <SelectorList
        list={verbs}
        onChange={onChange}
        counters={counters}
        prefix="verb"
        enableHints={true}
      />
    </>
  );
};

export default VerbList;
