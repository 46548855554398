const avoir = {
  avoir: {
    present: [
      "J'%%ai%% un nouveau travail.",
      "Tu %%as%% un sourire radieux.",
      "Il %%a%% une grande passion pour la musique.",
      "Elle %%a%% une collection impressionnante de livres.",
      "On %%a%% une réunion plus tard aujourd'hui.",
      "Nous %%avons%% une belle maison à la campagne.",
      "Vous %%avez%% beaucoup de talent pour la peinture.",
      "Ils %%ont%% une grande famille unie.",
      "Elles %%ont%% des idées brillantes pour le projet.",
      "Mon chat %%a%% de beaux yeux bleus.",
      "Ma mère %%a%% toujours de bons conseils.",
      "Mes amis %%ont%% prévu une surprise pour mon anniversaire.",
      "Mon père %%a%% une nouvelle voiture de sport.",
      "Le professeur %%a%% une grande connaissance de la littérature.",
      "Le directeur %%a%% une réunion avec les employés cet après-midi.",
      "La voisine %%a%% deux jolis petits chiens.",
      "Le parc près de chez moi %%a%% une grande aire de jeux.",
      "La vieille dame du troisième étage %%a%% toujours un mot gentil pour tout le monde.",
      "La bibliothèque municipale %%a%% une grande sélection de romans.",
      "Le café du coin %%a%% le meilleur expresso de la ville.",
      "La librairie en face %%a%% une section de bandes dessinées très complète.",
      "L'hôtel dans lequel nous avons séjourné %%a%% une belle vue sur la mer.",
      "Le musée que nous avons visité hier %%a%% une exposition temporaire sur l'Égypte ancienne.",
      "L'équipe de football locale %%a%% un match important ce weekend.",
      "Le restaurant où nous dînons ce soir %%a%% une excellente réputation.",
      "Le boulanger du village %%a%% toujours du pain frais le matin.",
      "Les enfants %%ont%% hâte d'aller au parc d'attractions ce weekend.",
      "Les jeunes du quartier %%ont%% organisé une fête pour la fin de l'année scolaire.",
      "Les étudiants de ma classe %%ont%% beaucoup de questions sur le sujet.",
      "Les voisins %%ont%% décidé de faire une fête de quartier le mois prochain.",
    ],
    passeCompose: [
      "Mon père %%a eu%% une longue journée de travail.",
      "L'artiste %%a eu%% une inspiration soudaine.",
      "Mes amis %%ont eu%% une belle fête d'anniversaire.",
      "Le chien %%a eu%% un os à mâcher.",
      "Ma soeur %%a eu%% une bonne note à son examen.",
      "Nous %%avons eu%% un bel été cette année.",
      "Le professeur %%a eu%% une idée brillante.",
      "Le chat %%a eu%% peur de l'orage.",
      "Vous %%avez eu%% une chance incroyable.",
      "Les arbres %%ont eu%% beaucoup de fruits cette saison.",
      "Mon voisin %%a eu%% un nouvel emploi.",
      "Les étudiants %%ont eu%% beaucoup de devoirs cette semaine.",
      "J'%%ai eu%% une idée pour un nouveau projet.",
      "Les touristes %%ont eu%% un merveilleux voyage.",
      "Le jardinier %%a eu%% une grande récolte de légumes.",
      "L'équipe %%a eu%% une victoire écrasante.",
      "Ma tante %%a eu%% une nouvelle maison.",
      "Les soldats %%ont eu%% une dure bataille.",
      "Le cuisinier %%a eu%% un compliment pour son plat.",
      "Vous %%avez eu%% une grande influence sur elle.",
      "Les bénévoles %%ont eu%% une journée occupée.",
      "Le vieil homme %%a eu%% un long voyage.",
      "Nous %%avons eu%% un agréable pique-nique.",
      "Le pilote %%a eu%% un atterrissage en douceur.",
      "Les musiciens %%ont eu%% un grand succès.",
      "La fleuriste %%a eu%% beaucoup de commandes.",
      "Vous %%avez eu%% une belle collection de timbres.",
      "Les danseurs %%ont eu%% une performance époustouflante.",
      "Mon cousin %%a eu%% une promotion.",
      "Les poètes %%ont eu%% une soirée de lecture passionnante.",
    ],
    imparfait: [
      "L'oiseau %%avait%% une belle chanson.",
      "Mon ami %%avait%% une vieille voiture.",
      "Nous %%avions%% un rêve commun.",
      "Ma grand-mère %%avait%% toujours une histoire à raconter.",
      "Les enfants %%avaient%% beaucoup d'énergie.",
      "Le village %%avait%% une belle vue sur la montagne.",
      "Vous %%aviez%% une confiance en vous.",
      "Le musée %%avait%% une grande collection d'art.",
      "Les pêcheurs %%avaient%% de bons équipements.",
      "Mon professeur %%avait%% beaucoup de patience.",
      "J'%%avais%% une question à poser.",
      "La plage %%avait%% beaucoup de coquillages.",
      "Les chercheurs %%avaient%% une hypothèse intéressante.",
      "La ville %%avait%% une riche histoire.",
      "L'athlète %%avait%% une grande détermination.",
      "La bibliothèque %%avait%% une grande variété de livres.",
      "Mon oncle %%avait%% une passion pour la musique.",
      "Les pompiers %%avaient%% une tâche difficile.",
      "L'écolière %%avait%% un sac à dos lourd.",
      "Nous %%avions%% un secret à garder.",
      "Le peintre %%avait%% un talent incroyable.",
      "Les ouvriers %%avaient%% une longue journée de travail.",
      "La serveuse %%avait%% un sourire accueillant.",
      "Vous %%aviez%% un sentiment d'accomplissement.",
      "Les fermiers %%avaient%% une récolte abondante.",
      "Le ciel %%avait%% une belle couleur au coucher du soleil.",
      "Les clients %%avaient%% un grand choix de produits.",
      "Le lion %%avait%% une crinière impressionnante.",
      "J'%%avais%% un amour pour la nature.",
      "Les acteurs %%avaient%% un rôle important.",
    ],
    plusQueParfait: [
      "Mon père %%avait eu%% une longue journée de travail.",
      "L'artiste %%avait eu%% une inspiration soudaine.",
      "Mes amis %%avaient eu%% une belle fête d'anniversaire.",
      "Le chien %%avait eu%% un os à mâcher.",
      "Ma soeur %%avait eu%% une bonne note à son examen.",
      "Nous %%avions eu%% un bel été cette année.",
      "Le professeur %%avait eu%% une idée brillante.",
      "Le chat %%avait eu%% peur de l'orage.",
      "Vous %%aviez eu%% une chance incroyable.",
      "Les arbres %%avaient eu%% beaucoup de fruits cette saison.",
      "Mon voisin %%avait eu%% un nouvel emploi.",
      "Les étudiants %%avaient eu%% beaucoup de devoirs cette semaine.",
      "J'%%avais eu%% une idée pour un nouveau projet.",
      "Les touristes %%avaient eu%% un merveilleux voyage.",
      "Le jardinier %%avait eu%% une grande récolte de légumes.",
      "L'équipe %%avait eu%% une victoire écrasante.",
      "Ma tante %%avait eu%% une nouvelle maison.",
      "Les soldats %%avaient eu%% une dure bataille.",
      "Le cuisinier %%avait eu%% un compliment pour son plat.",
      "Vous %%aviez eu%% une grande influence sur elle.",
      "Les bénévoles %%avaient eu%% une journée occupée.",
      "Le vieil homme %%avait eu%% un long voyage.",
      "Nous %%avions eu%% un agréable pique-nique.",
      "Le pilote %%avait eu%% un atterrissage en douceur.",
      "Les musiciens %%avaient eu%% un grand succès.",
      "La fleuriste %%avait eu%% beaucoup de commandes.",
      "Vous %%aviez eu%% une belle collection de timbres.",
      "Les danseurs %%avaient eu%% une performance époustouflante.",
      "Mon cousin %%avait eu%% une promotion.",
      "Les poètes %%avaient eu%% une soirée de lecture passionnante.",
    ],
    passeSimple: [
      "Mon père %%eut%% une longue journée de travail.",
      "L'artiste %%eut%% une inspiration soudaine.",
      "Mes amis %%eurent%% une belle fête d'anniversaire.",
      "Le chien %%eut%% un os à mâcher.",
      "Ma soeur %%eut%% une bonne note à son examen.",
      "Nous %%eûmes%% un bel été cette année.",
      "Le professeur %%eut%% une idée brillante.",
      "Le chat %%eut%% peur de l'orage.",
      "Vous %%eûtes%% une chance incroyable.",
      "Les arbres %%eurent%% beaucoup de fruits cette saison.",
      "Mon voisin %%eut%% un nouvel emploi.",
      "Les étudiants %%eurent%% beaucoup de devoirs cette semaine.",
      "J'%%eus%% une idée pour un nouveau projet.",
      "Les touristes %%eurent%% un merveilleux voyage.",
      "Le jardinier %%eut%% une grande récolte de légumes.",
      "L'équipe %%eut%% une victoire écrasante.",
      "Ma tante %%eut%% une nouvelle maison.",
      "Les soldats %%eurent%% une dure bataille.",
      "Le cuisinier %%eut%% un compliment pour son plat.",
      "Vous %%eûtes%% une grande influence sur elle.",
      "Les bénévoles %%eurent%% une journée occupée.",
      "Le vieil homme %%eut%% un long voyage.",
      "Nous %%eûmes%% un agréable pique-nique.",
      "Le pilote %%eut%% un atterrissage en douceur.",
      "Les musiciens %%eurent%% un grand succès.",
      "La fleuriste %%eut%% beaucoup de commandes.",
      "Vous %%eûtes%% une belle collection de timbres.",
      "Les danseurs %%eurent%% une performance époustouflante.",
      "Mon cousin %%eut%% une promotion.",
      "Les poètes %%eurent%% une soirée de lecture passionnante.",
    ],
    passeAnterieur: [
      "Mon père %%eut eu%% une longue journée de travail.",
      "L'artiste %%eut eu%% une inspiration soudaine.",
      "Mes amis %%eurent eu%% une belle fête d'anniversaire.",
      "Le chien %%eut eu%% un os à mâcher.",
      "Ma soeur %%eut eu%% une bonne note à son examen.",
      "Nous %%eûmes eu%% un bel été cette année.",
      "Le professeur %%eut eu%% une idée brillante.",
      "Le chat %%eut eu%% peur de l'orage.",
      "Vous %%eûtes eu%% une chance incroyable.",
      "Les arbres %%eurent eu%% beaucoup de fruits cette saison.",
      "Mon voisin %%eut eu%% un nouvel emploi.",
      "Les étudiants %%eurent eu%% beaucoup de devoirs cette semaine.",
      "J'%%eus eu%% une idée pour un nouveau projet.",
      "Les touristes %%eurent eu%% un merveilleux voyage.",
      "Le jardinier %%eut eu%% une grande récolte de légumes.",
      "L'équipe %%eut eu%% une victoire écrasante.",
      "Ma tante %%eut eu%% une nouvelle maison.",
      "Les soldats %%eurent eu%% une dure bataille.",
      "Le cuisinier %%eut eu%% un compliment pour son plat.",
      "Vous %%eûtes eu%% une grande influence sur elle.",
      "Les bénévoles %%eurent eu%% une journée occupée.",
      "Le vieil homme %%eut eu%% un long voyage.",
      "Nous %%eûmes eu%% un agréable pique-nique.",
      "Le pilote %%eut eu%% un atterrissage en douceur.",
      "Les musiciens %%eurent eu%% un grand succès.",
      "La fleuriste %%eut eu%% beaucoup de commandes.",
      "Vous %%eûtes eu%% une belle collection de timbres.",
      "Les danseurs %%eurent eu%% une performance époustouflante.",
      "Mon cousin %%eut eu%% une promotion.",
      "Les poètes %%eurent eu%% une soirée de lecture passionnante.",
    ],
    futurSimple: [
      "Mon père %%aura%% une longue journée de travail.",
      "L'artiste %%aura%% une inspiration soudaine.",
      "Mes amis %%auront%% une belle fête d'anniversaire.",
      "Le chien %%aura%% un os à mâcher.",
      "Ma soeur %%aura%% une bonne note à son examen.",
      "Nous %%aurons%% un bel été cette année.",
      "Le professeur %%aura%% une idée brillante.",
      "Le chat %%aura%% peur de l'orage.",
      "Vous %%aurez%% une chance incroyable.",
      "Les arbres %%auront%% beaucoup de fruits cette saison.",
      "Mon voisin %%aura%% un nouvel emploi.",
      "Les étudiants %%auront%% beaucoup de devoirs cette semaine.",
      "J'%%aurai%% une idée pour un nouveau projet.",
      "Les touristes %%auront%% un merveilleux voyage.",
      "Le jardinier %%aura%% une grande récolte de légumes.",
      "L'équipe %%aura%% une victoire écrasante.",
      "Ma tante %%aura%% une nouvelle maison.",
      "Les soldats %%auront%% une dure bataille.",
      "Le cuisinier %%aura%% un compliment pour son plat.",
      "Vous %%aurez%% une grande influence sur elle.",
      "Les bénévoles %%auront%% une journée occupée.",
      "Le vieil homme %%aura%% un long voyage.",
      "Nous %%aurons%% un agréable pique-nique.",
      "Le pilote %%aura%% un atterrissage en douceur.",
      "Les musiciens %%auront%% un grand succès.",
      "La fleuriste %%aura%% beaucoup de commandes.",
      "Vous %%aurez%% une belle collection de timbres.",
      "Les danseurs %%auront%% une performance époustouflante.",
      "Mon cousin %%aura%% une promotion.",
      "Les poètes %%auront%% une soirée de lecture passionnante.",
    ],
    futurAnterieur: [
      "Mon père %%aura eu%% une longue journée de travail.",
      "L'artiste %%aura eu%% une inspiration soudaine.",
      "Mes amis %%auront eu%% une belle fête d'anniversaire.",
      "Le chien %%aura eu%% un os à mâcher.",
      "Ma soeur %%aura eu%% une bonne note à son examen.",
      "Nous %%aurons eu%% un bel été cette année.",
      "Le professeur %%aura eu%% une idée brillante.",
      "Le chat %%aura eu%% peur de l'orage.",
      "Vous %%aurez eu%% une chance incroyable.",
      "Les arbres %%auront eu%% beaucoup de fruits cette saison.",
      "Mon voisin %%aura eu%% un nouvel emploi.",
      "Les étudiants %%auront eu%% beaucoup de devoirs cette semaine.",
      "J'%%aurai eu%% une idée pour un nouveau projet.",
      "Les touristes %%auront eu%% un merveilleux voyage.",
      "Le jardinier %%aura eu%% une grande récolte de légumes.",
      "L'équipe %%aura eu%% une victoire écrasante.",
      "Ma tante %%aura eu%% une nouvelle maison.",
      "Les soldats %%auront eu%% une dure bataille.",
      "Le cuisinier %%aura eu%% un compliment pour son plat.",
      "Vous %%aurez eu%% une grande influence sur elle.",
      "Les bénévoles %%auront eu%% une journée occupée.",
      "Le vieil homme %%aura eu%% un long voyage.",
      "Nous %%aurons eu%% un agréable pique-nique.",
      "Le pilote %%aura eu%% un atterrissage en douceur.",
      "Les musiciens %%auront eu%% un grand succès.",
      "La fleuriste %%aura eu%% beaucoup de commandes.",
      "Vous %%aurez eu%% une belle collection de timbres.",
      "Les danseurs %%auront eu%% une performance époustouflante.",
      "Mon cousin %%aura eu%% une promotion.",
      "Les poètes %%auront eu%% une soirée de lecture passionnante.",
    ],
  },
};


export default avoir;