const ouvrir: any = {
  ouvrir: {
    present: [
      "Je %%ouvre%% la porte pour prendre l'air frais.",
      "Tu %%ouvres%% toujours ton livre à la dernière page.",
      "Il %%ouvre%% une nouvelle bouteille de vin.",
      "Elle %%ouvre%% la fenêtre tous les matins.",
      "Nous %%ouvrons%% le magasin à 9 heures.",
      "Vous %%ouvrez%% vos cadeaux à minuit.",
      "Ils %%ouvrent%% les festivités avec un feu d'artifice.",
      "Les fleurs %%ouvrent%% leurs pétales au lever du soleil.",
      "Mes parents %%ouvrent%% leur maison aux invités chaque été.",
      "Mon ami et moi %%ouvrons%% une nouvelle entreprise ensemble.",
      "Le professeur %%ouvre%% le débat sur la politique actuelle.",
      "La bibliothèque %%ouvre%% ses portes aux étudiants jusqu'à tard.",
      "Les musées %%ouvrent%% gratuitement le premier dimanche du mois.",
      "Les oiseaux %%ouvrent%% leurs ailes pour prendre leur envol.",
      "Le chat %%ouvre%% les yeux lentement.",
      "Les enfants %%ouvrent%% leurs sacs pour y mettre les bonbons.",
      "Les cyclistes %%ouvrent%% la course avec une grande vitesse.",
      "Les artistes %%ouvrent%% leur atelier au public une fois par an.",
      "Le chef %%ouvre%% le repas avec une entrée délicieuse.",
      "Le médecin %%ouvre%% le dossier médical du patient.",
      "Les danseurs %%ouvrent%% le spectacle avec une performance.",
      "La directrice %%ouvre%% la réunion avec quelques annonces.",
      "Les bénévoles %%ouvrent%% les boîtes de dons.",
      "Le jardinier %%ouvre%% le robinet pour arroser les plantes.",
      "Le serveur %%ouvre%% une nouvelle table pour les clients.",
      "Les traders %%ouvrent%% la séance en bourse avec optimisme.",
      "Les cinéastes %%ouvrent%% le festival de films ce soir.",
      "Les astronautes %%ouvrent%% la capsule spatiale avec précaution.",
      "Les militants %%ouvrent%% une nouvelle campagne pour les droits de l'homme.",
      "Le quartier %%ouvre%% une nouvelle aire de jeux pour les enfants.",
    ],
    imparfait: [
      "Je %%ouvrais%% le livre à la première page chaque fois.",
      "Tu %%ouvrais%% souvent la porte à des inconnus.",
      "Il %%ouvrait%% la boîte de pandore sans le savoir.",
      "Elle %%ouvrait%% toujours la fenêtre, même en hiver.",
      "Nous %%ouvrions%% les portes du jardin à l'aube.",
      "Vous %%ouvriez%% les réunions avec une chanson.",
      "Ils %%ouvraient%% leur maison aux voyageurs de passage.",
      "Les commerçants %%ouvraient%% leurs boutiques tard le samedi.",
      "Mes amis %%ouvraient%% toujours leurs cœurs aux nécessiteux.",
      "Mon frère et moi %%ouvrions%% les cadeaux en tremblant d'excitation.",
      "Le musée %%ouvrait%% ses salles pour des visites nocturnes.",
      "La vieille dame %%ouvrait%% la grille pour nourrir les chats.",
      "Les employés %%ouvraient%% les bureaux à sept heures du matin.",
      "Les oiseaux %%ouvraient%% leurs ailes pour se sécher.",
      "Le théâtre %%ouvrait%% ses rideaux sur une scène vide.",
      "Les enfants %%ouvraient%% leurs yeux grands comme des soucoupes.",
      "Les marins %%ouvraient%% les voiles quand le vent soufflait.",
      "Les écoliers %%ouvraient%% leurs cahiers quand la maîtresse arrivait.",
      "Le chef de la gare %%ouvrait%% le guichet à la première heure.",
      "Les serveurs %%ouvraient%% les bouteilles de champagne pour fêter.",
      "Le guide %%ouvrait%% la marche dans la forêt dense.",
      "Les campeurs %%ouvraient%% leur tente pour aérer.",
      "La chorale %%ouvrait%% les cérémonies avec un hymne.",
      "L'écrivain %%ouvrait%% son manuscrit avec une citation inspirante.",
      "Les organisateurs %%ouvraient%% les festivités par un discours.",
      "Les gardiens %%ouvraient%% les portes du zoo très tôt.",
      "Le jardinier %%ouvrait%% l'arrosoir pour hydrater les plantes.",
      "Les cinéphiles %%ouvraient%% le festival avec un film classique.",
      "Les astronautes %%ouvraient%% le sas avec beaucoup de précaution.",
      "Le public %%ouvrait%% les applaudissements après chaque acte.",
    ],
    futurSimple: [
      "Je %%ouvrirai%% une nouvelle page de ma vie demain.",
      "Tu %%ouvriras%% la boîte que j'ai laissée sur la table.",
      "Il %%ouvrira%% son propre restaurant l'année prochaine.",
      "Elle %%ouvrira%% une école de danse dans sa ville natale.",
      "Nous %%ouvrirons%% les inscriptions pour le cours la semaine prochaine.",
      "Vous %%ouvrirez%% une succursale à l'étranger, n'est-ce pas?",
      "Ils %%ouvriront%% le spectacle avec un numéro de jonglage.",
      "Les autorités %%ouvriront%% une enquête sur l'accident.",
      "Mon ami et sa sœur %%ouvriront%% une librairie ensemble.",
      "Les étudiants %%ouvriront%% la conférence avec une présentation.",
      "Le directeur %%ouvrira%% la cérémonie de remise des diplômes.",
      "La compagnie aérienne %%ouvrira%% une nouvelle ligne vers Tokyo.",
      "Les artistes %%ouvriront%% leurs studios pour une journée porte ouverte.",
      "Le public %%ouvrira%% la séance de questions et réponses.",
      "Les ingénieurs %%ouvriront%% le barrage pour libérer de l'eau.",
      "Les enfants %%ouvriront%% leurs cadeaux de Noël avec enthousiasme.",
      "L'orchestre %%ouvrira%% la saison avec une symphonie de Beethoven.",
      "Le gouvernement %%ouvrira%% des négociations avec les syndicats.",
      "Le jardin botanique %%ouvrira%% une nouvelle exposition sur les plantes tropicales.",
      "Les pompiers %%ouvriront%% les portes de la caserne pour une visite guidée.",
      "La ville %%ouvrira%% une nouvelle piscine communautaire cet été.",
      "Le chanteur %%ouvrira%% sa tournée en France.",
      "Les archéologues %%ouvriront%% une nouvelle fouille dans le désert.",
      "La fondation %%ouvrira%% un centre pour les enfants défavorisés.",
      "Les éditeurs %%ouvriront%% un nouveau chapitre dans le domaine du livre numérique.",
      "Le cirque %%ouvrira%% ses portes pour la dernière fois ce dimanche.",
      "Les astronautes %%ouvriront%% la capsule de retour dès leur arrivée.",
      "Les fermiers %%ouvriront%% le marché fermier chaque samedi.",
      "La famille royale %%ouvrira%% le bal annuel.",
      "Les militants %%ouvriront%% le défilé avec une grande banderole.",
    ],
  },
};

export default ouvrir;
