const tenir: any = {
  tenir: {
    present: [
      "Je %%tiens%% fermement à mes convictions.",
      "Tu %%tiens%% ta promesse, n'est-ce pas?",
      "Il %%tient%% le volant avec assurance.",
      "Elle %%tient%% le bébé dans ses bras.",
      "Nous %%tenons%% une conférence sur le changement climatique demain.",
      "Vous %%tenez%% le magasin pendant les vacances?",
      "Ils %%tiennent%% le premier rôle dans la pièce de théâtre.",
      "Le serveur %%tient%% le plateau d'une seule main.",
      "Ma sœur %%tient%% un blog sur la mode.",
      "Mon frère et moi %%tenons%% un stand au marché ce week-end.",
      "Le directeur %%tient%% une réunion imprévue.",
      "Le chien %%tient%% le bâton dans sa gueule.",
      "Les athlètes %%tiennent%% une position de départ.",
      "L'orchestre %%tient%% la note pendant plusieurs secondes.",
      "Les artistes %%tiennent%% leurs pinceaux avec finesse.",
      "La bibliothèque %%tient%% une grande collection de livres anciens.",
      "Les enfants %%tiennent%% leurs cahiers ouverts.",
      "La police %%tient%% une barrière pour contrôler la foule.",
      "L'infirmière %%tient%% la main du patient.",
      "Les étudiants %%tiennent%% leur diplôme avec fierté.",
      "L'équipe %%tient%% le trophée en l'air.",
      "La fleuriste %%tient%% un bouquet de roses.",
      "Les danseurs %%tiennent%% la pose à la fin de la performance.",
      "Les militants %%tiennent%% des pancartes pendant la manifestation.",
      "La cuisinière %%tient%% la casserole loin du feu.",
      "Le jardinier %%tient%% la pelle fermement.",
      "Les voyageurs %%tiennent%% leurs passeports prêts.",
      "La professeure %%tient%% le stylo rouge pour corriger les copies.",
      "Les spectateurs %%tiennent%% leur souffle pendant la dernière seconde du match.",
      "L'arbitre %%tient%% le sifflet à la bouche.",
    ],
    imparfait: [
      "Je %%tenais%% le rôle principal dans la pièce de théâtre.",
      "Tu %%tenais%% le ballon pendant tout le match.",
      "Il %%tenait%% toujours la porte pour les autres.",
      "Elle %%tenait%% un journal intime quand elle était jeune.",
      "Nous %%tenions%% régulièrement des réunions familiales.",
      "Vous %%teniez%% un stand au marché tous les samedis.",
      "Ils %%tenaient%% leurs enfants par la main dans la foule.",
      "Les artistes %%tenaient%% des expositions dans la galerie.",
      "Les musiciens %%tenaient%% la note pendant la performance.",
      "Ma grand-mère %%tenait%% un magasin de fleurs dans sa jeunesse.",
      "Le professeur %%tenait%% des cours de philosophie chaque semaine.",
      "Les soldats %%tenaient%% la ligne de front avec courage.",
      "Le chat %%tenait%% la souris dans sa gueule.",
      "La comédienne %%tenait%% l'auditoire en haleine.",
      "Les voisins %%tenaient%% toujours leurs promesses.",
      "Les élèves %%tenaient%% leurs stylos prêts pour prendre des notes.",
      "Le jardinier %%tenait%% la pelle tout en parlant à son apprenti.",
      "Le serveur %%tenait%% le menu pour les clients.",
      "Les enfants %%tenaient%% des lanternes pendant la fête.",
      "Le chef d'orchestre %%tenait%% la baguette avec élégance.",
      "L'arbitre %%tenait%% le sifflet prêt pour signaler la fin du match.",
      "Les écrivains %%tenaient%% des séances de dédicaces après chaque publication.",
      "Les pêcheurs %%tenaient%% leurs cannes en attendant une prise.",
      "La foule %%tenait%% des bougies pendant la veillée.",
      "Les acteurs %%tenaient%% leurs scripts en répétant leurs lignes.",
      "Le photographe %%tenait%% son appareil photo prêt pour le moment parfait.",
      "Les athlètes %%tenaient%% leur position avant le coup de sifflet.",
      "La sorcière dans l'histoire %%tenait%% une pomme empoisonnée.",
      "Les guides %%tenaient%% les cartes pendant l'expédition.",
      "Le maître de cérémonie %%tenait%% le micro tout au long de l'événement.",
    ],
    futurSimple: [
      "Je %%tiendrai%% ma promesse, quoi qu'il arrive.",
      "Tu %%tiendras%% la porte pour les invités.",
      "Il %%tiendra%% la conférence demain matin.",
      "Elle %%tiendra%% un stand au marché ce week-end.",
      "Nous %%tiendrons%% un séminaire sur l'énergie renouvelable.",
      "Vous %%tiendrez%% le rôle principal dans la prochaine pièce.",
      "Ils %%tiendront%% la ligne de front, peu importe le risque.",
      "Mes parents %%tiendront%% une réception après la cérémonie.",
      "Les enfants %%tiendront%% des bougies pendant la procession.",
      "L'équipe de football %%tiendra%% une conférence de presse.",
      "Mon ami et moi %%tiendrons%% les billets pour le concert.",
      "Les militants %%tiendront%% une manifestation la semaine prochaine.",
      "La chanteuse %%tiendra%% le micro avec confiance.",
      "Les étudiants %%tiendront%% des présentations à la fin du semestre.",
      "L'artiste %%tiendra%% une exposition de ses nouvelles œuvres.",
      "Les comédiens %%tiendront%% le public en haleine avec leur performance.",
      "Le directeur %%tiendra%% compte de toutes les suggestions.",
      "Le jury %%tiendra%% une session de délibération après le procès.",
      "La gardienne de but %%tiendra%% sa position pendant le tir au but.",
      "Les pompiers %%tiendront%% la lance d'incendie fermement.",
      "La professeure %%tiendra%% les copies corrigées lundi.",
      "Le maître de cérémonie %%tiendra%% la liste des invités.",
      "Les chercheurs %%tiendront%% une table ronde sur les changements climatiques.",
      "La foule %%tiendra%% une veillée pour honorer la mémoire des disparus.",
      "Le coiffeur %%tiendra%% les ciseaux avec précision.",
      "Le fermier %%tiendra%% la barrière pour laisser passer les moutons.",
      "Le boulanger %%tiendra%% une nouvelle fournée de pain prête demain.",
      "Les écologistes %%tiendront%% un discours sur la conservation de la nature.",
      "La libraire %%tiendra%% une sélection de livres rares.",
      "Le vétérinaire %%tiendra%% une clinique gratuite pour les animaux en détresse.",
    ],
  },
};

export default tenir;
