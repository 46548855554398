// src/pages/NotFoundRedirect.tsx
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NotFoundRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/", { replace: true });
  }, [navigate]);

  return null;
}

export default NotFoundRedirect;
