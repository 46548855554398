let savoirConjugations = {
  present: [
    "je %%sais%%",
    "tu %%sais%%",
    "il/elle %%sait%%",
    "nous %%savons%%",
    "vous %%savez%%",
    "ils/elles %%savent%%",
  ],
  passeCompose: [
    "j'%%ai su%%",
    "tu %%as su%%",
    "il/elle %%a su%%",
    "nous %%avons su%%",
    "vous %%avez su%%",
    "ils/elles %%ont su%%",
  ],
  imparfait: [
    "je %%savais%%",
    "tu %%savais%%",
    "il/elle %%savait%%",
    "nous %%savions%%",
    "vous %%saviez%%",
    "ils/elles %%savaient%%",
  ],
  plusQueParfait: [
    "j'%%avais su%%",
    "tu %%avais su%%",
    "il/elle %%avait su%%",
    "nous %%avions su%%",
    "vous %%aviez su%%",
    "ils/elles %%avaient su%%",
  ],
  passeSimple: [
    "je %%sus%%",
    "tu %%sus%%",
    "il/elle %%sut%%",
    "nous %%sûmes%%",
    "vous %%sûtes%%",
    "ils/elles %%surent%%",
  ],
  passeAnterieur: [
    "j'%%eus su%%",
    "tu %%eus su%%",
    "il/elle %%eut su%%",
    "nous %%eûmes su%%",
    "vous %%eûtes su%%",
    "ils/elles %%eurent su%%",
  ],
  futurSimple: [
    "je %%saurai%%",
    "tu %%sauras%%",
    "il/elle %%saura%%",
    "nous %%saurons%%",
    "vous %%saurez%%",
    "ils/elles %%sauront%%",
  ],
  futurAnterieur: [
    "j'%%aurai su%%",
    "tu %%auras su%%",
    "il/elle %%aura su%%",
    "nous %%aurons su%%",
    "vous %%aurez su%%",
    "ils/elles %%auront su%%",
  ],
  subjonctif_present: [
    "que je %%sache%%",
    "que tu %%saches%%",
    "qu'il/elle %%sache%%",
    "que nous %%sachions%%",
    "que vous %%sachiez%%",
    "qu'ils/elles %%sachent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie su%%",
    "que tu %%aies su%%",
    "qu'il/elle %%ait su%%",
    "que nous %%ayons su%%",
    "que vous %%ayez su%%",
    "qu'ils/elles %%aient su%%",
  ],
  subjonctif_imparfait: [
    "que je %%susse%%",
    "que tu %%susses%%",
    "qu'il/elle %%sût%%",
    "que nous %%sussions%%",
    "que vous %%sussiez%%",
    "qu'ils/elles %%sussent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse su%%",
    "que tu %%eusses su%%",
    "qu'il/elle %%eût su%%",
    "que nous %%eussions su%%",
    "que vous %%eussiez su%%",
    "qu'ils/elles %%eussent su%%",
  ],
  conditionnel_present: [
    "je %%saurais%%",
    "tu %%saurais%%",
    "il/elle %%saurait%%",
    "nous %%saurions%%",
    "vous %%sauriez%%",
    "ils/elles %%sauraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais su%%",
    "tu %%aurais su%%",
    "il/elle %%aurait su%%",
    "nous %%aurions su%%",
    "vous %%auriez su%%",
    "ils/elles %%auraient su%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais su%%",
    "tu %%aurais su%%",
    "il/elle %%aurait su%%",
    "nous %%aurions su%%",
    "vous %%auriez su%%",
    "ils/elles %%auraient su%%",
  ],
};

export default savoirConjugations;
