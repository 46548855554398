const prendre: any = {
  prendre: {
    present: [
      "Je %%prends%% le métro tous les matins.",
      "Tu %%prends%% du café ou du thé?",
      "Il %%prend%% sa retraite cette année.",
      "Nous %%prenons%% un vol pour Tokyo.",
      "Mes amis %%prennent%% un verre en terrasse.",
      "Vous %%prenez%% le dessert?",
      "Elles %%prennent%% leur temps.",
      "Le journaliste %%prend%% des notes pendant l'interview.",
      "Les enfants %%prennent%% le bus pour l'école.",
      "On %%prend%% la sortie 12 sur l'autoroute.",
      "Ma mère %%prend%% des photos en voyage.",
      "Tu %%prends%% des risques en faisant ça.",
      "Le chien %%prend%% sa nourriture dans sa gamelle.",
      "Vous %%prenez%% l'apéro ce soir?",
      "Les investisseurs %%prennent%% des décisions importantes.",
      "Le chef %%prend%% les ingrédients pour la recette.",
      "Nous %%prenons%% la route demain matin.",
      "Elle %%prend%% une douche rapide.",
      "Ils %%prennent%% leur santé au sérieux.",
      "La police %%prend%% les empreintes digitales.",
      "Je %%prends%% un bain relaxant.",
      "Les élèves %%prennent%% leurs cahiers.",
      "Vous %%prenez%% le temps de réfléchir?",
      "Nous %%prenons%% l'initiative de nettoyer le parc.",
      "Il %%prend%% le dernier train pour rentrer.",
      "Le fermier %%prend%% soin des animaux.",
      "Elles %%prennent%% un cours de yoga.",
      "La chanteuse %%prend%% la scène à 21h.",
      "Je %%prends%% ma voiture pour le travail.",
      "Vous %%prenez%% le petit déjeuner à la maison.",
    ],
    imparfait: [
      "Je %%prenais%% le bus tous les jours.",
      "Tu %%prenais%% toujours la même chose au restaurant.",
      "Il %%prenait%% des cours de piano.",
      "Nous %%prenions%% le petit déjeuner ensemble.",
      "Les touristes %%prenaient%% des photos du monument.",
      "Vous %%preniez%% des vacances en août.",
      "Elles %%prenaient%% l'ascenseur pour monter.",
      "Mon frère %%prenait%% la parole en classe.",
      "Les oiseaux %%prenaient%% leur envol au printemps.",
      "On %%prenait%% souvent la mauvaise route.",
      "Mes parents %%prenaient%% le journal tous les matins.",
      "Tu %%prenais%% des notes en classe?",
      "La serveuse %%prenait%% les commandes des clients.",
      "Vous %%preniez%% l'autoroute ou la route nationale?",
      "Les joggeurs %%prenaient%% le sentier du parc.",
      "Elle %%prenait%% un bain moussant chaque soir.",
      "Nous %%prenions%% nos responsabilités.",
      "Le professeur %%prenait%% le temps d'expliquer.",
      "Vous %%preniez%% l'avion chaque mois?",
      "Ils %%prenaient%% du plaisir à cuisiner.",
      "Le fermier %%prenait%% soin de ses plantes.",
      "Les étudiants %%prenaient%% le bus pour l'université.",
      "Le conducteur %%prenait%% toujours ce chemin.",
      "Je %%prenais%% soin de mes affaires.",
      "Tu %%prenais%% plaisir à la lecture?",
      "Ma grand-mère %%prenait%% ses médicaments à heure fixe.",
      "Vous %%preniez%% des risques calculés.",
      "Le comité %%prenait%% des décisions chaque semaine.",
      "Elles %%prenaient%% plaisir à danser.",
      "On %%prenait%% les choses comme elles venaient.",
    ],
    futurSimple: [
      "Je %%prendrai%% un billet de train demain.",
      "Tu %%prendras%% des vacances cet été?",
      "Il %%prendra%% ses médicaments après le repas.",
      "Nous %%prendrons%% la sortie suivante.",
      "Les candidats %%prendront%% part au débat.",
      "Vous %%prendrez%% une décision avant la fin du mois.",
      "Elles %%prendront%% l'avion pour New York.",
      "Mon ami %%prendra%% la place du conducteur.",
      "Les chefs %%prendront%% en compte les avis des employés.",
      "On %%prendra%% ce que l'on pourra obtenir.",
      "Ma sœur %%prendra%% la relève en cuisine.",
      "Tu %%prendras%% soin de ton jardin?",
      "L'arbitre %%prendra%% des mesures strictes.",
      "Vous %%prendrez%% le dessert avec votre café?",
      "Les étudiants %%prendront%% des notes pendant le cours.",
      "Je %%prendrai%% un Uber pour rentrer.",
      "Le directeur %%prendra%% la parole en premier.",
      "Nous %%prendrons%% nos responsabilités très au sérieux.",
      "Vous %%prendrez%% le temps de méditer?",
      "Ils %%prendront%% la route à l'aube.",
      "Le soleil %%prendra%% son temps pour se coucher.",
      "Les membres du jury %%prendront%% une décision unanime.",
      "Le chat %%prendra%% sa sieste habituelle.",
      "Je %%prendrai%% soin de finir ce projet.",
      "Tu %%prendras%% un rendez-vous chez le médecin?",
      "L'équipe %%prendra%% toutes les précautions nécessaires.",
      "Elles %%prendront%% leur temps pour décider.",
      "Le comité %%prendra%% les appels à partir de demain.",
      "Le vendeur %%prendra%% votre commande maintenant.",
      "Vous %%prendrez%% le thé ou le café?",
    ],
  },
};

export default prendre;
