let direConjugations = {
  present: [
    "je %%dis%%",
    "tu %%dis%%",
    "il/elle %%dit%%",
    "nous %%disons%%",
    "vous %%dites%%",
    "ils/elles %%disent%%",
  ],
  passeCompose: [
    "j'%%ai dit%%",
    "tu %%as dit%%",
    "il/elle %%a dit%%",
    "nous %%avons dit%%",
    "vous %%avez dit%%",
    "ils/elles %%ont dit%%",
  ],
  imparfait: [
    "je %%disais%%",
    "tu %%disais%%",
    "il/elle %%disait%%",
    "nous %%disions%%",
    "vous %%disiez%%",
    "ils/elles %%disaient%%",
  ],
  plusQueParfait: [
    "j'%%avais dit%%",
    "tu %%avais dit%%",
    "il/elle %%avait dit%%",
    "nous %%avions dit%%",
    "vous %%aviez dit%%",
    "ils/elles %%avaient dit%%",
  ],
  passeSimple: [
    "je %%dis%%",
    "tu %%dis%%",
    "il/elle %%dit%%",
    "nous %%dîmes%%",
    "vous %%dîtes%%",
    "ils/elles %%dirent%%",
  ],
  passeAnterieur: [
    "j'%%eus dit%%",
    "tu %%eus dit%%",
    "il/elle %%eut dit%%",
    "nous %%eûmes dit%%",
    "vous %%eûtes dit%%",
    "ils/elles %%eurent dit%%",
  ],
  futurSimple: [
    "je %%dirai%%",
    "tu %%diras%%",
    "il/elle %%dira%%",
    "nous %%dirons%%",
    "vous %%direz%%",
    "ils/elles %%diront%%",
  ],
  futurAnterieur: [
    "j'%%aurai dit%%",
    "tu %%auras dit%%",
    "il/elle %%aura dit%%",
    "nous %%aurons dit%%",
    "vous %%aurez dit%%",
    "ils/elles %%auront dit%%",
  ],
  subjonctif_present: [
    "que je %%dise%%",
    "que tu %%dises%%",
    "qu'il/elle %%dise%%",
    "que nous %%disions%%",
    "que vous %%disiez%%",
    "qu'ils/elles %%disent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie dit%%",
    "que tu %%aies dit%%",
    "qu'il/elle %%ait dit%%",
    "que nous %%ayons dit%%",
    "que vous %%ayez dit%%",
    "qu'ils/elles %%aient dit%%",
  ],
  subjonctif_imparfait: [
    "que je %%disse%%",
    "que tu %%disses%%",
    "qu'il/elle %%dît%%",
    "que nous %%dissions%%",
    "que vous %%dissiez%%",
    "qu'ils/elles %%dissent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse dit%%",
    "que tu %%eusses dit%%",
    "qu'il/elle %%eût dit%%",
    "que nous %%eussions dit%%",
    "que vous %%eussiez dit%%",
    "qu'ils/elles %%eussent dit%%",
  ],
  conditionnel_present: [
    "je %%dirais%%",
    "tu %%dirais%%",
    "il/elle %%dirait%%",
    "nous %%dirions%%",
    "vous %%diriez%%",
    "ils/elles %%diraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais dit%%",
    "tu %%aurais dit%%",
    "il/elle %%aurait dit%%",
    "nous %%aurions dit%%",
    "vous %%auriez dit%%",
    "ils/elles %%auraient dit%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais dit%%",
    "tu %%aurais dit%%",
    "il/elle %%aurait dit%%",
    "nous %%aurions dit%%",
    "vous %%auriez dit%%",
    "ils/elles %%auraient dit%%",
  ],
};

export default direConjugations;
