const devoir: any = {
  devoir: {
    present: [
      "Je %%dois%% finir mon travail avant ce soir.",
      "Tu %%dois%% prendre tes médicaments tous les jours.",
      "Il %%doit%% réviser pour son examen de mathématiques.",
      "Elle %%doit%% passer chez le coiffeur demain.",
      "Nous %%devons%% voter avant la fermeture des bureaux.",
      "Vous %%devez%% vous inscrire en ligne pour le séminaire.",
      "Ils %%doivent%% rendre leur projet la semaine prochaine.",
      "Les enfants %%doivent%% aller au lit avant 9 heures.",
      "Ma mère %%doit%% prendre le train pour Paris demain.",
      "Mon collègue et moi %%devons%% préparer une présentation.",
      "Les athlètes %%doivent%% suivre un régime strict pendant la compétition.",
      "Le professeur %%doit%% corriger les copies avant lundi.",
      "La police %%doit%% enquêter sur cette affaire immédiatement.",
      "Le maire %%doit%% prendre une décision concernant le budget.",
      "Les touristes %%doivent%% respecter les règles du parc national.",
      "L'équipe de nettoyage %%doit%% désinfecter les locaux.",
      "Le jardinier %%doit%% arroser les plantes en fin de journée.",
      "Le chirurgien %%doit%% opérer ce soir.",
      "Les investisseurs %%doivent%% étudier le marché attentivement.",
      "Les étudiants en art %%doivent%% visiter le musée pour leur cours.",
      "Le comité %%doit%% se réunir une fois par mois.",
      "Le pilote %%doit%% effectuer des vérifications avant le décollage.",
      "Les serveurs %%doivent%% porter des masques en salle.",
      "La chef d'orchestre %%doit%% répéter avec les musiciens.",
      "Le journaliste %%doit%% soumettre son article demain matin.",
      "Les bénévoles %%doivent%% se coordonner pour l'événement caritatif.",
      "Les scientifiques %%doivent%% valider leurs résultats.",
      "Les propriétaires %%doivent%% payer leurs taxes foncières chaque année.",
      "Le jury %%doit%% délibérer sur le verdict.",
      "Les passagers %%doivent%% embarquer maintenant.",
      "Tu %%dois%% étudier pour ton examen.",
      "Il %%doit%% aller chez le dentiste.",
      "Ma soeur %%doit%% arriver bientôt.",
      "Mon ami %%doit%% prendre son vol demain.",
      "Vous %%devez%% apporter votre passeport.",
      "Elles %%doivent%% terminer leur projet.",
      "Les étudiants %%doivent%% respecter les règles.",
      "Nous %%devons%% faire du sport régulièrement.",
      "On %%doit%% préparer le dîner.",
      "Mon père et moi %%devons%% aller à la banque.",
      "Ton frère %%doit%% se comporter bien.",
      "Je %%dois%% m'entraîner tous les jours.",
      "Le chien %%doit%% faire ses besoins.",
      "Vous %%devez%% rendre le livre à la bibliothèque.",
      "Les touristes %%doivent%% visiter la tour Eiffel.",
      "Ma mère %%doit%% rencontrer le professeur.",
      "Le gouvernement %%doit%% trouver une solution.",
      "L'entreprise %%doit%% créer de nouveaux produits.",
      "Ils %%doivent%% prendre des vacances.",
      "Elle %%doit%% répondre au téléphone.",
      "Le jury %%doit%% prendre une décision.",
      "Tu %%dois%% payer les factures.",
      "Nous %%devons%% respecter l'environnement.",
      "Les employés %%doivent%% arriver à l'heure.",
      "Mes collègues %%doivent%% assister à la réunion.",
      "Vous %%devez%% acheter des billets.",
      "Les professeurs %%doivent%% corriger les examens.",
      "Il %%doit%% lire le livre avant le cours.",
      "Je %%dois%% terminer cette tâche aujourd'hui.",
    ],
    imparfait: [
      "Je %%devais%% souvent visiter mes grands-parents.",
      "Tu %%devais%% terminer tes devoirs avant de jouer.",
      "Il %%devait%% prendre le bus chaque matin.",
      "Nous %%devions%% respecter le couvre-feu.",
      "Ma famille %%devait%% manger ensemble tous les soirs.",
      "Vous %%deviez%% vous entraîner pour le tournoi.",
      "Ils %%devaient%% assister à des cours de musique.",
      "Le mécanicien %%devait%% vérifier la voiture.",
      "La police %%devait%% patrouiller la région.",
      "Mon père %%devait%% me chercher à l'école.",
      "Les enfants %%devaient%% faire une sieste l'après-midi.",
      "On %%devait%% se laver les mains avant de manger.",
      "Elles %%devaient%% se rendre à la bibliothèque.",
      "Le professeur %%devait%% corriger les copies.",
      "Mon chien %%devait%% avoir une promenade tous les jours.",
      "Je %%devais%% passer l'aspirateur chaque semaine.",
      "Le jardinier %%devait%% tondre la pelouse.",
      "Vous %%deviez%% choisir un représentant pour la classe.",
      "Les athlètes %%devaient%% s'échauffer avant le match.",
      "Mes amis et moi %%devions%% planifier les vacances.",
      "L'équipe %%devait%% se préparer pour le grand événement.",
      "Elle %%devait%% prendre des notes pendant le cours.",
      "Ma mère %%devait%% me lire une histoire chaque nuit.",
      "Le chef %%devait%% préparer le plat du jour.",
      "Nous %%devions%% rendre nos travaux à temps.",
      "Les voyageurs %%devaient%% acheter des tickets.",
      "La foule %%devait%% évacuer le stade.",
      "Je %%devais%% prendre des cours de natation.",
      "Les employés %%devaient%% pointer en arrivant.",
      "Vous %%deviez%% éteindre les lumières en sortant.",
    ],
    futurSimple: [
      "Je %%devrai%% prendre des vacances l'année prochaine.",
      "Tu %%devras%% appeler le médecin demain.",
      "Elle %%devra%% soumettre le rapport avant vendredi.",
      "Nous %%devrons%% augmenter les ventes ce trimestre.",
      "Le conseil d'administration %%devra%% prendre une décision.",
      "Vous %%devrez%% apporter des chaussures de randonnée.",
      "Ils %%devront%% réviser pour les examens finaux.",
      "Ma soeur %%devra%% déménager en juillet.",
      "Les enfants %%devront%% se coucher tôt ce soir.",
      "On %%devra%% économiser de l'énergie.",
      "Le musicien %%devra%% composer une nouvelle chanson.",
      "Mes collègues %%devront%% participer à la conférence.",
      "Tu %%devras%% nourrir le chat pendant mon absence.",
      "Le chauffeur %%devra%% attendre devant l'hôtel.",
      "Vous %%devrez%% vous inscrire avant le 15 octobre.",
      "Le public %%devra%% quitter le théâtre à 22 heures.",
      "Mon père et moi %%devrons%% aller à la pêche ce weekend.",
      "Elles %%devront%% passer des entretiens d'embauche.",
      "Je %%devrai%% me concentrer sur mes études.",
      "L'équipe %%devra%% gagner le prochain match.",
      "Les bénévoles %%devront%% organiser l'événement.",
      "Le gardien %%devra%% fermer les portes à minuit.",
      "Ma mère %%devra%% prendre rendez-vous avec le docteur.",
      "L'artiste %%devra%% finir sa toile pour l'exposition.",
      "Nous %%devrons%% vérifier notre courrier électronique.",
      "Le professeur %%devra%% publier les notes.",
      "Tu %%devras%% acheter un billet de train.",
      "Les résidents %%devront%% trier leurs déchets.",
      "Les citoyens %%devront%% voter aux élections.",
      "Vous %%devrez%% renouveler votre abonnement.",
    ],
  },
};

export default devoir;