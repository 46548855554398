import React from "react";

interface SubmitButtonProps {
  onSubmit: (userAnswers: string[]) => void;
  userAnswers: string[];
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  onSubmit,
  userAnswers,
}) => {
  return (
    <button className="btn btn-primary" onClick={() => onSubmit(userAnswers)}>
      Verifier
    </button>
  );
};

export default SubmitButton;
