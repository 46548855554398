const mangerConjugations = {
  present: [
    "je %%mange%%",
    "tu %%manges%%",
    "il/elle %%mange%%",
    "nous %%mangeons%%",
    "vous %%mangez%%",
    "ils/elles %%mangent%%",
  ],
  passeCompose: [
    "j'%%ai mangé%%",
    "tu %%as mangé%%",
    "il/elle %%a mangé%%",
    "nous %%avons mangé%%",
    "vous %%avez mangé%%",
    "ils/elles %%ont mangé%%",
  ],
  imparfait: [
    "je %%mangeais%%",
    "tu %%mangeais%%",
    "il/elle %%mangeait%%",
    "nous %%mangions%%",
    "vous %%mangiez%%",
    "ils/elles %%mangeaient%%",
  ],
  plusQueParfait: [
    "j'%%avais mangé%%",
    "tu %%avais mangé%%",
    "il/elle %%avait mangé%%",
    "nous %%avions mangé%%",
    "vous %%aviez mangé%%",
    "ils/elles %%avaient mangé%%",
  ],
  passeSimple: [
    "je %%mangeai%%",
    "tu %%mangeas%%",
    "il/elle %%mangea%%",
    "nous %%mangeâmes%%",
    "vous %%mangeâtes%%",
    "ils/elles %%mangèrent%%",
  ],
  passeAnterieur: [
    "j'%%eus mangé%%",
    "tu %%eus mangé%%",
    "il/elle %%eut mangé%%",
    "nous %%eûmes mangé%%",
    "vous %%eûtes mangé%%",
    "ils/elles %%eurent mangé%%",
  ],
  futurSimple: [
    "je %%mangerai%%",
    "tu %%mangeras%%",
    "il/elle %%mangera%%",
    "nous %%mangerons%%",
    "vous %%mangerez%%",
    "ils/elles %%mangeront%%",
  ],
  futurAnterieur: [
    "j'%%aurai mangé%%",
    "tu %%auras mangé%%",
    "il/elle %%aura mangé%%",
    "nous %%aurons mangé%%",
    "vous %%aurez mangé%%",
    "ils/elles %%auront mangé%%",
  ],
  subjonctif_present: [
    "que je %%mange%%",
    "que tu %%manges%%",
    "qu'il/elle %%mange%%",
    "que nous %%mangions%%",
    "que vous %%mangiez%%",
    "qu'ils/elles %%mangent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie mangé%%",
    "que tu %%aies mangé%%",
    "qu'il/elle %%ait mangé%%",
    "que nous %%ayons mangé%%",
    "que vous %%ayez mangé%%",
    "qu'ils/elles %%aient mangé%%",
  ],
  subjonctif_imparfait: [
    "que je %%mangeasse%%",
    "que tu %%mangeasses%%",
    "qu'il/elle %%mangeât%%",
    "que nous %%mangeassions%%",
    "que vous %%mangeassiez%%",
    "qu'ils/elles %%mangeassent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse mangé%%",
    "que tu %%eusses mangé%%",
    "qu'il/elle %%eût mangé%%",
    "que nous %%eussions mangé%%",
    "que vous %%eussiez mangé%%",
    "qu'ils/elles %%eussent mangé%%",
  ],
  conditionnel_present: [
    "je %%mangerais%%",
    "tu %%mangerais%%",
    "il/elle %%mangerait%%",
    "nous %%mangerions%%",
    "vous %%mangeriez%%",
    "ils/elles %%mangeraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais mangé%%",
    "tu %%aurais mangé%%",
    "il/elle %%aurait mangé%%",
    "nous %%aurions mangé%%",
    "vous %%auriez mangé%%",
    "ils/elles %%auraient mangé%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais mangé%%",
    "tu %%aurais mangé%%",
    "il/elle %%aurait mangé%%",
    "nous %%aurions mangé%%",
    "vous %%auriez mangé%%",
    "ils/elles %%auraient mangé%%",
  ],
};
export default mangerConjugations;
