const finirConjugations = {
  present: [
    "je %%finis%%",
    "tu %%finis%%",
    "il/elle %%finit%%",
    "nous %%finissons%%",
    "vous %%finissez%%",
    "ils/elles %%finissent%%",
  ],
  passeCompose: [
    "j'%%ai fini%%",
    "tu %%as fini%%",
    "il/elle %%a fini%%",
    "nous %%avons fini%%",
    "vous %%avez fini%%",
    "ils/elles %%ont fini%%",
  ],
  imparfait: [
    "je %%finissais%%",
    "tu %%finissais%%",
    "il/elle %%finissait%%",
    "nous %%finissions%%",
    "vous %%finissiez%%",
    "ils/elles %%finissaient%%",
  ],
  plusQueParfait: [
    "j'%%avais fini%%",
    "tu %%avais fini%%",
    "il/elle %%avait fini%%",
    "nous %%avions fini%%",
    "vous %%aviez fini%%",
    "ils/elles %%avaient fini%%",
  ],
  passeSimple: [
    "je %%finis%%",
    "tu %%finis%%",
    "il/elle %%finit%%",
    "nous %%finîmes%%",
    "vous %%finîtes%%",
    "ils/elles %%finirent%%",
  ],
  passeAnterieur: [
    "j'%%eus fini%%",
    "tu %%eus fini%%",
    "il/elle %%eut fini%%",
    "nous %%eûmes fini%%",
    "vous %%eûtes fini%%",
    "ils/elles %%eurent fini%%",
  ],
  futurSimple: [
    "je %%finirai%%",
    "tu %%finiras%%",
    "il/elle %%finira%%",
    "nous %%finirons%%",
    "vous %%finirez%%",
    "ils/elles %%finiront%%",
  ],
  futurAnterieur: [
    "j'%%aurai fini%%",
    "tu %%auras fini%%",
    "il/elle %%aura fini%%",
    "nous %%aurons fini%%",
    "vous %%aurez fini%%",
    "ils/elles %%auront fini%%",
  ],
  subjonctif_present: [
    "que je %%finisse%%",
    "que tu %%finisses%%",
    "qu'il/elle %%finisse%%",
    "que nous %%finissions%%",
    "que vous %%finissiez%%",
    "qu'ils/elles %%finissent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie fini%%",
    "que tu %%aies fini%%",
    "qu'il/elle %%ait fini%%",
    "que nous %%ayons fini%%",
    "que vous %%ayez fini%%",
    "qu'ils/elles %%aient fini%%",
  ],
  subjonctif_imparfait: [
    "que je %%finisse%%",
    "que tu %%finisses%%",
    "qu'il/elle %%finît%%",
    "que nous %%finissions%%",
    "que vous %%finissiez%%",
    "qu'ils/elles %%finissent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse fini%%",
    "que tu %%eusses fini%%",
    "qu'il/elle %%eût fini%%",
    "que nous %%eussions fini%%",
    "que vous %%eussiez fini%%",
    "qu'ils/elles %%eussent fini%%",
  ],
  conditionnel_present: [
    "je %%finirais%%",
    "tu %%finirais%%",
    "il/elle %%finirait%%",
    "nous %%finirions%%",
    "vous %%finiriez%%",
    "ils/elles %%finiraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais fini%%",
    "tu %%aurais fini%%",
    "il/elle %%aurait fini%%",
    "nous %%aurions fini%%",
    "vous %%auriez fini%%",
    "ils/elles %%auraient fini%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais fini%%",
    "tu %%aurais fini%%",
    "il/elle %%aurait fini%%",
    "nous %%aurions fini%%",
    "vous %%auriez fini%%",
    "ils/elles %%auraient fini%%",
  ],
};

export default finirConjugations;
