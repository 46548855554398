const conjugationData = {
  present: [
    "je %%suis%%",
    "tu %%es%%",
    "il %%est%%",
    "nous %%sommes%%",
    "vous %%êtes%%",
    "ils %%sont%%",
  ],
  passeCompose: [
    "j'%%ai été%%",
    "tu %%as été%%",
    "il %%a été%%",
    "nous %%avons été%%",
    "vous %%avez été%%",
    "ils %%ont été%%",
  ],
  imparfait: [
    "j'%%étais%%",
    "tu %%étais%%",
    "il %%était%%",
    "nous %%étions%%",
    "vous %%étiez%%",
    "ils %%étaient%%",
  ],
  plusQueParfait: [
    "j'%%avais été%%",
    "tu %%avais été%%",
    "il %%avait été%%",
    "nous %%avions été%%",
    "vous %%aviez été%%",
    "ils %%avaient été%%",
  ],
  passeSimple: [
    "je %%fus%%",
    "tu %%fus%%",
    "il %%fut%%",
    "nous %%fûmes%%",
    "vous %%fûtes%%",
    "ils %%furent%%",
  ],
  passeAnterieur: [
    "j'%%eus été%%",
    "tu %%eus été%%",
    "il %%eut été%%",
    "nous %%eûmes été%%",
    "vous %%eûtes été%%",
    "ils %%eurent été%%",
  ],
  futurSimple: [
    "je %%serai%%",
    "tu %%seras%%",
    "il %%sera%%",
    "nous %%serons%%",
    "vous %%serez%%",
    "ils %%seront%%",
  ],
  futurAnterieur: [
    "j'%%aurai été%%",
    "tu %%auras été%%",
    "il %%aura été%%",
    "nous %%aurons été%%",
    "vous %%aurez été%%",
    "ils %%auront été%%",
  ],
  subjonctif_present: [
    "que je %%sois%%",
    "que tu %%sois%%",
    "qu'il %%soit%%",
    "que nous %%soyons%%",
    "que vous %%soyez%%",
    "qu'ils %%soient%%",
  ],
  subjonctif_passe: [
    "que j'%%aie été%%",
    "que tu %%aies été%%",
    "qu'il %%ait été%%",
    "que nous %%ayons été%%",
    "que vous %%ayez été%%",
    "qu'ils %%aient été%%",
  ],
  subjonctif_imparfait: [
    "que je %%fusse%%",
    "que tu %%fusses%%",
    "qu'il %%fût%%",
    "que nous %%fussions%%",
    "que vous %%fussiez%%",
    "qu'ils %%fussent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse été%%",
    "que tu %%eusses été%%",
    "qu'il %%eût été%%",
    "que nous %%eussions été%%",
    "que vous %%eussiez été%%",
    "qu'ils %%eussent été%%",
  ],
  conditionnel_present: [
    "je %%serais%%",
    "tu %%serais%%",
    "il %%serait%%",
    "nous %%serions%%",
    "vous %%seriez%%",
    "ils %%seraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais été%%",
    "tu %%aurais été%%",
    "il %%aurait été%%",
    "nous %%aurions été%%",
    "vous %%auriez été%%",
    "ils %%auraient été%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%eusse été%%",
    "tu %%eusses été%%",
    "il %%eût été%%",
    "nous %%eussions été%%",
    "vous %%eussiez été%%",
    "ils %%eussent été%%",
  ],
};

export default conjugationData;
