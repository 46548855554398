const partir: any = {
  partir: {
    present: [
      "Je %%pars%% pour une nouvelle aventure.",
      "Tu %%pars%% déjà?",
      "Il %%part%% en voyage d'affaires demain.",
      "Elle %%part%% en retraite à la fin de l'année.",
      "Nous %%partons%% à la campagne ce week-end.",
      "Vous %%partez%% en vacances quand?",
      "Ils %%partent%% pour une mission humanitaire.",
      "Mes parents %%partent%% à Hawaii pour leur anniversaire.",
      "Les étudiants %%partent%% pour une excursion éducative.",
      "Ma sœur et son mari %%partent%% en lune de miel.",
      "Les oiseaux migrateurs %%partent%% vers le sud.",
      "Le train %%part%% dans cinq minutes.",
      "Les marathoniens %%partent%% du point de départ.",
      "La fusée %%part%% pour Mars.",
      "Le soleil %%part%% derrière les montagnes.",
      "Les pêcheurs %%partent%% en mer tous les matins.",
      "Les enfants %%partent%% à l'école en bus.",
      "Les invités %%partent%% un par un.",
      "Le vent %%part%% du nord-est.",
      "Les musiciens %%partent%% en tournée.",
      "La foule %%part%% après le concert.",
      "La pluie %%part%% aussi vite qu'elle est venue.",
      "Les jeunes %%partent%% pour le festival de musique.",
      "Les acteurs %%partent%% pour les répétitions.",
      "Le navire %%part%% pour une nouvelle expédition.",
      "La lumière %%part%% à travers le prisme.",
      "Les cyclistes %%partent%% pour une longue course.",
      "Les artistes %%partent%% pour une résidence à l'étranger.",
      "Le film %%part%% sur une note dramatique.",
      "Les nouvelles tendances %%partent%% de la mode parisienne.",
    ],
    imparfait: [
      "Je %%partais%% souvent à la pêche avec mon grand-père.",
      "Tu %%partais%% toujours sans dire au revoir.",
      "Il %%partait%% en randonnée chaque été.",
      "Elle %%partait%% à l'école en vélo.",
      "Nous %%partions%% en voyage toutes les vacances de Noël.",
      "Vous %%partiez%% en excursion tous les dimanches.",
      "Ils %%partaient%% pour des missions humanitaires chaque année.",
      "Les oiseaux %%partaient%% vers le sud chaque automne.",
      "Mes amis et moi %%partions%% pour des aventures chaque week-end.",
      "Ma mère et mon père %%partaient%% en voyage de noces à Paris.",
      "Les étudiants %%partaient%% pour une pause à midi.",
      "Le soleil %%partait%% derrière les nuages en fin de journée.",
      "Le vent %%partait%% du nord tous les matins.",
      "Les pêcheurs %%partaient%% en mer à l'aube.",
      "La pluie %%partait%% après quelques minutes généralement.",
      "Les enfants %%partaient%% chercher des œufs à Pâques.",
      "Les artistes %%partaient%% pour des tournées internationales.",
      "Les soldats %%partaient%% pour le front pendant la guerre.",
      "La foule %%partait%% dans différentes directions après le feu d'artifice.",
      "Les bateaux %%partaient%% au large pour éviter la tempête.",
      "La lumière %%partait%% à travers les vitraux colorés.",
      "Les cyclistes %%partaient%% pour des compétitions tous les mois.",
      "Les fermiers %%partaient%% pour le marché tôt le matin.",
      "La fumée %%partait%% de la cheminée.",
      "Les idées innovantes %%partaient%% souvent d'un petit groupe de personnes.",
      "Les romanciers %%partaient%% pour des retraites d'écriture.",
      "Le courrier %%partait%% à 3 heures de l'après-midi.",
      "Les vagues %%partaient%% du large.",
      "Les voitures %%partaient%% du parking après le match.",
      "Les bénévoles %%partaient%% pour nettoyer la plage chaque semaine.",
    ],
    futurSimple: [
      "Je %%partirai%% pour la lune un jour.",
      "Tu %%partiras%% pour l'université l'année prochaine.",
      "Il %%partira%% en retraite dans quelques mois.",
      "Elle %%partira%% en voyage pour son anniversaire.",
      "Nous %%partirons%% pour un tour du monde quand nous serons plus âgés.",
      "Vous %%partirez%% pour une nouvelle mission demain.",
      "Ils %%partiront%% en croisière l'été prochain.",
      "Mon ami %%partira%% pour l'Antarctique pour une recherche scientifique.",
      "Les oiseaux %%partiront%% vers le nord au printemps.",
      "Mes collègues et moi %%partirons%% pour une conférence à Tokyo.",
      "La famille Johnson %%partira%% pour un safari en Afrique.",
      "La lumière %%partira%% dans toutes les directions quand le prisme sera cassé.",
      "Les aventuriers %%partiront%% à la recherche du trésor perdu.",
      "Le train %%partira%% à l'heure, malgré la grève.",
      "Le vol %%partira%% tôt demain matin.",
      "Les pêcheurs %%partiront%% pour une nouvelle saison de pêche.",
      "Les étudiants en art %%partiront%% pour visiter des musées en Europe.",
      "Le cirque %%partira%% pour une nouvelle ville après la dernière représentation.",
      "Le bateau %%partira%% pour une nouvelle expédition de plongée sous-marine.",
      "Le soleil %%partira%% derrière les montagnes et laissera place aux étoiles.",
      "Les musiciens %%partiront%% en tournée à l'étranger.",
      "Les acteurs %%partiront%% pour les répétitions de la nouvelle pièce de théâtre.",
      "Les touristes %%partiront%% pour une journée de visites.",
      "Les campeurs %%partiront%% pour un week-end en forêt.",
      "Les astronautes %%partiront%% pour une mission sur Mars.",
      "Les chiens de traîneau %%partiront%% pour une course en Alaska.",
      "Les écrivains %%partiront%% pour une retraite d'écriture en montagne.",
      "Le bruit %%partira%% quand le concert sera terminé.",
      "Les ingénieurs %%partiront%% pour une formation avancée.",
      "Les enfants %%partiront%% pour un camp d'été en juillet.",
    ],
  },
};

export default partir;
