const manger: any = {
  manger: {
    present: [
      "Je %%mange%% une pomme pour le petit-déjeuner.",
      "Tu %%manges%% des légumes pour rester en bonne santé.",
      "Il %%mange%% du chocolat même s'il sait que ce n'est pas bon pour lui.",
      "Elle %%mange%% souvent du poisson pour les oméga-3.",
      "Nous %%mangeons%% des pâtes tous les mardis.",
      "Vous %%mangez%% toujours des plats épicés, n'est-ce pas?",
      "Ils %%mangent%% de la viande chaque dimanche.",
      "Les enfants %%mangent%% des bonbons après l'école.",
      "Ma sœur et moi %%mangeons%% des fruits secs pendant la randonnée.",
      "Le chat %%mange%% de la pâtée tous les jours.",
      "Les oiseaux %%mangent%% des graines dans le jardin.",
      "Mon ami %%mange%% du fromage comme dessert.",
      "Les touristes %%mangent%% des spécialités locales.",
      "Le professeur %%mange%% un sandwich pendant la pause déjeuner.",
      "Les étudiants %%mangent%% dans la cafétéria.",
      "Le chien %%mange%% des croquettes pour son repas.",
      "Les athlètes %%mangent%% beaucoup de protéines.",
      "Ma mère %%mange%% toujours une salade pour le déjeuner.",
      "Le boulanger %%mange%% du pain frais chaque matin.",
      "Les spectateurs %%mangent%% du popcorn pendant le film.",
      "Les jardiniers %%mangent%% des légumes de leur propre jardin.",
      "Le bébé %%mange%% de la purée de carottes.",
      "Les voyageurs %%mangent%% dans les restaurants sur la route.",
      "Les végétariens %%mangent%% des alternatives à la viande.",
      "Le jury %%mange%% pendant la délibération.",
      "Les voisins %%mangent%% ensemble chaque vendredi.",
      "Le fermier %%mange%% les produits de sa ferme.",
      "Les collègues %%mangent%% dans la salle de pause.",
      "Le photographe %%mange%% rapidement entre deux séances photo.",
      "Le chef %%mange%% ses créations pour les tester.",
    ],
    imparfait: [
      "Je %%mangeais%% des céréales chaque matin quand j'étais petit.",
      "Tu %%mangeais%% souvent chez ta grand-mère, n'est-ce pas?",
      "Il %%mangeait%% des pommes pour garder la forme.",
      "Elle %%mangeait%% du tofu avant de devenir végétarienne.",
      "Nous %%mangions%% toujours ensemble le dimanche.",
      "Vous %%mangiez%% beaucoup de fast-food pendant vos années d'université.",
      "Ils %%mangeaient%% des sandwiches au jambon tous les jours.",
      "Mes parents %%mangeaient%% de la soupe chaque soir.",
      "Mon frère et moi %%mangions%% des bonbons en cachette.",
      "Le chat %%mangeait%% des souris quand il vivait à la campagne.",
      "Les oiseaux %%mangeaient%% des vers au printemps.",
      "Mon voisin %%mangeait%% du gâteau pour son anniversaire.",
      "Les touristes %%mangeaient%% dans les petits bistrots.",
      "Le professeur %%mangeait%% souvent seul dans sa salle de classe.",
      "Les étudiants %%mangeaient%% des ramen pour économiser.",
      "Le chien %%mangeait%% toujours à sept heures.",
      "Les athlètes %%mangeaient%% des bananes pour l'énergie.",
      "Ma tante %%mangeait%% toujours des fruits de mer en vacances.",
      "Le pêcheur %%mangeait%% le poisson qu'il attrapait.",
      "Les enfants %%mangeaient%% des glaces l'été.",
      "Les jardiniers %%mangeaient%% des carottes qu'ils cultivaient.",
      "Le bébé %%mangeait%% souvent des purées.",
      "Les soldats %%mangeaient%% des rations en mission.",
      "Les végétaliens %%mangeaient%% uniquement des plantes.",
      "Le musicien %%mangeait%% avant ses concerts.",
      "Les voisins %%mangeaient%% des barbecues en été.",
      "Le fermier %%mangeait%% un œuf au petit-déjeuner.",
      "Les collègues %%mangeaient%% des pizzas les vendredis.",
      "Le photographe %%mangeait%% des en-cas pendant les tournages.",
      "Le chef %%mangeait%% rarement ce qu'il cuisinait.",
    ],
    futurSimple: [
      "Je %%mangerai%% des sushis pour mon anniversaire.",
      "Tu %%mangeras%% plus de légumes cette année, j'espère.",
      "Il %%mangera%% ce qu'il y a dans le frigo quand il rentrera.",
      "Elle %%mangera%% bio pour aider l'environnement.",
      "Nous %%mangerons%% à l'extérieur si le temps le permet.",
      "Vous %%mangerez%% tous les restes, n'est-ce pas?",
      "Ils %%mangeront%% du gâteau pour célébrer leur succès.",
      "Les enfants %%mangeront%% leurs légumes s'ils veulent du dessert.",
      "Mon ami et moi %%mangerons%% dans ce nouveau restaurant vendredi.",
      "Le chat %%mangera%% ses croquettes comme d'habitude.",
      "Les oiseaux %%mangeront%% les graines que nous mettrons dehors.",
      "Ma sœur %%mangera%% végétalien pendant un mois.",
      "Les touristes %%mangeront%% des crêpes en France.",
      "Le professeur %%mangera%% avec ses collègues demain.",
      "Les étudiants %%mangeront%% de la nourriture de la cantine, malheureusement.",
      "Le chien %%mangera%% une friandise après son tour de magie.",
      "Les athlètes %%mangeront%% une alimentation équilibrée pendant la compétition.",
      "Ma mère %%mangera%% des fruits exotiques pendant ses vacances.",
      "Le boulanger %%mangera%% une part de sa propre tarte.",
      "Les musiciens %%mangeront%% avant le grand concert.",
      "Les jardiniers %%mangeront%% des légumes frais de leur jardin.",
      "Le bébé %%mangera%% de la nourriture solide bientôt.",
      "Les voyageurs %%mangeront%% des spécialités locales lors de leur voyage.",
      "Les végétariens %%mangeront%% des alternatives à la dinde à Thanksgiving.",
      "Le jury %%mangera%% pendant la délibération du concours culinaire.",
      "Les voisins %%mangeront%% ensemble ce samedi.",
      "Le fermier %%mangera%% un repas fait de ses propres produits.",
      "Les collègues %%mangeront%% au food truck près du bureau.",
      "Le photographe %%mangera%% rapidement entre deux séances.",
      "Le chef %%mangera%% en dernier, comme toujours.",
    ],
  },
};

export default manger;
