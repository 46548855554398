let mettreConjugations = {
  present: [
    "je %%mets%%",
    "tu %%mets%%",
    "il/elle %%met%%",
    "nous %%mettons%%",
    "vous %%mettez%%",
    "ils/elles %%mettent%%",
  ],
  passeCompose: [
    "j'%%ai mis%%",
    "tu %%as mis%%",
    "il/elle %%a mis%%",
    "nous %%avons mis%%",
    "vous %%avez mis%%",
    "ils/elles %%ont mis%%",
  ],
  imparfait: [
    "je %%mettais%%",
    "tu %%mettais%%",
    "il/elle %%mettait%%",
    "nous %%mettions%%",
    "vous %%mettiez%%",
    "ils/elles %%mettaient%%",
  ],
  plusQueParfait: [
    "j'%%avais mis%%",
    "tu %%avais mis%%",
    "il/elle %%avait mis%%",
    "nous %%avions mis%%",
    "vous %%aviez mis%%",
    "ils/elles %%avaient mis%%",
  ],
  passeSimple: [
    "je %%mis%%",
    "tu %%mis%%",
    "il/elle %%mit%%",
    "nous %%mîmes%%",
    "vous %%mîtes%%",
    "ils/elles %%mirent%%",
  ],
  passeAnterieur: [
    "j'%%eus mis%%",
    "tu %%eus mis%%",
    "il/elle %%eut mis%%",
    "nous %%eûmes mis%%",
    "vous %%eûtes mis%%",
    "ils/elles %%eurent mis%%",
  ],
  futurSimple: [
    "je %%mettrai%%",
    "tu %%mettras%%",
    "il/elle %%mettra%%",
    "nous %%mettrons%%",
    "vous %%mettrez%%",
    "ils/elles %%mettront%%",
  ],
  futurAnterieur: [
    "j'%%aurai mis%%",
    "tu %%auras mis%%",
    "il/elle %%aura mis%%",
    "nous %%aurons mis%%",
    "vous %%aurez mis%%",
    "ils/elles %%auront mis%%",
  ],
  subjonctif_present: [
    "que je %%mette%%",
    "que tu %%mettes%%",
    "qu'il/elle %%mette%%",
    "que nous %%mettions%%",
    "que vous %%mettiez%%",
    "qu'ils/elles %%mettent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie mis%%",
    "que tu %%aies mis%%",
    "qu'il/elle %%ait mis%%",
    "que nous %%ayons mis%%",
    "que vous %%ayez mis%%",
    "qu'ils/elles %%aient mis%%",
  ],
  subjonctif_imparfait: [
    "que je %%misse%%",
    "que tu %%misses%%",
    "qu'il/elle %%mît%%",
    "que nous %%missions%%",
    "que vous %%missiez%%",
    "qu'ils/elles %%missent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse mis%%",
    "que tu %%eusses mis%%",
    "qu'il/elle %%eût mis%%",
    "que nous %%eussions mis%%",
    "que vous %%eussiez mis%%",
    "qu'ils/elles %%eussent mis%%",
  ],
  conditionnel_present: [
    "je %%mettrais%%",
    "tu %%mettrais%%",
    "il/elle %%mettrait%%",
    "nous %%mettrions%%",
    "vous %%mettriez%%",
    "ils/elles %%mettraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais mis%%",
    "tu %%aurais mis%%",
    "il/elle %%aurait mis%%",
    "nous %%aurions mis%%",
    "vous %%auriez mis%%",
    "ils/elles %%auraient mis%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais mis%%",
    "tu %%aurais mis%%",
    "il/elle %%aurait mis%%",
    "nous %%aurions mis%%",
    "vous %%auriez mis%%",
    "ils/elles %%auraient mis%%",
  ],
};

export default mettreConjugations;
