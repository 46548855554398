const etre = {
  etre: {
    present: [
      "Je %%suis%% content.",
      "Tu %%es%% mon meilleur ami.",
      "Il %%est%% tard pour sortir.",
      "Elle %%est%% en vacances.",
      "On %%est%% prêts à partir.",
      "Nous %%sommes%% en train d'étudier.",
      "Vous %%êtes%% à la maison.",
      "Ils %%sont%% des étudiants.",
      "Elles %%sont%% très intelligentes.",
      "Mon chien %%est%% gentil.",
      "Mes parents %%sont%% en voyage.",
      "Mon ami et moi %%sommes%% au cinéma.",
      "Les enfants %%sont%% dans le jardin.",
      "Le ciel %%est%% bleu.",
      "Ma soeur et toi %%êtes%% dans la même classe.",
      "Le professeur %%est%% dans la salle.",
      "Ma mère %%est%% cuisinière.",
      "Mes collègues %%sont%% en réunion.",
      "Mon père et moi %%sommes%% médecins.",
      "Les pommes %%sont%% sur la table.",
      "La voiture %%est%% en panne.",
      "Le livre %%est%% sur l'étagère.",
      "Mon frère %%est%% ingénieur.",
      "Ma tante et ma cousine %%sont%% à Paris.",
      "Le café %%est%% chaud.",
      "L'école %%est%% loin.",
      "Mon ami et toi %%êtes%% de bons joueurs.",
      "Les fleurs %%sont%% belles.",
      "Le film %%est%% intéressant.",
      "La porte %%est%% ouverte.",
    ],
    passeCompose: [
      "Je %%suis arrivé(e)%% à l'heure.",
      "Tu %%es parti(e)%% tôt.",
      "Il %%est monté%% à l'étage.",
      "Elle %%est descendue%% de la voiture.",
      "On %%est sorti(e)s%% pour se promener.",
      "Nous %%sommes allé(e)s%% au cinéma.",
      "Vous %%êtes rentré(e)(s)%% tard.",
      "Ils %%sont restés%% à la maison.",
      "Elles %%sont venues%% nous voir.",
      "Mon ami %%est venu%% me chercher.",
      "Ma mère %%est tombée%% dans les escaliers.",
      "Mon frère et moi %%sommes revenus%% de vacances.",
      "Mes collègues %%sont partis%% en déplacement.",
      "Ma cousine %%est née%% la semaine dernière.",
      "Le train %%est arrivé%% en retard.",
      "Le chat %%est monté%% sur l'arbre.",
      "Les invités %%sont partis%% après le dîner.",
      "Le soleil %%est sorti%% après la pluie.",
      "Mes amis et toi %%êtes allés%% à la plage.",
      "Les enfants %%sont rentrés%% de l'école.",
      "Les fleurs %%sont tombées%% du vase.",
      "Le film %%est fini%%.",
      "La porte %%est restée%% ouverte.",
      "La réunion %%est terminée%%.",
      "Mon ami et moi %%sommes venus%% en retard.",
      "Les oiseaux %%sont partis%% vers le sud.",
      "Le cours %%est commencé%% à huit heures.",
      "La voiture %%est tombée%% en panne.",
      "La conférence %%est finie%%.",
      "Le match %%est terminé%%.",
    ],
    imparfait: [
      "J'%%étais%% jeune à l'époque.",
      "Tu %%étais%% mon meilleur ami.",
      "Il %%était%% tard quand il est arrivé.",
      "Elle %%était%% en vacances.",
      "On %%était%% prêts à partir.",
      "Nous %%étions%% en train d'étudier.",
      "Vous %%étiez%% à la maison.",
      "Ils %%étaient%% des étudiants.",
      "Elles %%étaient%% très intelligentes.",
      "Mon chien %%était%% gentil.",
      "Mes parents %%étaient%% en voyage.",
      "Mon ami et moi %%étions%% au cinéma.",
      "Les enfants %%étaient%% dans le jardin.",
      "Le ciel %%était%% bleu.",
      "Ma soeur et toi %%étiez%% dans la même classe.",
      "Le professeur %%était%% dans la salle.",
      "Ma mère %%était%% cuisinière.",
      "Mes collègues %%étaient%% en réunion.",
      "Mon père et moi %%étions%% médecins.",
      "Les pommes %%étaient%% sur la table.",
      "La voiture %%était%% en panne.",
      "Le livre %%était%% sur l'étagère.",
      "Mon frère %%était%% ingénieur.",
      "Ma tante et ma cousine %%étaient%% à Paris.",
      "Le café %%était%% chaud.",
      "L'école %%était%% loin.",
      "Mon ami et toi %%étiez%% de bons joueurs.",
      "Les fleurs %%étaient%% belles.",
      "Le film %%était%% intéressant.",
      "La porte %%était%% ouverte.",
    ],
    plusQueParfait: [
      "J'%%étais arrivé(e)%% à l'heure.",
      "Tu %%étais parti(e)%% tôt.",
      "Il %%était monté%% à l'étage.",
      "Elle %%était descendue%% de la voiture.",
      "On %%était sorti(e)s%% pour se promener.",
      "Nous %%étions allé(e)s%% au cinéma.",
      "Vous %%étiez rentré(e)(s)%% tard.",
      "Ils %%étaient restés%% à la maison.",
      "Elles %%étaient venues%% nous voir.",
      "Mon ami %%était venu%% me chercher.",
      "Ma mère %%était tombée%% dans les escaliers.",
      "Mon frère et moi %%étions revenus%% de vacances.",
      "Mes collègues %%étaient partis%% en déplacement.",
      "Ma cousine %%était née%% la semaine dernière.",
      "Le train %%était arrivé%% en retard.",
      "Le chat %%était monté%% sur l'arbre.",
      "Les invités %%étaient partis%% après le dîner.",
      "Le soleil %%était sorti%% après la pluie.",
      "Mes amis et toi %%étiez allés%% à la plage.",
      "Les enfants %%étaient rentrés%% de l'école.",
      "Les fleurs %%étaient tombées%% du vase.",
      "Le film %%était fini%%.",
      "La porte %%était restée%% ouverte.",
      "La réunion %%était terminée%%.",
      "Mon ami et moi %%étions venus%% en retard.",
      "Les oiseaux %%étaient partis%% vers le sud.",
      "Le cours %%était commencé%% à huit heures.",
      "La voiture %%était tombée%% en panne.",
      "La conférence %%était finie%%.",
      "Le match %%était terminé%%.",
    ],
    passeSimple: [
      "Je %%fus%% surpris par la nouvelle.",
      "Tu %%fus%% le premier à arriver.",
      "Il %%fut%% un grand scientifique.",
      "Elle %%fut%% ma meilleure amie.",
      "On %%fut%% heureux de le voir.",
      "Nous %%fûmes%% les derniers à partir.",
      "Vous %%fûtes%% très généreux.",
      "Ils %%furent%% les champions du tournoi.",
      "Elles %%furent%% les étoiles de la soirée.",
      "Mon chien %%fut%% très fidèle.",
      "Mes parents %%furent%% très compréhensifs.",
      "Mon ami et moi %%fûmes%% inséparables.",
      "Les enfants %%furent%% très sages.",
      "Le ciel %%fut%% clair ce jour-là.",
      "Ma soeur et toi %%fûtes%% toujours en compétition.",
      "Le professeur %%fut%% très strict.",
      "Ma mère %%fut%% une grande cuisinière.",
      "Mes collègues %%furent%% très coopératifs.",
      "Mon père et moi %%fûmes%% de grands fans de football.",
      "Les pommes %%furent%% très délicieuses.",
      "La voiture %%fut%% très coûteuse.",
      "Le livre %%fut%% un best-seller.",
      "Mon frère %%fut%% très travailleur.",
      "Ma tante et ma cousine %%furent%% très proches.",
      "Le café %%fut%% très fort.",
      "L'école %%fut%% mon deuxième chez moi.",
      "Mon ami et toi %%fûtes%% de grands athlètes.",
      "Les fleurs %%furent%% magnifiques.",
      "Le film %%fut%% un grand succès.",
      "La porte %%fut%% très difficile à ouvrir.",
    ],
    passeAnterieur: [
      "J'%%eus été%% déçu par la nouvelle.",
      "Tu %%eus été%% la première à arriver.",
      "Il %%eut été%% un grand scientifique.",
      "Elle %%eut été%% ma meilleure amie.",
      "On %%eut été%% heureux de le voir.",
      "Nous %%eûmes été%% les derniers à partir.",
      "Vous %%eûtes été%% très généreux.",
      "Ils %%eurent été%% les champions du tournoi.",
      "Elles %%eurent été%% les étoiles de la soirée.",
      "Mon chien %%eut été%% très fidèle.",
      "Mes parents %%eurent été%% très compréhensifs.",
      "Mon ami et moi %%eûmes été%% inséparables.",
      "Les enfants %%eurent été%% très sages.",
      "Le ciel %%eut été%% clair ce jour-là.",
      "Ma soeur et toi %%eûtes été%% toujours en compétition.",
      "Le professeur %%eut été%% très strict.",
      "Ma mère %%eut été%% une grande cuisinière.",
      "Mes collègues %%eurent été%% très coopératifs.",
      "Mon père et moi %%eûmes été%% de grands fans de football.",
      "Les pommes %%eurent été%% très délicieuses.",
      "La voiture %%eut été%% très coûteuse.",
      "Le livre %%eut été%% un best-seller.",
      "Mon frère %%eut été%% très travailleur.",
      "Ma tante et ma cousine %%eurent été%% très proches.",
      "Le café %%eut été%% très fort.",
      "L'école %%eut été%% mon deuxième chez moi.",
      "Mon ami et toi %%eûtes été%% de grands athlètes.",
      "Les fleurs %%eurent été%% magnifiques.",
      "Le film %%eut été%% un grand succès.",
      "La porte %%eut été%% très difficile à ouvrir.",
    ],
    futurSimple: [
      "Je %%serai%% à la maison.",
      "Tu %%seras%% en retard.",
      "Il %%sera%% le meilleur.",
      "Elle %%sera%% ici demain.",
      "On %%sera%% ensemble.",
      "Nous %%serons%% en vacances.",
      "Vous %%serez%% les bienvenus.",
      "Ils %%seront%% à la fête.",
      "Elles %%seront%% surprises.",
      "Mon chien %%sera%% vieux.",
      "Mes parents %%seront%% à l'étranger.",
      "Mon ami et moi %%serons%% au cinéma.",
      "Les enfants %%seront%% à l'école.",
      "Le ciel %%sera%% bleu.",
      "Ma soeur et toi %%serez%% à la bibliothèque.",
      "Le professeur %%sera%% absent.",
      "Ma mère %%sera%% à la maison.",
      "Mes collègues %%seront%% en réunion.",
      "Mon père et moi %%serons%% à la pêche.",
      "Les pommes %%seront%% mûres.",
      "La voiture %%sera%% réparée.",
      "Le livre %%sera%% publié.",
      "Mon frère %%sera%% marié.",
      "Ma tante et ma cousine %%seront%% à Paris.",
      "Le café %%sera%% ouvert.",
      "L'école %%sera%% fermée.",
      "Mon ami et toi %%serez%% au concert.",
      "Les fleurs %%seront%% magnifiques.",
      "Le film %%sera%% en salle.",
      "La porte %%sera%% fermée.",
    ],
    futurAnterieur: [
      "J'%%aurai été%% déçu si tu ne viens pas.",
      "Tu %%auras été%% le premier à arriver.",
      "Il %%aura été%% un grand scientifique.",
      "Elle %%aura été%% ma meilleure amie.",
      "On %%aura été%% heureux de le voir.",
      "Nous %%aurons été%% les derniers à partir.",
      "Vous %%aurez été%% très généreux.",
      "Ils %%auront été%% les champions du tournoi.",
      "Elles %%auront été%% les étoiles de la soirée.",
      "Mon chien %%aura été%% très fidèle.",
      "Mes parents %%auront été%% très compréhensifs.",
      "Mon ami et moi %%aurons été%% inséparables.",
      "Les enfants %%auront été%% très sages.",
      "Le ciel %%aura été%% clair ce jour-là.",
      "Ma soeur et toi %%aurez été%% toujours en compétition.",
      "Le professeur %%aura été%% très strict.",
      "Ma mère %%aura été%% une grande cuisinière.",
      "Mes collègues %%auront été%% très coopératifs.",
      "Mon père et moi %%aurons été%% de grands fans de football.",
      "Les pommes %%auront été%% très délicieuses.",
      "La voiture %%aura été%% très coûteuse.",
      "Le livre %%aura été%% un best-seller.",
      "Mon frère %%aura été%% très travailleur.",
      "Ma tante et ma cousine %%auront été%% très proches.",
      "Le café %%aura été%% très fort.",
      "L'école %%aura été%% mon deuxième chez moi.",
      "Mon ami et toi %%aurez été%% de grands athlètes.",
      "Les fleurs %%auront été%% magnifiques.",
      "Le film %%aura été%% un grand succès.",
      "La porte %%aura été%% très difficile à ouvrir.",
    ],
  },
};

export default etre;