// src/pages/ExercisePage.tsx
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";

import ExerciseArea from "../components/ExerciseArea";
import SubmitButton from "../components/SubmitButton";
import { getRandomElements } from "../data/helpers";
import ExerciseDetails from "../components/ExerciseDetails";
import ReturnButton from "../components/common/ReturnButton";
import verbs from "../data/conjugation/verbs";

const ExercisePage: React.FC = () => {
  const numberOfQuestions = 5;
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [feedback, setFeedback] = useState<boolean[] | null>(null);
  const [phraseList, setPhraseList] = useState<string[]>([]);

  const { verb, tense } = useParams();

  useEffect(() => {
    if (verb && tense) {
      setPhraseList(getRandomElements(verbs[verb][tense], numberOfQuestions));
    }
  }, [verb, tense]);

  const handleSubmit = () => {
    if (!verb || !tense || !verbs[verb] || !verbs[verb][tense]) return;
    const correctConjugations = phraseList.map(
      (sentence: string) =>
        sentence.split("%%")[1].trim().toLocaleLowerCase() || ""
    );
    const newFeedback = userAnswers.map(
      (answer, index) =>
        answer.trim().toLocaleLowerCase() === correctConjugations[index]
    );
    ReactGA.event({
      category: "Exercise",
      action: "Verify",
    });

    setFeedback(newFeedback);
  };

  const navigate = useNavigate();

  const handleNext = () => {
    ReactGA.event({
      category: "Exercise",
      action: "Next",
    });
    navigate(0);
  };

  return (
    <>
      <div className="container">
        <ExerciseDetails tense={tense} verb={verb} />
        {verb && tense && (
          <ExerciseArea
            verb={verb}
            tense={tense}
            phraseList={phraseList}
            onUserAnswersChange={setUserAnswers}
            feedback={feedback}
          />
        )}
        <div className="row mt-4">
          <div className="col text-center">
            <ReturnButton />
            <SubmitButton onSubmit={handleSubmit} userAnswers={userAnswers} />
            <button type="button" className="btn" onClick={handleNext}>
              Suivant
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExercisePage;
