import React from "react";

interface ExerciseDetailsProps {
  tense?: string;
  verb?: string;
}

const ExerciseDetails: React.FC<ExerciseDetailsProps> = ({ tense, verb }) => {
  return (
    <>
      {verb && tense && (
        <div className="row">
          <div className="col">
            <h3 className="text-center">
              Conjugues le verbe{" "}
              <span className="capitalize badge text-bg-primary">{verb}</span> au{" "}
              <span className="capitalize badge text-bg-primary">{tense}</span>
            </h3>
          </div>
        </div>
      )}
    </>
  );
};

export default ExerciseDetails;
