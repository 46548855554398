let ouvrirConjugations = {
  present: [
    "j'%%ouvre%%",
    "tu %%ouvres%%",
    "il/elle %%ouvre%%",
    "nous %%ouvrons%%",
    "vous %%ouvrez%%",
    "ils/elles %%ouvrent%%",
  ],
  passeCompose: [
    "j'%%ai ouvert%%",
    "tu %%as ouvert%%",
    "il/elle %%a ouvert%%",
    "nous %%avons ouvert%%",
    "vous %%avez ouvert%%",
    "ils/elles %%ont ouvert%%",
  ],
  imparfait: [
    "j'%%ouvrais%%",
    "tu %%ouvrais%%",
    "il/elle %%ouvrait%%",
    "nous %%ouvrions%%",
    "vous %%ouvriez%%",
    "ils/elles %%ouvraient%%",
  ],
  plusQueParfait: [
    "j'%%avais ouvert%%",
    "tu %%avais ouvert%%",
    "il/elle %%avait ouvert%%",
    "nous %%avions ouvert%%",
    "vous %%aviez ouvert%%",
    "ils/elles %%avaient ouvert%%",
  ],
  passeSimple: [
    "j'%%ouvris%%",
    "tu %%ouvris%%",
    "il/elle %%ouvrit%%",
    "nous %%ouvrîmes%%",
    "vous %%ouvrîtes%%",
    "ils/elles %%ouvrirent%%",
  ],
  passeAnterieur: [
    "j'%%eus ouvert%%",
    "tu %%eus ouvert%%",
    "il/elle %%eut ouvert%%",
    "nous %%eûmes ouvert%%",
    "vous %%eûtes ouvert%%",
    "ils/elles %%eurent ouvert%%",
  ],
  futurSimple: [
    "j'%%ouvrirai%%",
    "tu %%ouvriras%%",
    "il/elle %%ouvrira%%",
    "nous %%ouvrirons%%",
    "vous %%ouvrirez%%",
    "ils/elles %%ouvriront%%",
  ],
  futurAnterieur: [
    "j'%%aurai ouvert%%",
    "tu %%auras ouvert%%",
    "il/elle %%aura ouvert%%",
    "nous %%aurons ouvert%%",
    "vous %%aurez ouvert%%",
    "ils/elles %%auront ouvert%%",
  ],
  subjonctif_present: [
    "que j'%%ouvre%%",
    "que tu %%ouvres%%",
    "qu'il/elle %%ouvre%%",
    "que nous %%ouvrions%%",
    "que vous %%ouvriez%%",
    "qu'ils/elles %%ouvrent%%",
  ],
  subjonctif_passe: [
    "que j'%%aie ouvert%%",
    "que tu %%aies ouvert%%",
    "qu'il/elle %%ait ouvert%%",
    "que nous %%ayons ouvert%%",
    "que vous %%ayez ouvert%%",
    "qu'ils/elles %%aient ouvert%%",
  ],
  subjonctif_imparfait: [
    "que j'%%ouvre%%",
    "que tu %%ouvres%%",
    "qu'il/elle %%ouvrît%%",
    "que nous %%ouvrions%%",
    "que vous %%ouvriez%%",
    "qu'ils/elles %%ouvrissent%%",
  ],
  subjonctif_plusQueParfait: [
    "que j'%%eusse ouvert%%",
    "que tu %%eusses ouvert%%",
    "qu'il/elle %%eût ouvert%%",
    "que nous %%eussions ouvert%%",
    "que vous %%eussiez ouvert%%",
    "qu'ils/elles %%eussent ouvert%%",
  ],
  conditionnel_present: [
    "j'%%ouvrirais%%",
    "tu %%ouvrirais%%",
    "il/elle %%ouvrirait%%",
    "nous %%ouvririons%%",
    "vous %%ouvririez%%",
    "ils/elles %%ouvriraient%%",
  ],
  conditionnel_passePremiereForme: [
    "j'%%aurais ouvert%%",
    "tu %%aurais ouvert%%",
    "il/elle %%aurait ouvert%%",
    "nous %%aurions ouvert%%",
    "vous %%auriez ouvert%%",
    "ils/elles %%auraient ouvert%%",
  ],
  conditionnel_passeDeuxiemeForme: [
    "j'%%aurais ouvert%%",
    "tu %%aurais ouvert%%",
    "il/elle %%aurait ouvert%%",
    "nous %%aurions ouvert%%",
    "vous %%auriez ouvert%%",
    "ils/elles %%auraient ouvert%%",
  ],
};

export default ouvrirConjugations;
