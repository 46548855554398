import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import ReactGA from "react-ga4";

import TenseList from "./TenseList";
import VerbList from "./VerbList";
import * as helpers from "../data/helpers";

const SelectionArea: React.FC = () => {
  const [selectedVerb, setSelectedVerb] = useState("");
  const [selectedTense, setSelectedTense] = useState("");
  const navigate = useNavigate();

  const startExercise = () => {
    ReactGA.event({
      category: "Exercise",
      action: "Start",
      label: `Tense: ${selectedVerb}, Verb: ${selectedVerb}`,
    });

    console.log(selectedVerb, selectedTense);
    navigate(`/exercise/${selectedTense}/${selectedVerb}`);
  };

  const getPhraseCountForVerb = (verb: string) => {
    const phraseCount = helpers.phraseCountByVerb(verb);
    console.log(phraseCount);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <TenseList
            counters={helpers.phraseCountByVerb(selectedVerb)}
            onChange={(tense) => {
              setSelectedTense(tense);
            }}
          />
          <div className="sticky-button mr-2">
            <button
              type="button"
              className="btn btn-primary"
              disabled={!selectedVerb || !selectedTense}
              onClick={startExercise}
            >
              Commencer
            </button>
          </div>
        </div>
        <div className="col">
          <VerbList
            counters={helpers.phraseCountByTense(selectedTense)}
            onChange={(verb) => {
              setSelectedVerb(verb);
              getPhraseCountForVerb(verb);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectionArea;
