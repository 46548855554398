import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Counters } from "../../data/helpers";

export type ListItem = {
  id: string;
  title: string;
  hide?: boolean;
};

export type ListType = {
  list: ListItem[];
  onChange: (id: string) => void;
  counters?: Counters;
  prefix: string;
  enableHints?: boolean;
};

const SelectorList: React.FC<ListType> = (props) => {
  const [activeItem, setActiveItem] = useState("");
  const [showCounter] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <ul className="list-group mt-4">
        {props.list
          .filter((item) => !item.hide)
          .map((item) => (
            <button
              key={`${props.prefix}_${item.id}`}
              data-itemid={`${props.prefix}_${item.id}`}
              type="button"
              className={
                "list-group-item list-group-item-action px-3 py-2" +
                (activeItem === item.id ? " active" : "")
              }
              onClick={() => {
                setActiveItem(item.id);
                props.onChange(item.id);
              }}
            >
              {props.enableHints && (
                <span
                  className="btn btn-light btn-sm me-2"
                  onClick={(e) => {
                    navigate(`/conjugation/${item.id}`);
                    e.stopPropagation();
                  }}
                >
                  <i
                    className="bi bi-question-circle"
                    style={{ fontSize: "1rem" }}
                  ></i>
                </span>
              )}
              <span className="capitalize">{item.title}</span>
              {showCounter && props.counters && (
                <span className="badge text-bg-success">
                  {props.counters[item.id]}
                </span>
              )}
            </button>
          ))}
      </ul>
    </>
  );
};

export default SelectorList;
